<style scoped>
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
</style>

<template>
  <div>
    <Form v-if="selectedRecord" v-bind:record="selectedRecord"
          @close="selectedRecord = null"
          @insert="insert"></Form>
    <nav>
      <div class="breadcrumb p-2">
        <div class="row">
          <div class="col-auto ms-3 p-0">
            <ul class="breadcrumb p-0 m-auto">
              <li class="breadcrumb-item">
                <router-link to="/home">
                  <i v-if="!$apollo.loading" class="fas fa-home"></i>
                  <i v-if="$apollo.loading" class="fas fa-sync ms-1 fa-sm" :class="{'fa-spin': $apollo.loading}"></i>
                </router-link>
              </li>
              <li class="breadcrumb-item active fw-bold" aria-current="page">
                <router-link to="/learning_paths">Learning Paths</router-link>
              </li>
              <li class="breadcrumb-item fw-bold" aria-current="page">
                Levels
              </li>
            </ul>
          </div>
        </div>
        <div class="container-fluid">
          <div class="row">
            <div class="col order-last text-end">
              <button type="button" class="btn btn-company-primary" @click="newRecord()"
                  v-if="$can('CreateLearningPathLevels')">
                <i class="fas fa-plus"></i>
                New
              </button>
            </div>
          </div>
        </div>
      </div>
    </nav>
    <Empty v-if="!learning_path?.levels.length" icon="fas fa-graduation-cap" :can_create="true" @callCreate="newRecord"/>
    <VueDraggable v-bind="dragOptions" class="row" v-if="learning_path" :modelValue="learning_path.levels"
    @update:modelValue="updateLevels" @end="sort">
      <Card :key="index" v-for="(record, index) in learning_path.levels" :record="record" @remove="remove"
            @refresh="refresh"></Card>
    </VueDraggable>
  </div>
</template>

<script>

import { VueDraggable } from 'vue-draggable-plus';
import LEARNING_PATH_LEVELS_QUERY from './queries';
import { CREATE_LEARNING_PATH_LEVEL, DELETE_LEARNING_PATH_LEVEL, UPDATE_LEARNING_PATH_LEVELS_ORDER } from './mutations';
import Form from './Form.vue';
import Card from './Card.vue';
import { ErrorHandler } from '../../../shared';

export default {
  components: { Form, Card, VueDraggable },
  apollo: {
    learning_path: {
      query: LEARNING_PATH_LEVELS_QUERY,
      errorPolicy: 'all',
      variables() {
        return { id: this.$route.params.id };
      },
      error(error) {
        this.$toasted.error(error.message);
      },
    },
  },
  data() {
    return {
      selectedRecord: null,
    };
  },
  methods: {
    newRecord() {
      this.selectedRecord = {
        learning_path_id: this.$route.params.id,
      };
    },
    refresh() {
      this.$apollo.queries.learning_path.refetch();
    },
    updateLevels(newLevels) {
      this.learning_path = {
        ...this.learning_path,
        levels: newLevels,
      };
    },
    remove(record) {
      this.$apollo.mutate({
        mutation: DELETE_LEARNING_PATH_LEVEL,
        variables: {
          id: record.id,
        },
      }).then(() => {
        this.refresh();
      }).catch((error) => {
        this.$toasted.error(`Unable to delete record: ${error.message}`);
      });
    },
    sort() {
      const levels = this.learning_path.levels.map((level, index) => ({
        id: level.id,
        order: index + 1,
      }));

      this.$apollo.mutate({
        mutation: UPDATE_LEARNING_PATH_LEVELS_ORDER,
        variables: {
          learning_path_levels: levels,
        },
        update: (store, { data: { updateLearningPathLevelsOrder } }) => {
          const data = store.readQuery({ query: LEARNING_PATH_LEVELS_QUERY, variables: { id: this.$route.params.id } });
          store.modify({
            fields: {
              learning_path() {
                return { ...data.learning_path, levels: updateLearningPathLevelsOrder };
              },
            },
          });
        },
      }).then(() => {
        this.$toasted.success('Levels reordered');
      }).catch((error) => {
        this.$toasted.error(`Unable to update record: ${error.message}`);
      });
    },
    insert(record) {
      const newRecord = record;
      this.selectedRecord = null;
      this.$apollo.mutate({
        mutation: CREATE_LEARNING_PATH_LEVEL,
        variables: {
          learning_path_level: record,
        },
      }).then(() => {
        this.refresh();
      }).catch((error) => {
        ErrorHandler.handle(error, this);
        this.selectedRecord = newRecord;
      });
    },
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        disabled: false,
        ghostClass: 'ghost',
      };
    },
  },
};
</script>
