import { gql } from '@apollo/client/core';

export default gql`
  query($technology_ids: [Int!], $vertical_market_ids: [Int!]) {
    talented_people(technology_ids: $technology_ids, vertical_market_ids: $vertical_market_ids) {
      id
      name
      knowledge_score
    }
  }
`;
