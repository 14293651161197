import {
  ApolloClient, InMemoryCache, HttpLink, ApolloLink,
} from '@apollo/client/core';
import { onError } from '@apollo/client/link/error';

const errorLink = onError(({ graphQLErrors }) => {
  if (graphQLErrors && graphQLErrors[0].message === 'Unauthenticated.') {
    setTimeout(() => {
      document.getElementById('logout-form').submit();
    }, 500);
  }
});

const headers = () => {
  if (localStorage.getItem('api_token')) {
    return {
      authorization: `Bearer ${localStorage.getItem('api_token')}`,
    };
  }
  return {};
};

const httpLink = new HttpLink({
  uri: '/graphql',
  headers: headers(),
});

const link = ApolloLink.from([errorLink, httpLink]);

const apolloClient = new ApolloClient({
  link,
  cache: new InMemoryCache(),
});

export default apolloClient;
