<template>
  <div>
    <nav>
      <div class="breadcrumb p-2">
        <div class="row">
          <div class="col-auto ms-3 p-0">
            <SpinnerRouterLink :loading="$apollo.loading">
              Recurrent Revenue By Manager History
            </SpinnerRouterLink>
          </div>
        </div>
        <div class="container-fluid">
          <div class="row">
            <div class="col-12 col-sm-12 m-auto">
              <Search
                v-model="search" :inputs="['dateRanges']"
              ></Search>
            </div>
          </div>
        </div>
      </div>
    </nav>
    <div class="row">
      <div class="col-md-12">
        <div class="chart-row" id="chart"></div>
      </div>
    </div>
  </div>
</template>

<script>
import Highcharts from 'highcharts';
import { SpinnerRouterLink, Search } from '../../shared';
import { SearchBuilder } from '../../../shared';
import ENTITY_INDICATOR_QUERY from './queries';

export default {
  components: { SpinnerRouterLink, Search },
  data() {
    return {
      search: {},
    };
  },
  apollo: {
    user: {
      query: ENTITY_INDICATOR_QUERY,
      errorPolicy: 'all',
      variables() {
        return {
          id: this.$route.params.id,
          entity_indicator_name: 'RECURRENT_MONTHLY_REVENUE',
          orderBy: [{ column: 'created_at', order: 'DESC' }],
          ...SearchBuilder.build(this.search),
        };
      },
      error(error) {
        this.$toasted.error(error.message);
      },
    },
  },
  computed: {
    details() {
      return [...this.user.indicators].reverse();
    },
    categories() {
      return _.map(this.details, 'created_at');
    },
    series() {
      return _.map(this.details, (v) => parseFloat(v.value));
    },
    subtitle() {
      if (this.details.length > 0) return `From ${_.head(this.details).created_at} To ${_.last(this.details).created_at}`;
      return `Sorry, there is no data available beyond ${this.search.start_date || this.search.end_date}. Please select a different date range`;
    },
  },
  watch: {
    user() {
      this.drawChart();
    },
  },
  methods: {
    drawChart() {
      Highcharts.chart('chart', {
        chart: {
          type: 'area',
        },
        title: {
          text: this.user.name,
        },
        subtitle: {
          text: this.subtitle,
        },
        tooltip: {
          backgroundColor: 'white',
          valuePrefix: _.head(this.user.indicators)?.prefix || undefined,
          valueSuffix: _.head(this.user.indicators)?.suffix || undefined,
        },
        xAxis: {
          categories: this.categories,
          lineWidth: 0,
        },
        plotOptions: {
          series: {
            dataLabels: {
              enabled: true,
            },
          },
          area: {
            marker: {
              enabled: true,
              symbol: 'circle',
              radius: 4,
              fillColor: '#ec663b',
            },
          },
        },
        legend: {
          enabled: true,
          itemStyle: {
            color: '#000000',
          },
        },
        series: [
          {
            name: this.user.name,
            data: this.series,
            lineColor: '#ec663b',
            color: '#ffe9e1',
          },
        ],
      });
    },
  },
};
</script>
