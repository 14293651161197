import { gql } from '@apollo/client/core';

import PROJECT_ROLE_FRAGMENT from './fragments';

export const CREATE_PROJECT_ROLE = gql`
  mutation($project_role: ProjectRoleInput!){
    createProjectRole(input: $project_role){
      ...project_role
    }
  }
  ${PROJECT_ROLE_FRAGMENT}
`;

export const UPDATE_PROJECT_ROLE = gql`
  mutation($id: ID!, $project_role: ProjectRoleInput!){
    updateProjectRole(id: $id, input: $project_role){
      ...project_role
    }
  }
  ${PROJECT_ROLE_FRAGMENT}
`;

export const DELETE_PROJECT_ROLE = gql`
  mutation($id: ID!){
    deleteProjectRole(id: $id){
      id
    }
  }
`;
