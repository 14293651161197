import { gql } from '@apollo/client/core';

export default gql`
mutation($kudos: KudosInput!){
  createKudos(input: $kudos) {
    to_user_id
    message
  }
}
`;
