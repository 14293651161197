import { gql } from '@apollo/client/core';

import LEARNING_PATH_LEVEL_NODE_FRAGMENT from './fragments';

export const CREATE_LEARNING_PATH_LEVEL_NODE = gql`
  mutation($learning_path_level_node: LearningPathLevelNodeInput!){
    createLearningPathLevelNode(input: $learning_path_level_node){
      ...learning_path_level_node
    }
  }
  ${LEARNING_PATH_LEVEL_NODE_FRAGMENT}
`;

export const UPDATE_LEARNING_PATH_LEVEL_NODE = gql`
  mutation($id: ID!, $learning_path_level_node: LearningPathLevelNodeInput!){
    updateLearningPathLevelNode(id: $id, input: $learning_path_level_node){
      ...learning_path_level_node
    }
  }
  ${LEARNING_PATH_LEVEL_NODE_FRAGMENT}
`;

export const DELETE_LEARNING_PATH_LEVEL_NODE = gql`
  mutation($id: ID!){
    deleteLearningPathLevelNode(id: $id){
      id
    }
  }
`;
