<template>
  <div>
    <nav>
      <div class="breadcrumb p-2">
        <div class="row mb-3">
          <div class="col-auto ms-3 p-0">
            <SpinnerRouterLink :loading="$apollo.loading">People Allocation</SpinnerRouterLink>
          </div>
        </div>
        <div class="col-md-10 m-auto p-0" v-if="peopleAllocation">
          <div class="float-end">
            <div class="d-flex pb-1">
              <span>
                <i class="fas fa-circle text-success"></i>
                <strong>{{green}}</strong>
                Allocated for more than {{ status.green }} week{{ (status.green != 1) ? 's' : '' }}.
              </span>
            </div>
            <div class="d-flex pb-1">
              <span>
                <i class="fas fa-circle text-danger"></i>
                <strong>{{red}}</strong>
                Allocated for less than {{ status.red }} week{{ (status.red != 1) ? 's' : '' }}.
              </span>
            </div>
            <div class="d-flex">
              <span>
                <i class="fas fa-circle text-warning"></i>
                <strong>{{yellow}}</strong>
                Allocated for less than {{ status.green }} week{{ (status.green != 1) ? 's' : '' }} & more than {{ status.red }} week{{ (status.red != 1) ? 's' : '' }}.
              </span>
            </div>
          </div>
        </div>
      </div>
    </nav>
    <div class="row" v-if="peopleAllocation">
      <div class="col-md-12 text-nowrap scroll-x h-100">
        <Empty v-if="!peopleAllocation.length" icon="fas fa-users"/>
        <table v-if="peopleAllocation.length" class="table">
          <thead>
            <tr>
              <th class="d-table-cell">#</th>
              <SortByColumn class="d-table-cell" :sort_by_field="'person'" :orderBy="orderBy" @refresh="refresh">Person</SortByColumn>
              <SortByColumn class="d-table-cell" :sort_by_field="'position'" :orderBy="orderBy" @refresh="refresh">Position</SortByColumn>
              <SortByColumn class="d-table-cell" :sort_by_field="'end_date'" :orderBy="orderBy" @refresh="refresh">End Date</SortByColumn>
            </tr>
          </thead>
          <tbody>
            <tr :key="index" v-for="(row, index) in peopleAllocation">
              <td>{{index + 1}}</td>
              <td>
                <router-link :to="{ name: 'resume', params: { id: row.user_id }}" target="_blank" class="text-decoration-none">
                  {{row.person}}
                </router-link>
              </td>
              <td>{{row.position}}</td>
              <td>
                <i class="fas fa-circle" :class="classByStatus(row.status)"></i>
                {{$displayDate(row.end_date)}}
                ({{ remainingDays(row.end_date) }})
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import PEOPLE_ALLOCATION from './queries';
import { CONFIGURATION } from '../../configuration/queries';
import { SortByColumn, SpinnerRouterLink } from '../../shared';

export default {
  components: { SortByColumn, SpinnerRouterLink },
  data() {
    return {
      orderBy: { column: 'end_date', order: 'ASC' },
    };
  },
  computed: {
    red() {
      return _.filter(this.peopleAllocation, ['status', 'red']).length;
    },
    yellow() {
      return _.filter(this.peopleAllocation, ['status', 'yellow']).length;
    },
    green() {
      return _.filter(this.peopleAllocation, ['status', 'green']).length;
    },
    status() {
      return this.configuration ? {
        green: _.find(this.configuration, { key: 'Allocation Threshold Green' }).value,
        red: _.find(this.configuration, { key: 'Allocation Threshold Red' }).value,
      } : { };
    },
  },
  methods: {
    refresh() {
      this.$apollo.queries.peopleAllocation.refetch();
    },
    classByStatus(status) {
      const cssClasses = { red: 'text-danger', yellow: 'text-warning', green: 'text-success' };
      return cssClasses[status] || '';
    },
    remainingDays(endDate) {
      return dayjs(endDate, 'YYYY-MM-DD').endOf('day').fromNow();
    },
  },
  apollo: {
    peopleAllocation: {
      query: PEOPLE_ALLOCATION,
      errorPolicy: 'all',
      variables() {
        return { orderBy: [this.orderBy] };
      },
      error(error) {
        this.$toasted.error(error.message);
      },
    },
    configuration: {
      query: CONFIGURATION,
      errorPolicy: 'all',
      error(error) {
        this.$toasted.error(error.message);
      },
    },
  },
};
</script>
