<template>
  <div>
    <nav>
      <div class="breadcrumb p-2 mb-0">
        <div class="row">
          <div class="col-auto ms-3 p-0">
            <SpinnerRouterLink :loading="$apollo.loading">Gcoin Transactions</SpinnerRouterLink>
          </div>
        </div>
        <div class="container-fluid mb-3">
          <div class="row">
            <div class="col-md-12">
              <Search v-model="search" :inputs="['users', 'dateRanges']"></Search>
            </div>
          </div>
        </div>
      </div>
    </nav>
    <div class="row" v-if="GcoinsTransactions">
      <Empty v-if="!GcoinsTransactions.data.length" icon="fa-solid fa-money-bill-transfer"/>
      <template v-for="record in GcoinsTransactions.data" :key="record.id">
        <Card :record="record"></Card>
      </template>
    </div>
    <SkeletonCards
      v-if="GcoinsTransactions && GcoinsTransactions.paginatorInfo.hasMorePages"
      :cards=1
      :executeScroll="GcoinsTransactions != undefined"
      @showMore="showMore('GcoinsTransactions')"
    ></SkeletonCards>
  </div>
</template>

<script>
import GCOIN_TRANSACTIONS from './queries';
import Card from './Card.vue';
import { Search, SkeletonCards, SpinnerRouterLink } from '../shared';
import {
  SearchBuilder,
  ErrorHandler,
  PaginationHandler,
} from '../../shared';

export default {
  extends: PaginationHandler,
  components: {
    Card, Search, SkeletonCards, SpinnerRouterLink,
  },
  data() {
    return {
      search: { },
      is_exporting: false,
      orderBy: { column: 'id', order: 'DESC' },
    };
  },
  apollo: {
    GcoinsTransactions: {
      query: GCOIN_TRANSACTIONS,
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
      variables() {
        return {
          page: 1,
          ...SearchBuilder.build(this.search),
          orderBy: [this.orderBy],
        };
      },
      error(error) {
        ErrorHandler.handle(error, this);
      },
    },
  },
  methods: {
    queryVariables() {
      return SearchBuilder.build(this.search);
    },
  },
};
</script>
