import { gql } from '@apollo/client/core';
import PROJECT_RISK_FRAGMENT from './fragments';

export default gql`
query($page: Int!, $project_id: ID!, $name: String) {
  projectRisks(page: $page, project_id: $project_id, name: $name, orderBy: { column: "id", order: DESC }) {
    paginatorInfo {
      hasMorePages
    }
    data{
      ...projectRisk
    }
  }
}
${PROJECT_RISK_FRAGMENT}
`;
