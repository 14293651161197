<template>
  <div>
    <div class="S-404 left-0 h-100 text-opacity-25 text-dark m-auto position-absolute">
      <b>404</b>
    </div>
    <div class="d-flex flex-column align-items-center my-5 pt-5 position-absolute w-100 left-0">
      <br><br>
      <h3 class="h2 text-center px-5"><b>Oops!</b><br> We didn't find the company you're looking for</h3>
      <div style="width: 42rem; max-width: 99vw">
        <div class="d-flex flex-column align-items-center">
          <br><br><br>
          <router-link to="/welcome" class="fs-5 btn btn-company-primary w-50">
            Go Home
          </router-link>
          <br>
          <p>
            <span class="text-body-secondary">Maybe you're looking for &nbsp;</span>
            <router-link to="/register_company">
              <b>Register my Company</b>
            </router-link>
          </p>
        </div>
      </div>
    </div>
    <div class="rounded-bottom rounded-circle bg-opacity-10 bg-dark vw-100 h-25 bottom-0 start-0 position-absolute overflow-hidden position-absolute overflow-hidden"><span class="d-block"></span></div>
  </div>
</template>

<style lang="scss" scoped>

  .S-404 {
    width: max-content;
    right: 0%;
    font-size: 41vmin;
  }
</style>
