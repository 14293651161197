<template>
<div :id="record.id"  class="col-md-2 pb-2">
  <Form v-if="is_editting" v-bind:record="record"
                            @close="is_editting = false"
                            @update="update"></Form>
  <div v-if="!is_editting" class="card h-100">
    <div class="card-body pb-0">
      <h5 class="card-title">
        {{record.name}}
        <span class="badge bg-secondary bg-company-secondary" v-if="record.is_parallel">Parallel Work</span>
      </h5>
    </div>
    <div class="card-body">
      <button v-if="!confirmation && $can('UpdateBucket')" class="btn btn-company-primary" @click="is_editting = true">
        <i class="fas fa-pen"></i>
        Edit
      </button>
      <Confirmation v-if="$can('DeleteBucket')"
        v-model="confirmation"
        @accepted="remove()"
      />
    </div>
  </div>
</div>
</template>

<script>
import BUCKETS_QUERY from './queries';
import { UPDATE_BUCKET } from './mutations';
import Form from './Form.vue';
import { SearchBuilder, ErrorHandler } from '../../shared';
import { Confirmation } from '../shared';

export default {
  components: { Form, Confirmation },
  props: ['record'],
  data() {
    return {
      is_editting: false,
      confirmation: false,
    };
  },
  methods: {
    update(record) {
      this.$apollo.mutate({
        mutation: UPDATE_BUCKET,
        variables: {
          id: record.id,
          bucket: _.pick(record, ['name', 'is_parallel']),
        },
        update: (cache, { data: { updateBucket } }) => {
          const variables = { page: 1, ...SearchBuilder.build(this.$parent.search) };
          const data = cache.readQuery({ query: BUCKETS_QUERY, variables });
          const updatedRecords = data.buckets.data.map((r) => (r.id === updateBucket.id ? updateBucket : r));
          const newData = {
            ...data,
            buckets: {
              ...data.buckets,
              data: updatedRecords,
            },
          };
          cache.writeQuery({ query: BUCKETS_QUERY, variables, data: newData });
        },
      }).catch((error) => {
        ErrorHandler.handle(error, this);
      }).then(() => {
        this.is_editting = false;
      });
    },
    remove() {
      this.$emit('remove', this.record);
      this.is_editting = false;
      this.confirmation = false;
    },
  },
};
</script>
