<template>
<div class="mb-5 card">
  <div class="card-header d-flex">
    <div class="col p-0">
      <span v-if="!record.id">New Health Score Configuration Item</span>
      <span v-if="record.id">{{localRecord.name}}</span>
    </div>
    <div class="col text-end">
      <i class="fas fa-times" @click="$emit('close')"></i>
    </div>
  </div>
  <div class="card-body d-grid">
    <div class="mb-3">
      <label for="name">Name</label>
      <input v-focus type="text" class="form-control" :class="{'is-invalid': v$.localRecord.name.$invalid}" id="name" v-model="localRecord.name">
      <div v-for="error of v$.localRecord.name.$silentErrors" :key="error.$uid">
        <div class="text-danger">{{ error.$message }}</div>
      </div>
    </div>
    <div class="mb-3">
      <label for="description">Description</label>
      <textarea v-model="localRecord.description" id="description" class="form-control" rows="3" :class="{'is-invalid': v$.localRecord.description.$invalid}"></textarea>
      <div v-for="error of v$.localRecord.description.$silentErrors" :key="error.$uid">
        <div class="text-danger">{{ error.$message }}</div>
      </div>
    </div>
    <div class="mb-3">
      <label for="percentage_weight">Percentage Weight</label>
      <input type="number" class="form-control" :class="{'is-invalid': v$.localRecord.percentage_weight.$invalid}" id="percentage_weight" max="100" v-model.number="localRecord.percentage_weight">
      <div v-for="error of v$.localRecord.percentage_weight.$silentErrors" :key="error.$uid">
        <div class="text-danger">{{ error.$message }}</div>
      </div>
    </div>
    <div class="row">
      <div class="mb-3 col-md-2">
        <label for="success_operator">Metric is Successful when value is:</label>
        <select
          class="form-control"
          :class="{'is-invalid': v$.localRecord.success_operator.$invalid}"
          id="success_operator"
          v-model="localRecord.success_operator">
          <option v-for="operator in successOperators" :key="operator.value" :value="operator.value">
            {{ operator.text }}
          </option>
        </select>
        <div v-for="error of v$.localRecord.success_operator.$silentErrors" :key="error.$uid">
          <div class="text-danger">{{ error.$message }}</div>
        </div>
      </div>
      <div v-if="!['is_true', 'is_false'].includes(localRecord.success_operator)" class="mb-3 col-md-2">
        <label for="success_value_a"><i class="fa-solid fa-arrow-down"></i></label>
        <input type="number" class="form-control" :class="{'is-invalid': v$.localRecord.success_value_a.$invalid}" id="success_value_a" v-model.number="localRecord.success_value_a">
        <div v-for="error of v$.localRecord.success_value_a.$silentErrors" :key="error.$uid">
          <div class="text-danger">{{ error.$message }}</div>
        </div>
      </div>
      <div v-if="localRecord.success_operator === 'between'" class="mb-3 col-md-2">
        <label for="success_value_b"><i class="fa-solid fa-arrow-down"></i></label>
        <input type="number" class="form-control" :class="{'is-invalid': v$.localRecord.success_value_b.$invalid}" id="success_value_b" v-model.number="localRecord.success_value_b">
        <div v-for="error of v$.localRecord.success_value_b.$silentErrors" :key="error.$uid">
          <div class="text-danger">{{ error.$message }}</div>
        </div>
      </div>
    </div>
    <button :disabled="v$.localRecord.$invalid" type="button" class="btn btn-company-primary" @click="save()">
      <i class="fas fa-save"></i>
      Save
    </button>
    <button type="button" class="btn btn-company-secondary" @click="$emit('close')">
      <i class="fas fa-times"></i>
      Cancel
    </button>
  </div>
</div>
</template>

<script>
import Validations from './Validations';
import Blinkable from '../../shared/Blinkable';

export default {
  mixins: [Blinkable, Validations],
  props: ['record', 'totalAvailablePercentage'],
  data() {
    return {
      localRecord: _.cloneDeep(this.record),
      successOperators: [
        { value: 'equals_to', text: 'Equals To' },
        { value: 'greater_than', text: 'Greater Than' },
        { value: 'less_than', text: 'Less Than' },
        { value: 'is_true', text: 'Is True' },
        { value: 'is_false', text: 'Is False' },
        { value: 'between', text: 'Between' },
      ],
    };
  },
  methods: {
    save() {
      const event = (this.localRecord.id) ? 'update' : 'insert';
      this.$emit(event, this.localRecord);
    },
  },
};
</script>
