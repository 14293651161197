import { gql } from '@apollo/client/core';

import SIGNATURE_FRAGMENT from '../../signature_settings/fragments';

export default gql`
  query {
    signature {
      ...signature
    }
  }
  ${SIGNATURE_FRAGMENT}
`;
