import { gql } from '@apollo/client/core';

import ESTIMATION_FRAGMENT from './fragments';

export const ESTIMATION_QUERY = gql`
query($id: ID!){
  estimation(id: $id){
    id
    name
    client_id
    notes
    bucket_estimations{
      id
      order
      bucket {
        id
        name
        is_parallel
      }
      bucket_estimation_items {
        id
        name
        hours
        order
        is_completed
        deliverable {
          id
          name
          color
          created_at
        }
      }
    }
    deliverables {
      id
      estimation_id
      name
      color
      is_completed
      created_at
      bucket_estimation_items {
        id
        hours
        is_completed
        bucket_estimation {
          id
          bucket {
            id
            is_parallel
          }
        }
      }
      resources {
        id
        project_role {
          id
          name
        }
        quantity
      }
    }
  }
}
`;

export const ESTIMATIONS_QUERY = gql`
  query($page: Int,
        $name: String,
        $client_id: ID) {
    estimations(
      page: $page,
      name: $name,
      client_id: $client_id,
      orderBy: { column: "id", order: ASC }) @connection(key: "estimations", filter: ["client_id", "name"]) {
      paginatorInfo {
        hasMorePages
      }
      data {
        ...estimation
        technologies {
          id
          name
        }
        buckets {
          id
          name
          is_parallel
        }
        bucket_estimations{
          id
          total_hours
          bucket {
            id
            name
            is_parallel
          }
        }
        projects{
          id
        }
      }
    }
  }
  ${ESTIMATION_FRAGMENT}
`;
