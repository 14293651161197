import { gql } from '@apollo/client/core';

export default gql`
  fragment paid_time_off_request on PaidTimeOffRequest {
    id
    user_id
    status
    total_days
    assigned_to_id
    user {
      id
      name
    }
    assigned_to {
      id
      name
    }
    user_resolver {
      id
      name
    }
    dates {
      id
      date
      type
    }
  }
`;
