const bsToast = {
  install(app) {
    const toastMethods = {
      delay: 3000,
      htmlToElement: (html) => {
        const template = document.createElement('template');
        html = html.trim();
        template.innerHTML = html;
        return template.content.firstChild;
      },
      show: (message, color = 'info') => {
        const html = `
          <div class="toast rounded-pill align-items-center mt-1 px-1 text-white bg-${color} border-0" role="alert" aria-live="assertive" aria-atomic="true">
            <div class="d-flex justify-content-center">
              <div class="toast-body">
                <div>${message}</div>
              </div>
            </div>
          </div>
        `;
        const toastContainerElement = document.getElementById('toast-container');
        if (toastContainerElement) {
          const toastElement = toastMethods.htmlToElement(html);
          toastContainerElement.appendChild(toastElement);
          const toast = new window.bootstrap.Toast(toastElement, {
            delay: toastMethods.delay,
            animation: true,
          });
          toast.show();
          setTimeout(() => toastElement.remove(), toastMethods.delay + 1000); // Let the "hiding toast animation" finish
        }
      },
      error: (message) => {
        toastMethods.show(message, 'danger');
      },
      success: (message) => {
        toastMethods.show(message, 'success');
      },
    };

    // Register toast methods as a global property in the application
    app.config.globalProperties.$toasted = toastMethods;
  },
};

export default bsToast;
