import { gql } from '@apollo/client/core';

export default gql`
  fragment notification_setting on NotificationSettings {
    id
    name
    description
    emails
    slack_channel
  }
`;
