export default {
  mounted() {
    if (this.record && this.record.id) {
      document.getElementById(this.record.id).scrollIntoView();
    }
  },
  unmounted() {
    if (this.record && this.record.id) {
      const element = document.getElementById(this.record.id);
      element.scrollIntoView();
      element.classList.add('touched');
      setTimeout(() => element.classList.remove('touched'), 1000);
    }
  },
};
