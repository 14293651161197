<template>
  <div>
    <figure class="highcharts-figure w-100">
      <div id="container" class="fill grabbable"></div>
    </figure>
  </div>
</template>

<script>
import Highcharts from 'highcharts';
import Maps from 'highcharts/modules/map';
import topology from '@highcharts/map-collection/custom/world.topo.json';

Maps(Highcharts);

export default {
  props: ['record', 'showing'],
  data() {
    return {
      chart: null,
      time: 750,
    };
  },
  mounted() { this.drawChart(); },
  watch: {
    record() {
      const { mapView } = this.chart.options;
      this.drawChart();
      this.chart.update({ mapView });
    },
  },
  computed: {
    maxCount() {
      return _.map(this.record, 'value').length;
    },
  },
  methods: {
    drawChart() {
      this.chart = Highcharts.mapChart('container', {
        chart: { map: topology },

        title: { text: ' ' },

        legend: {
          enabled: true,
          align: 'left',
          verticalAlign: 'middle',
          layout: 'vertical',
          floating: true,
        },

        mapNavigation: {
          enabled: true,
          enableDoubleClickZoomTo: true,
          buttonOptions: {
            verticalAlign: 'bottom',
          },
        },

        mapView: {
          maxZoom: 30,
          projection: {
            name: 'Orthographic',
            rotation: [80, 0],
          },
        },

        colorAxis: {
          tickPixelInterval: 50,
          minColor: '#ffffaa',
          maxColor: '#cdd44a',
          min: 0,
          max: this.maxCount,
        },
        tooltip: { pointFormat: '{point.name}: {point.value}' },

        plotOptions: {
          series: {
            animation: {
              duration: this.time,
            },
            clip: false,
          },
        },

        series: [
          {
            name: 'Graticule',
            id: 'graticule',
            type: 'mapline',
            data: this.getGraticule(),
            nullColor: 'rgba(256, 256, 256, 0.05)',
            accessibility: { enabled: true },
          }, {
            nullColor: '#eee',
            data: this.record,
            joinBy: 'name',
            name: `${this.capitalize(this.showing)} Count`,
            states: {
              hover: {
                color: '#a4edba',
                borderColor: '#333333',
              },
            },
            dataLabels: {
              enabled: true,
              format: '{point.value}',
            },
            accessibility: { exposeAsGroupOnly: true },
          },
        ],
      });
      this.renderSea();
      Highcharts.addEvent(this.chart, 'redraw', this.renderSea);
      this.time = 0;
    },
    getGraticule() {
      const data = [];
      for (let x = -180; x <= 180; x += 15) { // Meridians
        data.push({
          geometry: {
            type: 'LineString',
            coordinates: x % 90 === 0 ? [
              [x, -90],
              [x, 0],
              [x, 90],
            ] : [
              [x, -80],
              [x, 80],
            ],
          },
        });
      }

      for (let y = -90; y <= 90; y += 10) { // Latitudes
        const coordinates = [];
        for (let x = -180; x <= 180; x += 5) {
          coordinates.push([x, y]);
        }
        data.push({
          geometry: {
            type: 'LineString',
            coordinates,
          },
          lineWidth: y === 0 ? 2 : undefined,
        });
      }

      return data;
    },
    renderSea() {
      let verb = 'animate';
      const { chart } = this;

      if (!chart.sea) {
        chart.sea = chart.renderer
          .circle()
          .attr({
            fill: {
              radialGradient: {
                cx: 0.4,
                cy: 0.4,
                r: 1,
              },
              stops: [
                [0, '#3D5285'],
                [1, '#1a2338'],
              ],
            },
            zIndex: -1,
          })
          .add(chart.get('graticule').group);
        verb = 'attr';
      }

      const { bounds } = chart.get('graticule');
      const p1 = chart.mapView.projectedUnitsToPixels({
        x: bounds.x1,
        y: bounds.y1,
      });
      const p2 = chart.mapView.projectedUnitsToPixels({
        x: bounds.x2,
        y: bounds.y2,
      });
      chart.sea[verb]({
        cx: (p1.x + p2.x) / 2,
        cy: (p1.y + p2.y) / 2,
        r: Math.min(p2.x - p1.x, p1.y - p2.y) / 2,
      });
    },
    capitalize(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
  },
};
</script>
