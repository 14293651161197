<template>
  <div>
    <Form v-if="selectedRecord" v-bind:record="selectedRecord"
          @close="selectedRecord = null"
          @insert="insert"></Form>
    <nav>
      <div class="breadcrumb p-2">
        <div class="row">
          <div class="col-auto ms-3 p-0">
            <SpinnerRouterLink :loading="$apollo.loading">Projects</SpinnerRouterLink>
          </div>
        </div>
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12 m-auto mt-2">
              <Search
                v-model="search"
                :inputs="['name', 'clients', 'projectType', 'users', 'state', 'onlyMyProjectsCheckbox']"
              ></Search>
            </div>
          </div>
          <div class="row">
            <div class="col-12 order-last text-end mt-2 pt-2">
              <button v-if="$can('CreateProject')" class="btn btn-company-primary mt-1 d-inline" type="button"
                      @click="newRecord()">
                <i class="fas fa-plus"></i>
                New
              </button>
            </div>
          </div>
        </div>
      </div>
    </nav>
    <div v-if="projects" class="row">
      <Card v-for="record in projects.data" :key="record.id" :record="record" @refresh="refresh" @remove="remove"
            @update="update"></Card>
      <Empty v-if="!projects.data.length" icon="fas fa-project-diagram" :can_create="true" @callCreate="newRecord"/>
    </div>
    <SkeletonCards v-if="projects && projects.paginatorInfo.hasMorePages" :cards=2
                   :executeScroll="projects != undefined" @showMore="showMore('projects')"></SkeletonCards>
  </div>
</template>

<script>
import { PROJECTS_QUERY } from './queries';
import { CREATE_PROJECT, DELETE_PROJECT } from './mutations';
import Form from './Form.vue';
import Card from './Card.vue';
import { Search, SkeletonCards, SpinnerRouterLink } from '../shared';
import { ErrorHandler, PaginationHandler, SearchBuilder } from '../../shared';
import RequestBuilder from './RequestBuilder';

export default {
  extends: PaginationHandler,
  components: {
    Form, Card, Search, SkeletonCards, SpinnerRouterLink,
  },
  apollo: {
    projects: {
      query: PROJECTS_QUERY,
      errorPolicy: 'all',
      variables() {
        return { page: 1, orderBy: [{ column: 'projects.id', order: 'ASC' }], ...SearchBuilder.build(this.search) };
      },
      error(error) {
        this.$toasted.error(error.message);
      },
    },
  },
  data() {
    return {
      search: { only_my_projects: false, state: 'In Progress' },
      selectedRecord: null,
    };
  },
  methods: {
    newRecord() {
      this.selectedRecord = {
        is_internal: false,
        is_confidential: false,
        technologies: [],
        members: [],
        vertical_markets: [],
        tags: [],
        contacts: [],
        description: '',
      };
    },
    queryVariables() {
      return { orderBy: [{ column: 'projects.id', order: 'ASC' }], ...SearchBuilder.build(this.search) };
    },
    refresh() {
      this.$apollo.queries.projects.refetch();
    },
    remove(record) {
      this.$apollo
        .mutate({
          mutation: DELETE_PROJECT,
          variables: {
            id: record.id,
          },
          update: (store, { data: { deleteProject } }) => {
            const data = store.readQuery({ query: PROJECTS_QUERY, variables: this.queryVariables() });
            const updatedProjects = data.projects.data.filter((p) => p.id !== deleteProject.id);
            store.modify({ fields: { projects() { return { ...data.projects, data: updatedProjects }; } } });
          },
        }).catch((error) => {
          this.$toasted.error(`Unable to delete record: ${error.message}`);
        });
    },
    insert(record) {
      // We save the user input in case of an error
      const newProject = record;
      this.selectedRecord = null;
      this.$apollo
        .mutate({
          mutation: CREATE_PROJECT,
          variables: {
            project: RequestBuilder.build(record),
          },
          update: (store, { data: { createProject } }) => {
            const data = store.readQuery({ query: PROJECTS_QUERY, variables: this.queryVariables() });
            store.modify({ fields: { projects(existingProjects = { data: [] }) { return { ...existingProjects, data: [createProject, ...data.projects.data] }; } } });
          },
        }).catch((error) => {
          ErrorHandler.handle(error, this);
          // We restore the initial user input
          this.selectedRecord = newProject;
        });
    },
    update(record) {
      const index = _.findIndex(this.projects.data, { id: record.id });
      this.projects.data[index] = record;
    },
  },
};
</script>
