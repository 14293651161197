import { gql } from '@apollo/client/core';

export default gql`
  query($start_date: DateTime!,
        $end_date: DateTime!,
        $orderBy: [OrderByClause!]) {
    turnover(start_date: $start_date, end_date: $end_date, orderBy: $orderBy) {
      user_id
      user_name
      headcount_diff
      event_date
      termination_reason
      country
    }
  }
`;
