<template>
  <div>
    <Form v-if="selectedRecord" v-bind:record="selectedRecord" @close="selectedRecord = null" @insert="insert"></Form>
    <nav>
      <div class="breadcrumb p-2">
        <div class="col-md-10 text-start">
          <ol class="breadcrumb p-0 m-auto">
            <li class="breadcrumb-item">
              <router-link to="/home">
                <i v-if="!$apollo.loading" class="fas fa-home"></i>
                <i v-if="$apollo.loading" class="fas fa-sync ms-1 fa-sm" :class="{ 'fa-spin': $apollo.loading }"></i>
              </router-link>
            </li>
            <li class="breadcrumb-item active fw-bold" aria-current="page">
              <router-link to="/configuration">Notification Settings</router-link>
            </li>
            <li class="breadcrumb-item active fw-bold" aria-current="page">
              {{ notification_setting?.name }}
            </li>
            <li class="breadcrumb-item fw-bold" aria-current="page">
              Templates
            </li>
          </ol>
        </div>
        <div class="col-md-2 text-end">
          <button type="button" class="btn btn-company-primary" @click="newTemplate()"
            v-if="$can('CreateNotificationTemplates')">
            <i class="fas fa-plus"></i>
            New
          </button>
        </div>
      </div>
    </nav>
    <div class="row" v-if="notification_templates">
      <Empty v-if="!notification_templates.data.length" icon="fas fa-search"/>
      <Card :key="index" v-for="(record, index) in notification_templates.data" :record="record" @remove="remove"></Card>
    </div>
    <SkeletonCards v-if="notification_templates && notification_templates.paginatorInfo.hasMorePages" :cards=3
      :executeScroll="notification_templates != undefined" @showMore="showMore('notification_templates')"></SkeletonCards>
  </div>
</template>

<script>
import { gql } from '@apollo/client/core';
import TEMPLATES_QUERY from './queries';
import { CREATE_TEMPLATE, DELETE_TEMPLATE } from './mutations';
import { SkeletonCards } from '../../shared';
import Form from './Form.vue';
import Card from './Card.vue';
import { ErrorHandler, PaginationHandler } from '../../../shared';

export default {
  extends: PaginationHandler,
  components: {
    SkeletonCards, Form, Card,
  },
  apollo: {
    notification_templates: {
      query: TEMPLATES_QUERY,
      errorPolicy: 'all',
      variables() {
        return { page: 1, notification_setting_id: this.$route.params.id };
      },
      error(error) {
        this.$toasted.error(error.message);
      },
    },
    notification_setting: {
      query: gql`query ($id: ID!) { notification_setting (id: $id) { id name liquid_variables }}`,
      errorPolicy: 'all',
      variables() {
        return { id: this.$route.params.id };
      },
      error(error) {
        this.$toasted.error(error.message);
      },
    },
  },
  data() {
    return {
      selectedRecord: null,
    };
  },
  methods: {
    queryVariables() {
      return { notification_setting_id: this.$route.params.id };
    },
    newTemplate() {
      this.selectedRecord = {
        notification_setting_id: this.$route.params.id,
        text: '',
      };
    },
    remove(template) {
      this.$apollo.mutate({
        mutation: DELETE_TEMPLATE,
        variables: {
          id: template.id,
        },
        update: (store, { data: { deleteNotificationTemplates } }) => {
          const data = store.readQuery({ query: TEMPLATES_QUERY, variables: this.queryVariables() });
          const updatedNotificationTemplates = data.notification_templates.data.filter((c) => c.id !== deleteNotificationTemplates.id);
          store.modify({ fields: { notification_templates(existingTemplates = { data: [] }) { return { ...existingTemplates, data: updatedNotificationTemplates }; } } });
        },
      }).catch((error) => {
        this.$toasted.error(`Unable to delete record: ${error.message}`);
      });
    },
    insert(template) {
      const newTemplate = template;
      this.selectedRecord = null;
      this.$apollo.mutate({
        mutation: CREATE_TEMPLATE,
        variables: {
          newTemplate,
        },
        update: (store, { data: { createNotificationTemplates } }) => {
          const data = store.readQuery({ query: TEMPLATES_QUERY, variables: this.queryVariables() });
          store.modify({ fields: { notification_templates(existingTemplates = { data: [] }) { return { ...existingTemplates, data: [createNotificationTemplates, ...data.notification_templates.data] }; } } });
        },
      }).catch((error) => {
        ErrorHandler.handle(error, this);
        this.selectedRecord = newTemplate;
      });
    },
  },
};
</script>
