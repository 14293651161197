<style scoped>
.badge-pill-custom {
  width: 4em;
  height: 4em;
}
</style>

<template>
  <div class="d-flex justify-content-center">
    <NodeModal :show="showModal(record.id)" @close="toggleModal(record.id)" :userNode="userNode" :record="record"
      @update-status="updateRecordStatus">
    </NodeModal>
    <div class="col-md-6" @click.stop="toggleModal(record.id)">
      <div class="card shadow border-0 m-2 p-3 sortable">
        <div class="d-flex">
          <span class="d-flex justify-content-center align-items-center rounded-circle badge-pill-custom bg-company-third-ligth" v-if="!userNode.is_successful && status === 'Unsuccessful'">
            <i class="fa-solid fa-circle-check fa-2xl" title="Unsuccessful"></i>
          </span>
          <span class="d-flex justify-content-center align-items-center rounded-circle badge-pill-custom bg-company-fourth-ligth" v-else-if="!userNode.is_successful && status === 'Ready for evaluation'">
            <i class="fa-solid fa-circle-question fa-2xl" title="Ready for evaluation"></i>
          </span>
          <span class="d-flex justify-content-center align-items-center rounded-circle badge-pill-custom bg-company-fiveth-ligth" v-else-if="userNode.is_successful">
            <i class="fa-solid fa-circle-check fa-2xl" title="verified"></i>
          </span>
          <span class="d-flex justify-content-center align-items-center rounded-circle badge-pill-custom bg-company-secondary-ligth" v-else>
            <i class="fa-solid fa-circle-check fa-2xl" title="Pending"></i>
          </span>
          <h5 class="company-text-primary my-auto ms-3">{{ record.name }}</h5>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NodeModal from './NodeModal.vue';

export default {
  components: { NodeModal },
  props: {
    learning_path_node_user: {
      type: Array,
      required: true,
    },
    record: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      status: 'Pending',
      activeModal: 0,
    };
  },
  computed: {
    userNode() {
      return (this.learning_path_node_user || []).find((node) => node.learning_path_level_node_id === parseInt(this.record.id, 10)) ?? {};
    },
  },
  methods: {
    showModal(id) {
      return this.activeModal === id;
    },
    toggleModal(id) {
      if (this.activeModal !== 0) {
        this.activeModal = 0;
        return false;
      }
      this.activeModal = id;
      return true;
    },
    updateRecordStatus(newStatus) {
      const updatedRecord = { ...this.record, status: newStatus };
      this.$emit('update:record', updatedRecord);
      this.status = newStatus;
      this.$toasted.success('Node status was updated');
    },
  },
  mounted() {
    this.status = this.userNode.status ?? 'Pending';
  },
};
</script>
