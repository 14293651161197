import { gql } from '@apollo/client/core';

export default gql`
  query($user_id: ID, $person: String, $orderBy: [OrderByClause!]) {
    peopleVacation(user_id: $user_id, person: $person, orderBy: $orderBy) {
      user_id
      person
      days
      working_time
      last_pto_date
      since
    }
  }
`;
