<template>
  <div class="card">
    <div class="card-header d-flex">
      <div class="col p-0">
        <span v-if="!record.id">New Promotion</span>
        <span v-if="record.id">{{localRecord.to_job_title.internal_description}}</span>
      </div>
      <div class="col text-end">
        <i class="fas fa-times" @click="$emit('close')"></i>
      </div>
    </div>
    <div class="card-body">
      <div class="mb-3">
        <label for="from_job_title_id">
          From job title <i v-if="$apollo.loading" class="fas fa-spinner fa-spin"></i>
        </label>
        <select id="from_job_title_id" class="form-control form-select" :class="{'is-invalid': v$.localRecord.from_job_title_id.$invalid}" v-model="localRecord.from_job_title_id">
          <template v-if="jobTitlesDropDown">
            <option :key="job_title.id" v-for="job_title in jobTitlesDropDown" :value="job_title.id">
              {{job_title.internal_description}}
            </option>
          </template>
        </select>
        <div v-for="error of v$.localRecord.from_job_title_id.$silentErrors" :key="error.$uid">
          <div class="text-danger">{{ error.$message }}</div>
        </div>
      </div>
      <div class="mb-3">
        <label for="to_job_title_id">
          To job title <i v-if="$apollo.loading" class="fas fa-spinner fa-spin"></i>
        </label>
        <select id="to_job_title_id" class="form-control form-select" :class="{'is-invalid': v$.localRecord.to_job_title_id.$invalid}" v-model="localRecord.to_job_title_id">
          <template v-if="jobTitlesDropDown">
            <option :key="job_title.id" v-for="job_title in jobTitlesDropDown" :value="job_title.id">
              {{job_title.internal_description}}
            </option>
          </template>
        </select>
        <div v-for="error of v$.localRecord.to_job_title_id.$silentErrors" :key="error.$uid">
          <div class="text-danger">{{ error.$message }}</div>
        </div>
      </div>
      <div class="mb-3">
        <label class="me-sm-2" for="date">Promotion Date</label>
        <DatePicker :date="localRecord.date"
                    placeholder="Select Date"
                    :css_class="{'is-invalid': v$.localRecord.date.$invalid}"
                    @change="localRecord = { ...localRecord, date: $event }"></DatePicker>
        <div v-for="error of v$.localRecord.date.$silentErrors" :key="error.$uid">
          <div class="text-danger">{{ error.$message }}</div>
        </div>
      </div>
      <div class="mb-3">
        <label for="next_job_title_id">
          Next job title <i v-if="$apollo.loading" class="fas fa-spinner fa-spin"></i>
        </label>
        <select id="next_job_title_id" class="form-control form-select" v-model="localRecord.next_job_title_id">
          <template v-if="jobTitlesDropDown">
            <option :key="job_title.id" v-for="job_title in jobTitlesDropDown" :value="job_title.id">
              {{job_title.internal_description}}
            </option>
          </template>
        </select>
      </div>
      <div class="mb-3">
        <label for="next_promotion_date">Next Promotion Date</label>
        <DatePicker :date="localRecord.next_promotion_date"
                    placeholder="Select Date"
                    :css_class="{'is-invalid': v$.localRecord.next_promotion_date.$invalid}"
                    @change="localRecord = { ...localRecord, next_promotion_date: $event }"></DatePicker>
        <div v-for="error of v$.localRecord.next_promotion_date.$silentErrors" :key="error.$uid">
          <div class="text-danger">{{ error.$message }}</div>
        </div>
      </div>
      <div class="mb-3">
        <label for="notes">Notes</label>
        <input type="hidden" id="promotion_note" v-model="localRecord.notes">
        <trix-editor input="promotion_note" @trix-change="localRecord.notes = $event.target.value; $forceUpdate();"
        placeholder="Enter content"></trix-editor>
        <div v-for="error of v$.localRecord.notes.$silentErrors" :key="error.$uid">
          <div class="text-danger">{{ error.$message }}</div>
        </div>
      </div>
      <button :disabled="v$.localRecord.$invalid" type="button" class="btn btn-company-primary" @click="save()">
        <i class="fas fa-save"></i>
        Save
      </button>
      <button type="button" class="btn btn-company-secondary" @click="$emit('close')">
        <i class="fas fa-times"></i>
        Cancel
      </button>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import Validations from './Validations';
import { Blinkable, JOB_TITLES } from '../../shared';
import { PROMOTED_USER_QUERY } from './queries';

export default {
  mixins: [Blinkable, Validations],
  apollo: {
    user: {
      query: PROMOTED_USER_QUERY,
      errorPolicy: 'all',
      variables() {
        return { id: parseInt(this.$route.params.id, 10) };
      },
      result({ data }) {
        if (!this.localRecord.id) {
          this.localRecord.from_job_title_id = data.user.job_title.id;
        }
      },
      error(error) {
        this.$toasted.error(error.message);
      },
    },
    jobTitlesDropDown: {
      query: JOB_TITLES,
      errorPolicy: 'all',
      variables() {
        return { orderBy: [{ column: 'id', order: 'ASC' }] };
      },
      error(error) {
        this.$toasted.error(error.message);
      },
    },
  },
  props: {
    record: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      localRecord: _.cloneDeep(this.record),
    };
  },
  methods: {
    save() {
      const event = (this.localRecord.id) ? 'update' : 'insert';
      this.$emit(event, this.localRecord);
    },
  },
};
</script>
