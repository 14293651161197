import { gql } from '@apollo/client/core';

export default gql`
  fragment template on NotificationTemplate {
    id
    name
    text
    notification_setting_id
  }
`;
