export default class SearchBuilder {
  static build(search) {
    const requestBody = _.clone(search);

    // Iterate over each property and, if it is an empty array or an empty object, convert it to null.
    _.forOwn(requestBody, (value, key) => {
      if (
        (_.isArray(value) && value.length === 0)
        || (_.isObject(value) && !_.isDate(value) && _.isEmpty(value))
      ) {
        requestBody[key] = null;
      }
    });

    const likeKeys = ['description', 'name', 'internal_description', 'person', 'notes', 'domain', 'provider', 'model', 'internal_key', 'serial_number'];
    likeKeys.forEach((k) => {
      if (search[k]) {
        requestBody[k] = `%${search[k]}%`;
      }
    });
    if (search.end_date) {
      requestBody.end_date = `${search.end_date} 23:59:59`;
    }
    if (search.start_date) {
      requestBody.start_date = `${search.start_date} 00:00:00`;
    }
    if (search.score) {
      requestBody.score = parseInt(requestBody.score, 10);
    }
    if (search.year) {
      requestBody.year = parseInt(requestBody.year, 10);
    }
    return requestBody;
  }
}
