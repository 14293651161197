<style scoped>
  .y-label {
    writing-mode: vertical-rl;
    text-orientation: mixed;
  }
  .cell {
    height: 25vh;
  }
  .y-axis {
    max-width: 2.5rem;
  }
</style>
<template>
  <div>
    <nav>
      <div class="breadcrumb p-2 mb-0">
        <div class="row">
          <div class="col-auto ms-3 p-0">
            <SpinnerRouterLink :loading="$apollo.loading">9 Box</SpinnerRouterLink>
          </div>
        </div>
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12">
              <Search
                v-model="search"
                :inputs="['users', 'evaluation_cycles']"
              ></Search>
            </div>
          </div>
        </div>
      </div>
    </nav>
    <div class="container-fluid mb-2">
      <div class="row">
        <div class="col d-flex align-items-center flex-nowrap y-axis">
          <h4 class="fa-rotate-180 y-label sortable m-0 p-0">
            Potential
            <i class="fa-solid fa-right-long mb-1 fa-rotate-90"></i>
          </h4>
        </div>
        <div class="col">
          <div ref="box" class="position-relative drop-zone">
            <!-- Iterate each member -->
            <template v-for="row in performance_evaluations_nine_box">
              <div v-if="!search.user_id && row.row_number === 1" :key="row.id" class="position-absolute sortable" :title="row.user.name" :style="markerStyle(row)">
                <router-link :title="row.user.name" :to="{ name: 'Performance Evaluation Detail', params: { id: row.id }}" target="_blank">
                  <img class="rounded-circle" :src="row.user.avatar" width="40px" height="40px">
                </router-link>
              </div>
              <div v-if="search.user_id" :key="row.id" class="position-absolute sortable" :title="row.user.name" :style="markerStyle(row)">
                <!-- show numbers only if user is filtering by user -->
                <router-link :title="row.user.name" :to="{ name: 'Performance Evaluation Detail', params: { id: row.id }}" target="_blank">
                  <img class="rounded-circle" :src="row.user.avatar" width="40px" height="40px">
                  <span class="position-absolute fw-bold link-light">{{row.row_number}}</span>
                </router-link>
              </div>
            </template>
            <div class="row">
              <div class="cell col m-2 p-2 bg-company-secondary-ligth d-flex flex-column align-items-center justify-content-center">
                <h4>Potential Gem</h4>
                <p>High Potential / Low Performance</p>
              </div>
              <div class="cell col m-2 p-2 bg-company-primary d-flex flex-column align-items-center justify-content-center">
                <h4>High Potential</h4>
                <p>High Potential / Moderate Performance</p>
              </div>
              <div class="cell col m-2 p-2 bg-company-primary d-flex flex-column align-items-center justify-content-center">
                <h4>Star</h4>
                <p>High Potential / High Performance</p>
              </div>
            </div>
            <div class="row">
              <div class="cell col m-2 p-2 bg-company-third-ligth d-flex flex-column align-items-center justify-content-center">
                <h4>Inconsistent Player</h4>
                <p>Moderate Potential / Low Performance</p>
              </div>
              <div class="cell col m-2 p-2 bg-company-secondary-ligth d-flex flex-column align-items-center justify-content-center">
                <h4>Core Player</h4>
                <p>Moderate Potential / Moderate Performance</p>
              </div>
              <div class="cell col m-2 p-2 bg-company-primary d-flex flex-column align-items-center justify-content-center">
                <h4>High Performer</h4>
                <p>Moderate Potential / High Performance</p>
              </div>
            </div>
            <div class="row">
              <div class="cell col m-2 p-2 bg-company-third-ligth d-flex flex-column align-items-center justify-content-center">
                <h4>Risk</h4>
                <p>Low Potential / Low Performance</p>
              </div>
              <div class="cell col m-2 p-2 bg-company-third-ligth d-flex flex-column align-items-center justify-content-center">
                <h4>Average Performer</h4>
                <p>Low Potential / Moderate Performance</p>
              </div>
              <div class="cell col m-2 p-2 bg-company-secondary-ligth d-flex flex-column align-items-center justify-content-center">
                <h4>Solid Performer</h4>
                <p>Low Potential / High Performance</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="text-center">
        <h4>Performance <i class="fa-solid fa-right-long"></i></h4>
      </div>
    </div>
  </div>
</template>
<script>
import { Search, SpinnerRouterLink } from '../shared';
import { SearchBuilder } from '../../shared';
import NINE_BOX_QUERY from './queries';

export default {
  components: { SpinnerRouterLink, Search },
  data() {
    return {
      search: {},
    };
  },
  apollo: {
    performance_evaluations_nine_box: {
      query: NINE_BOX_QUERY,
      errorPolicy: 'all',
      variables() {
        return SearchBuilder.build(this.search);
      },
      error(error) {
        this.$toasted.error(error.message);
      },
    },
  },
  methods: {
    invertYAxis(y) {
      const min = 0;
      const max = 3;
      return max - y + min;
    },
    markerStyle(row) {
      const marker = { x: 0, y: 0 };
      if (!this.$refs.box) {
        return {};
      }
      const x = (row.nine_box_performance_score * 100) / 3;
      const y = (this.invertYAxis(row.nine_box_potential_score) * 100) / 3;
      marker.x += (this.$refs.box.clientWidth * (x / 100)) - 20;
      marker.y += this.$refs.box.clientHeight * (y / 100);
      return { left: `${marker.x}px`, top: `${marker.y}px` };
    },
  },
};
</script>
