<style scoped>
/* show only one card when the device is Small or Extra small */
@media (max-width: 767.98px) {
  .skeleton:not(:first-child) {
    display: none;
  }
}
</style>

<template>
  <div class="row" id="skeleton">
    <div :key="item" class="skeleton" :class="bootstrapClass" v-for="(item) of cards">
      <div class="skeleton-rect"></div>
    </div>
  </div>
</template>
<script>

export default {
  props: ['cards', 'executeScroll'],
  computed: {
    bootstrapClass() {
      const col = Math.floor(12 / this.cards);
      return [
        `col-md-${col}`,
        'col-sm-12',
      ];
    },
  },
  mounted() {
    this.scroll();
  },
  methods: {
    scroll() {
      const observer = new IntersectionObserver((entries) => {
        if (this.executeScroll && entries[0].isIntersecting === true) {
          this.$emit('showMore');
        }
      }, { threshold: [0.5] });
      observer.observe(document.querySelector('#skeleton'));
    },
  },
};
</script>

<style scoped>
/* Styles for the loading skeleton */
.skeleton {
  position: relative;
  margin-bottom: 15px;
  overflow: hidden;
  border-radius: 8px;
}

/* Styles for the loading "cards" */
.skeleton-rect {
  background: linear-gradient(90deg, #e0e0e0 25%, #f4f4f4 50%, #e0e0e0 75%);
  background-size: 200% 100%;
  width: 100%;
  height: 150px; /* Fixed height to simulate a card */
  border-radius: 8px;
  animation: shine 1.5s infinite;
}

/* Animation for the lateral light sweep effect */
@keyframes shine {
  0% {
    background-position: -100% 0;
  }
  100% {
    background-position: 100% 0;
  }
}

/* Show only one card when the device is Small or Extra small */
@media (max-width: 767.98px) {
  .skeleton:not(:first-child) {
    display: none;
  }
}
</style>
