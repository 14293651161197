<template>
  <div>
    <nav>
      <div class="breadcrumb p-2">
        <div class="row">
          <div class="col-auto ms-3 p-0">
            <SpinnerRouterLink :loading="$apollo.loading">Vacation Requests</SpinnerRouterLink>
          </div>
        </div>
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12 m-auto mt-2">
              <Search v-model="search"></Search>
            </div>
          </div>
        </div>
      </div>
    </nav>
    <div class="row" v-if="paid_time_off_requests">
      <div class="col-md-12">
        <table class="table" >
          <thead>
            <tr>
              <SortByColumn :sort_by_field="'user_id'" :orderBy="orderBy" @refresh="refresh">Person</SortByColumn>
              <SortByColumn :sort_by_field="'total_days'" :orderBy="orderBy" @refresh="refresh">Total days</SortByColumn>
              <th>Requested dates</th>
              <SortByColumn :sort_by_field="'status'" :orderBy="orderBy" @refresh="refresh">Status</SortByColumn>
              <SortByColumn :sort_by_field="'assigned_to_id'" :orderBy="orderBy" @refresh="refresh">Assigned to</SortByColumn>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr :key="row.id" v-for="row in paid_time_off_requests.data">
              <td>
                <router-link v-if="row.user" :to="`/users?id=${row.user.id}`" target="_blank" class="text-decoration-none">
                  {{row.user.name}}
                </router-link>
              </td>
              <td>{{row.total_days}}</td>
              <td>
                {{formattedDates(row.dates)}}
              </td>
              <td class="text-capitalize">
                <span>{{row.status}}</span>
              </td>
              <td v-if="row.assigned_to"> {{row.assigned_to.name}} </td>
              <td v-if="!row.assigned_to"> </td>
              <td v-if="row.status === 'reversed'">
                <div>
                  <Confirmation :icon="'fas fa-ban'" :text="'Reject'" v-if="!row.is_approving"
                    v-model="row.is_rejecting"
                    @accepted="resolve(row, 'reject')"
                  />
                  <Confirmation :icon="'fas fa-check'" :text="'Approve'" v-if="!row.is_rejecting"
                    v-model="row.is_approving"
                    @accepted="resolve(row, 'approve')"
                  />
                </div>
              </td>
              <td v-if="row.status === 'approved' || row.status === 'rejected'">
                <Confirmation :icon="'fas fa-undo-alt'" :text="'Reverse'"
                  v-model="row.is_reversing"
                  @accepted="resolve(row, 'reverse')"
                />
              </td>
              <td v-if="row.status === 'pending'">
                <div>
                  <Confirmation :icon="'fas fa-ban'" :text="'Reject'" v-if="!row.is_approving"
                    v-model="row.is_rejecting"
                    @accepted="resolve(row, 'reject')"
                  />
                  <Confirmation :icon="'fas fa-check'" :text="'Approve'" v-if="!row.is_rejecting"
                    v-model="row.is_approving"
                    @accepted="resolve(row, 'approve')"
                  />
                </div>
              </td>
            </tr>
            <SkeletonRow v-if="paid_time_off_requests && paid_time_off_requests.paginatorInfo.hasMorePages" :colspan=6 :executeScroll="paid_time_off_requests != undefined" @showMore="showMore('paid_time_off_requests')"/>
          </tbody>
          </table>
      </div>
    </div>
    <Empty v-if="!paid_time_off_requests?.data.length" icon="fas fa-umbrella-beach"/>
  </div>
</template>

<script>

import dayjs from 'dayjs';
import PAID_TIME_OFF_REQUESTS from './queries';
import RESOLVE_PAID_TIME_OFF_REQUEST from './mutations';
import Search from './Search.vue';
import { ErrorHandler, PaginationHandler } from '../../../shared';
import {
  SkeletonRow, SortByColumn, Confirmation, SpinnerRouterLink,
} from '../../shared';

export default {
  extends: PaginationHandler,
  components: {
    Search, SkeletonRow, SortByColumn, Confirmation, SpinnerRouterLink,
  },
  data() {
    return {
      search: {},
      loading: false,
      selectedItem: false,
      confirmation: false,
      orderBy: { column: 'id', order: 'DESC' },
      users: {},
    };
  },
  apollo: {
    paid_time_off_requests: {
      query: PAID_TIME_OFF_REQUESTS,
      errorPolicy: 'all',
      fetchPolicy: 'network-only',
      variables() {
        return {
          page: 1, assigned_to_id: this.search.assigned_to_id, orderBy: [this.orderBy], ...this.search,
        };
      },
      update(data) {
        const newData = { ...data.paid_time_off_requests };
        newData.data = newData.data.map((register) => ({
          ...register,
          is_approving: false,
          is_rejecting: false,
          is_reversing: false,
        }));
        return newData;
      },
      error(error) {
        ErrorHandler.handle(error, this);
      },
    },
  },
  methods: {
    queryVariables() {
      return { orderBy: [{ column: 'id', order: 'DESC' }], ...this.search };
    },
    refresh() {
      this.$apollo.queries.paid_time_off_requests.refetch();
    },
    formattedDates(dates) {
      return (_.map(dates, (d) => dayjs(d.date).format('D MMM YYYY'))).join(', ');
    },
    resolve(request, action) {
      this.$apollo.mutate({
        mutation: RESOLVE_PAID_TIME_OFF_REQUEST,
        variables: {
          id: request.id,
          action,
        },
      }).then(() => {
        this.resolveMessage(request.user.name, action);
      }).catch((error) => {
        ErrorHandler.handle(error, this);
      });
    },
    resolveMessage(userName, action) {
      this.remove();
      this.$toasted.success(`Request from ${userName} was ${action}.`);
      this.refresh();
    },
    handleConfirmation(item) {
      this.selectedItem = item;
      this.confirmation = true;
    },
    remove() {
      this.selectedItem = false;
      this.confirmation = false;
    },
  },
};
</script>
