<template>
  <div>
    <Modal :show="show" @close="close()">
      <div>
        <h2 class="company-text-primary text-center fw-bold">
          {{ localRecord.name }}
        </h2>
        <p class="card-text" v-html="localRecord.description"></p>
        <p class="card-text">
          <span class="text-primary">Evaluation Methodology:</span>
          <span v-html="localRecord.evaluation_methodology"></span>
        </p>
        <div class="card-text">
          <span class="text-primary">Documentation Links:</span>
          <ul>
            <li :key="`${localRecord.id}-${index}`" v-for="(link, index) in documentationLinks">
              <a :href="link" target="_blank">{{link}}</a>
            </li>
          </ul>
        </div>
      </div>
      <div class="row">
        <div class="col-12 d-flex flex-column justify-content-center m-4">
          <div v-if="localUserNode.evaluated_by_user">
            <span class="text-xs">
              Evaluated by: <span class="fw-bold">{{ localUserNode.evaluated_by_user.name }}</span><span> {{ timeAgo }}</span><br />
              Evaluation Notes:
            </span>
            <blockquote class="blockquote">
              <p v-html="localUserNode.notes"></p>
              <footer class="blockquote-footer">
                {{ localUserNode.evaluated_by_user.name }}
              </footer>
            </blockquote>
          </div>
          <div v-if="!localUserNode.is_successful" class="float-end">
            <button v-if="status === 'Pending' || status === 'Unsuccessful'" @click="updateStatus('Ready for evaluation')" class="btn btn-company-primary btn-sm">
              <i class="fas fa-check"></i>
              Ready for evaluation
            </button>
            <button v-if="status === 'Ready for evaluation'" @click="updateStatus('Pending')" class="btn btn-company-secondary btn-sm">
              <i class="fas fa-times"></i>
              <span>Pending for evaluation</span>
            </button>
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import UPDATE_LEARNING_PATH_NODE_USER_STATUS from './mutations';
import Modal from './Modal.vue';

export default {
  components: { Modal },
  props: ['show', 'userNode', 'record'],
  data() {
    return {
      status: 'Pending',
    };
  },
  computed: {
    localUserNode() {
      return { ...this.userNode };
    },
    localRecord() {
      return { ...this.record };
    },
    timeAgo() {
      return dayjs(this.localUserNode.evaluated_at, 'YYYY-MM-DD').endOf('day').fromNow();
    },
    documentationLinks() {
      return this.localRecord.documentation_links.split('\n');
    },
  },
  methods: {
    updateStatus(status) {
      this.$apollo
        .mutate({
          mutation: UPDATE_LEARNING_PATH_NODE_USER_STATUS,
          variables: {
            learning_path_node_user: {
              learning_path_level_node_id: this.localRecord.id,
              status,
            },
          },
        }).then(({ data }) => {
          this.status = data.updateLearningPathNodeUserStatus.status;
          this.$emit('update-status', this.status);
        });
    },
    close() {
      this.$emit('close');
    },
  },
  mounted() {
    this.status = this.localUserNode.status ?? 'Pending';
  },
};
</script>
