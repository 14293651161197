import { gql } from '@apollo/client/core';

export const NET_PROMOTER_SCORE_FRAGMENT = gql`
  fragment netPromoterScore on NetPromoterScore {
    id
    score
    reason
    client_id
    client {
      name
    }
    project_id
    project {
      name
    }
  }
`;

export const NET_PROMOTER_SCORE_STATISTICS_FRAGMENT = gql`
  fragment npsFragment on NetPromoterScoreStatistics {
    nps_percentage
    total
    promoters
    detractors
    neutrals
    detractors_percentage
    promoters_percentage
    neutrals_percentage
    total_0
    total_1
    total_2
    total_3
    total_4
    total_5
    total_6
    total_7
    total_8
    total_9
    total_10
}
`;
