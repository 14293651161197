import { gql } from '@apollo/client/core';
import HOLIDAY_FRAGMENT from '../../vacation/holidays/fragments';

export default gql`
  query($year: Int!, $country_id: ID, $project_id: ID) {
    teamHolidays(year: $year, country_id: $country_id, project_id: $project_id) {
      ...holiday
    }
  },
  ${HOLIDAY_FRAGMENT}
`;
