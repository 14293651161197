import { gql } from '@apollo/client/core';
import ACTION_FRAGMENT from './fragments';

export const CREATE_ACTIONS = gql`
mutation($input: ActionInput!){
  createAction(input: $input){
    ...action
  }
}
${ACTION_FRAGMENT}
`;

export const UPDATE_ACTIONS = gql`
  mutation($id: ID!, $input: ActionInput!) {
    updateAction(id: $id, input: $input) {
      id
    }
  }
`;
