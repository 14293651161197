<style>
.style-chooser .vs__search::placeholder,
.style-chooser .vs__dropdown-toggle,
.style-chooser .vs__dropdown-menu {
  background: #ffffff;
}

.style-chooser .vs__dropdown-toggle {
  height: 35px !important;
}

.style-chooser .vs__clear,
.style-chooser .vs__open-indicator {
  fill: red;
}
.v-select {
  font-size: 0.9rem;
  width: 100%;
  text-transform: none;
}
label{
    margin:5px
  }
</style>
<template>
  <div class="company-control">
    <label :for="`select_${name}`" v-if="$slots['default']">
    <slot></slot>
    </label>
    <vSelect
      v-model="option"
      :class="cssClass"
      :id="`select_${name}`"
      :label="fieldToShow"
      :filterable="false"
      :options="options"
      @search="onSearch"
      @update:modelValue="handleValue">

      <template #no-options>
        Type to search
      </template>
      <template #options="{ option }">
        <div class="d-center">
          {{ option[fieldToShow] }}
        </div>
      </template>
      <template #selected-options="{ option }">
        <div class="selected d-center">
          {{ option[fieldToShow] }}
        </div>
      </template>
    </vSelect>
  </div>
</template>
<script>
import _ from 'lodash';
import vSelect from 'vue-select';
import { h } from 'vue';
import {
  Searchable,
  SearchBuilder,
} from '../../shared';

// custom icons
vSelect.props.components.default = () => ({
  Deselect: {
    setup() {
      return () => h('i', { class: 'fa-solid fa-xmark', style: { 'line-height': '5' } });
    },
  },
  OpenIndicator: {
    setup() {
      return () => h('i', { class: 'fa-solid fa-chevron-down', style: { cursor: 'pointer' } });
    },
  },
});

export default {
  components: { vSelect },
  extends: Searchable,
  props: {
    modelValue: { default: '' },
    name: { type: String, default: '' },
    customCssClass: { type: String, default: '' },
    query: { type: Object, default: () => {} },
    queryVariables: { type: Object, default: () => {} },
    loadDefaultData: { type: Boolean, default: false },
    fieldToShow: { type: String, default: 'id' },
  },
  data() {
    return {
      option: null,
      options: [],
      isSelect: true,
    };
  },
  created() {
    this.setDefaultOption();
  },
  computed: {
    cssClass() {
      const classes = ['style-chooser'];
      if (this.customCssClass) {
        classes.push(this.customCssClass);
      }
      return classes.join(' ');
    },
    queryName() {
      /*
      the structure of a gql object is:
      {
        "kind": "Document",
        "definitions": [
          {
            "kind": "OperationDefinition",
            "operation": "query",
            "name": null,
            "variableDefinitions": null,
            "directives": [],
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [
                {
                  "kind": "Field",
                  "alias": null,
                  "name": {
                    "kind": "Name",
                    "value": "user",
                    ...
                  }
                }
              ]
            }
          }
        ]
      }

      it means that we can get the name of any query dynamically
      */
      return _.head(_.head(this.query.definitions).selectionSet.selections).name.value;
    },
  },
  methods: {
    /**
     * Trigger method when user search for a project.
     *
     */
    onSearch(q, loading) {
      // Do not seach if a single letter was typed
      if (q.length > 1) {
        loading(true);
        this.makeSearch(loading, q, this);
      }
    },

    /**
     * Handle The v-select value and emit it to parent component.
     *
     * @param {Object} option The option selected
     */
    handleValue(option) {
      if (!option || !option.id) {
        this.option = { id: '', name: '' }; // Ensure there is always a valid object
      } else {
        this.option = option;
      }
      this.$emit('update:modelValue', this.option.id);
      this.$emit('change', this.option.id);
    },

    /**
     * Method Triggered after the component was created.
     * This helps to define the value passed via v-model
     */
    setDefaultOption() {
      if (!this.modelValue && !this.loadDefaultData) return;
      const queryVariables = !this.modelValue
        ? { ...SearchBuilder.build({ ...this.queryVariables }) }
        : { id: this.modelValue, type: this.queryVariables.type };
      this.$apollo.query({
        query: this.query,
        variables: queryVariables,
      }).then((response) => {
        this.options = response.data[this.queryName];
        this.option = _.head(this.options);
      });
    },

    /**
     * Make search query
     */
    makeSearch: _.debounce((loading, search, vm) => {
      vm.$apollo.query({
        query: vm.query,
        errorPolicy: 'all',
        fetchPolicy: 'no-cache',
        variables: {
          ...SearchBuilder.build({ ...vm.queryVariables, name: search, description: search }),
        },
        error(error) {
          vm.$toasted.error(error.message);
          loading(false);
        },
      }).then((response) => {
        const res = response.data[vm.queryName];
        vm.options = res.data || res;
        loading(false);
      });
    }, 350),
  },
};
</script>
