<template>
  <div>
    <Form v-if="selectedRecord" v-bind:record="selectedRecord"
                                    @close="selectedRecord = null"
                                    @insert="insert"></Form>
    <nav>
      <div class="breadcrumb p-2" v-if="learning_path">
        <div class="col-md-2 m-auto p-0">
          <SpinnerRouterLink :loading="$apollo.loading">{{learning_path.name}}</SpinnerRouterLink>
        </div>
        <div class="col-md-8 m-auto p-0 d-flex justify-content-center">
          <ul class="nav nav-pills">
            <template v-for="level in learning_path.levels" :key="`l-${level.id}`">
              <li class="nav-item sortable">
                <a class="nav-link" :class="{'active': level.id === selectedLevel}" @click="selectedLevel = level.id">
                  {{level.name}}
                </a>
              </li>
            </template>
          </ul>
        </div>
        <div class="col-md-2 text-end">
          <button type="button" class="btn btn-company-primary" @click="selectedRecord = {}" v-if="$can('CreateLearningPath')">
            <i class="fas fa-plus"></i>
            New
          </button>
        </div>
      </div>
    </nav>
    <div class="row" v-if="(learning_path?.levels || []).length > 0 && learning_path_level">
      <Card :key="`n-${record.id}`" :record="record" v-for="record in learning_path_level.nodes" @remove="remove"></Card>
    </div>
    <Empty v-else icon="fa-solid fa-book" :can_create="true" @callCreate="selectedRecord = {}"/>
  </div>
</template>

<script>

import { LEARNING_PATH_LEVELS_QUERY, LEARNING_PATH_LEVEL_NODES_QUERY } from './queries';
import { DELETE_LEARNING_PATH_LEVEL_NODE, CREATE_LEARNING_PATH_LEVEL_NODE } from './mutations';
import { ErrorHandler } from '../../../shared';
import Card from './Card.vue';
import Form from './Form.vue';
import { SpinnerRouterLink } from '../../shared';

export default {
  components: { Card, Form, SpinnerRouterLink },
  data() {
    return {
      selectedLevel: 0,
      selectedRecord: null,
    };
  },
  apollo: {
    learning_path: {
      query: LEARNING_PATH_LEVELS_QUERY,
      errorPolicy: 'all',
      variables() {
        return { id: this.$route.params.id };
      },
      error(error) {
        this.$toasted.error(error.message);
      },
    },
    learning_path_level: {
      query: LEARNING_PATH_LEVEL_NODES_QUERY,
      errorPolicy: 'all',
      variables() {
        return { learning_path_level_id: this.selectedLevel };
      },
      error(error) {
        this.$toasted.error(error.message);
      },
    },
  },
  watch: {
    learning_path() {
      if (!this.learning_path.levels.map((l) => l.id).includes(this.selectedLevel) && this.learning_path.levels.length > 0) {
        this.selectedLevel = this.learning_path.levels[0].id;
      }
    },
  },
  methods: {
    queryVariables() {
      return { learning_path_level_id: this.selectedLevel };
    },
    insert(record) {
      // We save the user input in case of an error
      const newLearningPathNode = record;
      this.selectedRecord = null;
      this.$apollo.mutate({
        mutation: CREATE_LEARNING_PATH_LEVEL_NODE,
        variables: {
          learning_path_level_node: { ...record, learning_path_level_id: this.selectedLevel },
        },
        update: (store, { data: { createLearningPathLevelNode } }) => {
          store.modify({
            id: store.identify({ __typename: 'LearningPathLevel', id: this.selectedLevel }),
            fields: {
              nodes(existingNodes = []) {
                return [...existingNodes, createLearningPathLevelNode];
              },
            },
          });
        },
      }).catch((error) => {
        ErrorHandler.handle(error, this);
        // We restore the initial user input
        this.selectedRecord = newLearningPathNode;
      });
    },
    remove(record) {
      this.$apollo.mutate({
        mutation: DELETE_LEARNING_PATH_LEVEL_NODE,
        variables: {
          id: record.id,
        },
        update: (store, { data: { deleteLearningPathLevelNode } }) => {
          const data = store.readQuery({ query: LEARNING_PATH_LEVEL_NODES_QUERY, variables: this.queryVariables() });
          const updatedNodes = data.learning_path_level.nodes.filter((c) => c.id !== deleteLearningPathLevelNode.id);
          store.writeQuery({ query: LEARNING_PATH_LEVEL_NODES_QUERY, variables: this.queryVariables(), data: { learning_path_level: { ...data.learning_path_level, nodes: updatedNodes } } });
        },
      }).catch((error) => {
        this.$toasted.error(`Unable to delete record: ${error.message}`);
      });
    },
  },
};
</script>
