<template>
  <div class="mb-5 card">
    <div class="card-header d-flex">
      <div class="col p-0">
        <span v-if="!record.id">New Bots</span>
        <span v-if="record.id">{{ localRecord.name }}</span>
      </div>
      <div class="col text-end">
        <i class="fas fa-times" @click="$emit('close')"></i>
      </div>
    </div>
    <div class="card-body d-grid">
      <div class="mb-3">
        <label for="name">Name</label>
        <input
          v-focus
          type="text"
          class="form-control"
          :class="{'is-invalid': v$.localRecord.name.$invalid}"
          id="name"
          v-model="localRecord.name"
        />
        <div v-for="error of v$.localRecord.name.$silentErrors" :key="error.$uid">
          <div class="text-danger">{{ error.$message }}</div>
        </div>
      </div>
      <div class="mb-3">
        <PermissionPicker :selected="localRecord.permissions || []">Permissions:</PermissionPicker>
      </div>
      <button
        :disabled="v$.localRecord.$invalid"
        type="button"
        class="btn btn-company-primary"
        @click="save()">
        <i class="fas fa-save"></i>
        Save
      </button>
      <button
        type="button"
        class="btn btn-company-secondary"
        @click="$emit('close')">
        <i class="fas fa-times"></i>
        Cancel
      </button>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import Validations from './Validations';
import PermissionPicker from '../permissions/Picker.vue';
import Blinkable from '../../shared/Blinkable';

export default {
  mixins: [Blinkable, Validations],
  components: { PermissionPicker },
  props: {
    record: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      localRecord: _.cloneDeep(this.record), // Copia profunda usando lodash
    };
  },
  methods: {
    save() {
      const event = this.localRecord.id ? 'update' : 'insert';
      this.$emit(event, this.localRecord);
    },
  },
};
</script>
