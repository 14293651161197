<template>
  <div>
    <nav>
      <div class="breadcrumb p-2">
        <div class="col-md-12 m-auto p-0">
          <SpinnerRouterLink :loading="$apollo.loading">Suggestion Box</SpinnerRouterLink>
        </div>
      </div>
    </nav>
    <table class="table" v-if="suggestions">
      <thead>
        <tr>
          <th>
            <span class="sortable" @click="sortBy('title')">
              <i v-if="orderBy[0].column === 'title'" :class="sortIcon" class="fas"></i>
              Title
            </span>
          </th>
          <th>
            <span class="sortable" @click="sortBy('created_at')">
              <i v-if="orderBy[0].column === 'created_at'" :class="sortIcon" class="fas"></i>
              Date
            </span>
          </th>
          <th>
            <span class="sortable" @click="sortBy('status')">
              <i v-if="orderBy[0].column === 'status'" :class="sortIcon" class="fas"></i>
              Status
            </span>
          </th>
          <th></th>
        </tr>
      </thead>
      <tbody v-if="suggestions">
        <tr v-for="(row, index) in suggestions.data" :key="index">
          <td>{{ row.title }}</td>
          <td>{{ $displayDate(row.created_at) }}</td>
          <td class="text-capitalize">{{ row.status }}</td>
          <td>
            <Options
              v-bind:suggestion="row"
              @accept="accept(row)"
              @reject="reject(row)"
              @remove="remove(row)"
            ></Options>
          </td>
        </tr>
      </tbody>
      <SkeletonRow v-if="suggestions && suggestions.paginatorInfo.hasMorePages" :colspan="7"
                   :executeScroll="suggestions != undefined" @showMore="showMore('suggestions')"></SkeletonRow>
    </table>
    <Empty v-if="!suggestions?.data.length" icon="fas fa-bell"/>
  </div>
</template>
<script>

import SUGGESTIONS_QUERY from './queries';
import { DELETE_SUGGESTION, UPDATE_SUGGESTION } from './mutations';
import { ErrorHandler, PaginationHandler } from '../../shared';
import Options from './Options.vue';
import { SkeletonRow, SpinnerRouterLink } from '../shared';

export default {
  extends: PaginationHandler,
  components: {
    Options,
    SkeletonRow,
    SpinnerRouterLink,
  },
  data() {
    return {
      is_removing: false,
      confirmState: 0,
      orderBy: [{ column: 'id', order: 'DESC' }],
    };
  },
  apollo: {
    suggestions: {
      query: SUGGESTIONS_QUERY,
      errorPolicy: 'all',
      variables() {
        return {
          page: 1,
          orderBy: this.orderBy,
        };
      },
      error(error) {
        this.$toasted.error(error.message);
      },
    },
  },
  computed: {
    sortIcon() {
      return this.asc() ? 'fa-sort-alpha-down' : 'fa-sort-alpha-down-alt';
    },
    localRecord() {
      return {
        ...this.suggestions,
      };
    },
  },
  methods: {
    suggestionInput(suggestion) {
      return {
        title: suggestion.title,
        suggestion: suggestion.suggestion,
        status: suggestion.status,
      };
    },
    queryVariables() {
      return { orderBy: this.orderBy };
    },
    refresh() {
      this.$apollo.queries.suggestions.refetch();
    },
    asc() {
      return this.orderBy[0].order === 'ASC';
    },
    sortBy(column) {
      if (this.orderBy[0].column === column) {
        this.orderBy[0].order = this.orderBy[0].order === 'ASC' ? 'DESC' : 'ASC';
      } else {
        this.orderBy[0].column = column;
        this.orderBy[0].order = 'ASC';
      }
      this.refresh();
    },
    edit(id) {
      return this.$router.replace({ path: `/suggestions/${id}` });
    },
    remove(suggestion) {
      this.is_removing = true;
      this.$apollo.mutate({
        mutation: DELETE_SUGGESTION,
        variables: {
          id: suggestion.id,
        },
        refetchQueries: [{ query: SUGGESTIONS_QUERY }],
      }).then(() => {
        this.is_removing = false;
        this.$toasted.success('Suggestion removed successfully');
        this.$apollo.queries.suggestions.refetch();
      }).catch((error) => {
        this.is_removing = false;
        ErrorHandler.handle(error, this);
      });
    },
    reject(suggestion) {
      this.loading = true;
      const newData = { ...suggestion, status: 'rejected' };
      this.$apollo.mutate({
        mutation: UPDATE_SUGGESTION,
        variables: {
          id: suggestion.id,
          input: this.suggestionInput(newData),
        },
      }).catch((error) => {
        ErrorHandler.handle(error, this);
      }).then(() => {
        this.loading = false;
        this.$toasted.success('Suggestion rejected successfully');
        this.$apollo.queries.suggestions.refetch();
      });
    },
    accept(suggestion) {
      this.loading = true;
      const newData = { ...suggestion, status: 'approved' };
      this.$apollo.mutate({
        mutation: UPDATE_SUGGESTION,
        variables: {
          id: suggestion.id,
          input: this.suggestionInput(newData),
        },
      }).catch((error) => {
        ErrorHandler.handle(error, this);
      }).then(() => {
        this.loading = false;
        this.$toasted.success('Suggestion approved successfully');
        this.$apollo.queries.suggestions.refetch();
      });
    },
  },
};
</script>
