import { gql } from '@apollo/client/core';

import PERFORMANCE_EVALUATION_CYCLE_FRAGMENT from './fragments';

export const CREATE_PERFORMANCE_EVALUATION_CYCLE = gql`
  mutation($performance_evaluation_cycle: PerformanceEvaluationCycleInput!){
    createPerformanceEvaluationCycle(input: $performance_evaluation_cycle){
      ...performance_evaluation_cycle
    }
  }
  ${PERFORMANCE_EVALUATION_CYCLE_FRAGMENT}
`;

export const UPDATE_PERFORMANCE_EVALUATION_CYCLE = gql`
  mutation($id: ID!, $performance_evaluation_cycle: PerformanceEvaluationCycleInput!){
    updatePerformanceEvaluationCycle(id: $id, input: $performance_evaluation_cycle){
      ...performance_evaluation_cycle
    }
  }
  ${PERFORMANCE_EVALUATION_CYCLE_FRAGMENT}
`;

export const DELETE_PERFORMANCE_EVALUATION_CYCLE = gql`
  mutation($id: ID!){
    deletePerformanceEvaluationCycle(id: $id){
      id
    }
  }
`;
