import { gql } from '@apollo/client/core';

export default gql`
  fragment promotion on Promotion {
    id
    from_job_title_id
    to_job_title_id
    date
    next_job_title_id
    next_promotion_date
    notes
    user_id
    created_by_id
    user {
      id
      name
    }
    from_job_title {
      id
      internal_description
    }
    to_job_title {
      id
      internal_description
    }
    next_job_title {
      id
      internal_description
    }
  }
`;
