import { gql } from '@apollo/client/core';

export default gql`
query($query: String!) {
  AiQuery(query: $query) {
    id
    user_id
    question
    answer
  }
}
`;
