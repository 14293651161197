import { gql } from '@apollo/client/core';

export default gql`
  fragment performance_evaluation_cycle on PerformanceEvaluationCycle {
    id
    name
    is_active
    start_date
    end_date
  }
`;
