import { gql } from '@apollo/client/core';

import SIGNATURE_FRAGMENT from './fragments';

export default gql`
query profileAndSignature {
  profile: me {
    name
    email
    job_title {
      external_description
    }
    personal_phone
    phone_extension
  }
  signature {
    ...signature
  }
}
${SIGNATURE_FRAGMENT}
`;
