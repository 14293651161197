import { useVuelidate } from '@vuelidate/core';
import {
  required, maxLength, minValue, maxValue, requiredIf,
} from '@vuelidate/validators';

export default {
  data() {
    return {
      v$: useVuelidate(),
    };
  },
  validations: {
    localRecord: {
      name: { required, maxLengthValue: maxLength(255) },
      identification: { required, maxLengthValue: maxLength(255) },
      hiring_date: { required },
      date_of_birth: { required },
      english_level: { required },
      job_title_id: { required },
      working_time: { required, minValueValue: minValue(0.25), maxValueValue: maxValue(1) },
      country_id: { required },
      emergency_contact: { required },
      termination_date: {
        requiredIfFuction: requiredIf((localRecord) => !localRecord.is_active),
      },
      termination_reason: {
        requiredIfFuction: requiredIf((localRecord) => !localRecord.is_active),
      },
    },
  },
};
