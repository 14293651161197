import { gql } from '@apollo/client/core';
import EQUIPMENT_FRAGMENT from './fragments';

export const CREATE_EQUIPMENT = gql`
  mutation($record: EquipmentInput!){
    createEquipment(input: $record){
      ...equipment
    }
  }
  ${EQUIPMENT_FRAGMENT}
`;

export const UPDATE_EQUIPMENT = gql`
  mutation($id: ID!, $record: EquipmentInput!){
    updateEquipment(id: $id, input: $record){
      ...equipment
    }
  }
  ${EQUIPMENT_FRAGMENT}
`;

export const DELETE_EQUIPMENT = gql`
  mutation($id: ID!){
    deleteEquipment(id: $id){
      id
    }
  }
`;

export const RECOVERY_EQUIPMENT = gql`
  mutation($id: ID!){
    recoverEquipment(id: $id){
      id
    }
  }
`;

export const EXPORT_EQUIPMENT = gql`
  mutation($acquired_date: Date, $type: String, $location: String, $serial_number: String, $internal_key: String, $model: String, $name: String){
    exportEquipmentToExcel(acquired_date: $acquired_date, type: $type, location: $location, serial_number: $serial_number, internal_key: $internal_key, model: $model, name: $name)
  }
`;
