<template>
  <div>
    <nav v-if="project">
      <div class="breadcrumb p-2">
        <div class="col-md-10 m-auto p-0">
          <ol class="breadcrumb p-0 m-auto">
            <li class="breadcrumb-item">
              <router-link to="/home">
                <i class="fas fa-home"></i>
              </router-link>
            </li>
            <li class="breadcrumb-item active fw-bold" aria-current="page">
              <router-link to="/projects">Projects</router-link>
            </li>
            <li class="breadcrumb-item fw-bold" aria-current="page">{{project.name}}</li>
          </ol>
        </div>
        <div class="col-md-2 text-end">
          <button type="button" class="btn btn-company-primary" @click="save()">
            <i v-if="loading" class="fas fa-sync fa-spin"></i>
            <i v-if="!loading" class="fas fa-save"></i>
            Save
          </button>
          <button type="button" class="btn btn-secondary" @click="discard()">
            <i class="fas fa-undo"></i>
            Discard
          </button>
        </div>
      </div>
    </nav>
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-12">
          <ul class="nav nav-tabs" role="tablist">
            <li class="nav-item">
              <a class="nav-link active" id="estimation-tab" data-bs-toggle="tab" href="#estimation" role="tab" aria-controls="estimation" aria-selected="true">Estimation</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" id="my-hours-tab" data-bs-toggle="tab" href="#my-hours" role="tab" aria-controls="my-hours" aria-selected="false">My Hours</a>
            </li>
          </ul>
          <div class="tab-content" v-if="project">
            <div class="tab-pane fade show active" id="estimation" role="tabpanel" aria-labelledby="estimation-tab">
              <div class="row mt-3">
                <div class="col-md-12">
                  <table v-if="project.estimation" class="table">
                    <thead>
                      <tr>
                        <th>Bucket</th>
                        <th>Progress</th>
                        <th>User</th>
                        <th v-if="hasDeliverables">Deliverable</th>
                        <th>Add Time</th>
                      </tr>
                    </thead>
                    <tbody>
                      <template v-for="bucket_estimation in project.estimation.bucket_estimations" :key="bucket_estimation.id">
                        <HourRow
                          @update="update"
                          :bucket_estimation="bucket_estimation"
                          :members="project.members"
                        ></HourRow>
                      </template>
                    </tbody>
                  </table>
                  <template v-else>
                    <HourRowWithoutEstimation @update="update" :members="project.members"></HourRowWithoutEstimation>
                    <ul class="list-group">
                      <li :key="bucket_estimation_hour.id" v-for="bucket_estimation_hour in bucket_estimation_hours" class="list-group-item d-flex justify-content-between align-items-center">
                        <div>
                          <p>{{project.members.find(m => m.user.id === bucket_estimation_hour.user_id).user.name}}</p>
                          <span class="text-body-secondary">{{formatDate(bucket_estimation_hour.created_at)}}</span>
                        </div>
                        <p v-html="bucket_estimation_hour.activities"></p>
                        <p class="m-0">Hours: {{bucket_estimation_hour.hours}}</p>
                      </li>
                    </ul>
                  </template>
                </div>
              </div>
            </div>
            <div class="tab-pane fade" id="my-hours" role="tabpanel" aria-labelledby="my-hours-tab">
              <div class="row mt-3">
                <div class="col-md-12">
                  <MyHoursIntegration/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { PROJECT_QUERY } from './queries';
import { GET_INTEGRATION } from '../integrations/queries';
import { CREATE_BUCKET_ESTIMATION_HOURS } from '../estimations/mutations';
import HourRow from './HourRow.vue';
import MyHoursIntegration from './MyHoursIntegration.vue';
import HourRowWithoutEstimation from './HourRowWithoutEstimation.vue';

export default {
  components: { HourRow, HourRowWithoutEstimation, MyHoursIntegration },
  data() {
    return {
      bucket_estimation_hours: [],
      loading: false,
    };
  },
  apollo: {
    project: {
      query: PROJECT_QUERY,
      errorPolicy: 'all',
      variables() {
        return { id: this.$route.params.id };
      },
      error(error) {
        this.$toasted.error(error.message);
      },
    },
    integration: {
      query: GET_INTEGRATION,
      errorPolicy: 'all',
      variables() {
        return { identification: 'MY_HOURS' };
      },
      error(error) {
        this.$toasted.error(error.message);
      },
    },
  },
  computed: {
    hasDeliverables() {
      return (this.project.estimation.deliverables || []).length > 0;
    },
  },
  methods: {
    formatDate(date) {
      if (date) {
        return date.slice(0, 10);
      }
      const dateNow = new Date();
      return `${dateNow.getFullYear()}-${dateNow.getMonth() + 1}-${dateNow.getDate()}`;
    },
    percentage(bucketEstimation) {
      return (
        (bucketEstimation.total_hours_consumed * 100)
        / bucketEstimation.total_hours
      ).toFixed(2);
    },
    update(bucketEstimationHour) {
      bucketEstimationHour.project_id = this.$route.params.id;
      // clear null values
      bucketEstimationHour = _.pickBy(bucketEstimationHour, (v) => v !== null);
      this.bucket_estimation_hours.push(bucketEstimationHour);
    },
    save() {
      this.loading = true;
      this.$apollo
        .mutate({
          mutation: CREATE_BUCKET_ESTIMATION_HOURS,
          variables: {
            bucket_estimation_hours: this.bucket_estimation_hours,
          },
        }).then(() => {
          this.loading = false;
          this.bucket_estimation_hours = [];
        });
    },
    discard() {
      if (this.project.estimation) {
        this.bucket_estimation_hours.forEach((bucketEstimationHour) => {
          const item = this.project.estimation.bucket_estimations.find(
            (b) => b.id === bucketEstimationHour.bucket_estimation_id,
          );
          if (item) {
            item.total_hours_consumed -= bucketEstimationHour.hours;
          }
        });
      }
      this.bucket_estimation_hours = [];
    },
  },
};
</script>
