<template>
  <div class="d-flex flex-column justify-content-between h-100">
    <div class="my-2">
      <label for="hours" class="form-label">Hours</label>
      <input type="number" :ref="'hours'" id="hours" v-model="record.hours" class="form-control" v-focus/>
    </div>
    <div class="mb-2" v-if="buckets && buckets.length > 0">
      <label for="type" class="form-label">Type</label>
      <select id="type" class="form-control custom-select" v-model="record.bucket_estimation_id">
        <option :modelValue="null">
          None
        </option>
        <option v-for="bucket in buckets" :modelValue="bucket.id" :key="bucket.id">
          {{ bucket.name }}
        </option>
      </select>
    </div>
    <div class="mb-2 mb-lg-0 h-100">
      <label for="detail" class="form-label">Detail:</label>
      <textarea class="h-50 form-control" v-model="record.activities" :placeholder="placeholder" ></textarea>
    </div>
  </div>
</template>

<script setup>
import * as vue from 'vue';
import { State } from '../../State';

const props = defineProps({
  modelValue: { type: Object, default: () => ({}) },
  buckets: { type: Array },
  placeholder: { type: String },
});

const hours = vue.ref(null);
const record = vue.computed(() => props.modelValue);

vue.watch(vue.computed(() => State.value.isLoading), async () => {
  await vue.nextTick(); hours.value.focus();
});
vue.watch(vue.computed(() => record.value.hours), (value) => {
  record.value.hours = Number.isNaN(value) || value < 0 ? 0 : Number.parseFloat(value);
});
</script>
