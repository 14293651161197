<template>
<div :id="record.id" class="col-md-2 pb-2">
  <Form v-if="is_editting" v-bind:record="record"
                            @close="is_editting = false"
                            @update="update"></Form>
  <div v-if="!is_editting" class="card h-100">
    <div class="card-body pb-0">
      <h5 class="card-title">
        {{record.name}}
      </h5>
      <h6 class="card-subtitle mb-2 text-body-secondary">
        {{record.total_members}} people.
      </h6>
    </div>
    <div class="card-body">
      <button v-if="!confirmation && $can('UpdateBusinessUnit')" class="btn btn-company-primary" @click="is_editting = true">
        <i class="fas fa-pen"></i>
        Edit
      </button>
      <Confirmation v-if="!is_editting && $can('DeleteBusinessUnit')"
        :modelValue="confirmation"
        @update:modelValue="confirmation = $event"
        @accepted="remove()"
      />
    </div>
  </div>
</div>
</template>

<script>
import BUSINESS_UNITS_QUERY from './queries';
import { UPDATE_BUSINESS_UNIT } from './mutations';
import Form from './Form.vue';
import { SearchBuilder, ErrorHandler } from '../../shared';
import { Confirmation } from '../shared';

export default {
  components: { Form, Confirmation },
  props: ['record'],
  data() {
    return {
      is_editting: false,
      confirmation: false,
    };
  },
  methods: {
    update(businessUnit) {
      this.is_editting = false;
      this.$apollo.mutate({
        mutation: UPDATE_BUSINESS_UNIT,
        variables: {
          id: businessUnit.id,
          business_unit: _.pick(businessUnit, ['name']),
        },
        update: (cache, { data: { updateBusinessUnit } }) => {
          const variables = { page: 1, ...SearchBuilder.build(this.$parent.search) };
          const data = cache.readQuery({ query: BUSINESS_UNITS_QUERY, variables });
          const updatedRecords = data.business_units.data.map((record) => (record.id === updateBusinessUnit.id ? updateBusinessUnit : record));
          const newData = {
            ...data,
            business_units: {
              ...data.business_units,
              data: updatedRecords,
            },
          };
          cache.writeQuery({ query: BUSINESS_UNITS_QUERY, data: newData, variables });
        },
      }).catch((error) => {
        ErrorHandler.handle(error, this);
      });
    },
    remove() {
      this.$emit('remove', this.record);
      this.is_editting = false;
      this.confirmation = false;
    },
  },
};
</script>
