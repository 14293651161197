import { gql } from '@apollo/client/core';

export default gql`
  query($start_date: DateTime!,
        $end_date: DateTime!,
        $client_id: ID,
        $user_id: ID,
        $project_id: ID,
        $only_my_projects: Boolean,
        $orderBy: [OrderByClause!]) {
    projectForecast(start_date: $start_date,
                    end_date: $end_date,
                    client_id: $client_id,
                    user_id: $user_id,
                    project_id: $project_id,
                    only_my_projects: $only_my_projects,
                    orderBy: $orderBy) @connection(key: "projectForecast", filter: ["start_date", "end_date", "client_id", "user_id", "project_id", "only_my_projects"]) {
      project_id
      project
      person
      user_id
      hours_per_week
      actual_hours
      expected_hours
      holidays
      vacation_days
    }
  }
`;
