<template>
  <div>
    <Form v-if="selectedRecord != null" v-bind:record="selectedRecord" @close="selectedRecord = null" @insert="insert">
    </Form>
    <nav>
      <div class="breadcrumb p-2">
        <div class="row">
          <div class="col-auto ms-3 p-0">
            <SpinnerRouterLink :loading="$apollo.loading">Equipment</SpinnerRouterLink>
          </div>
        </div>
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12 m-2">
              <Search
                v-model="search"
                :inputs="['users', 'model', 'serie', 'key', 'equipmentType', 'equipment_state']"
                class=""
              ></Search>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="d-inline-flex form-check form-switch mt-2 ps-2">
                <div class="form-check">
                  <div class="form-check">
                    <input id="active_only" v-model="active_only" class="form-check-input" type="checkbox"
                      @click="onActiveClick()">
                    <label class="form-check-label m-auto" for="active_only">Active only</label>
                  </div>
                </div>
              </div>
              <div class="d-inline-flex form-check form-switch ps-2 mb-3">
                <div class="form-check">
                  <div class="form-check">
                    <input id="switchUnassigneds" v-model="inCellar" class="form-check-input" type="checkbox"
                      @click="inCellar = !inCellar" />
                    <label class="form-check-label m-auto" for="switchUnassigneds">Show in Cellar</label>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 order-last text-end">
                <button v-if="$can('CreateEquipment')" class="btn btn-company-primary" type="button" @click="newRecord">
                  <i class="fas fa-plus"></i>
                  New
                </button>
                <button :disabled="isExporting" class="btn btn-company-primary" type="button" @click="exportFile()">
                  <i v-if="isExporting" class="fas fa-sync fa-spin"></i>
                  <i v-if="!isExporting" class="fa fa-file-excel"></i>
                  {{ exportButtonLabel }}
                </button>
            </div>
          </div>
        </div>
      </div>
    </nav>
    <div class="row" v-if="equipment">
      <Empty v-if="!equipment.data.length" icon="fas fa-archive" :can_create="true" @callCreate="selectedRecord = { users: [] }"/>
      <Card
        v-for="(record, index) in equipment.data"
        :key="index"
        :readOnlyMode="!active_only"
        :record="record"
        :recoveryMode="!active_only"
        @recover="recoverEquipment"
        @remove="remove"
      ></Card>
    </div>
    <SkeletonCards
      v-if="equipment && equipment.paginatorInfo.hasMorePages"
      :cards=4
      :executeScroll="equipment != undefined"
      @showMore="showMore('equipment')"
    ></SkeletonCards>
  </div>
</template>

<script>

import dayjs from 'dayjs';
import EQUIPMENT_QUERY from './queries';
import {
  CREATE_EQUIPMENT, DELETE_EQUIPMENT, EXPORT_EQUIPMENT, RECOVERY_EQUIPMENT,
} from './mutations';
import { Search, SkeletonCards, SpinnerRouterLink } from '../shared';
import Form from './Form.vue';
import {
  Base64File, ErrorHandler, PaginationHandler, SearchBuilder,
} from '../../shared';
import Card from './Card.vue';

export default {
  extends: PaginationHandler,
  components: {
    Card,
    Search,
    Form,
    SkeletonCards,
    SpinnerRouterLink,
  },
  apollo: {
    equipment: {
      query: EQUIPMENT_QUERY,
      errorPolicy: 'all',
      variables() {
        return {
          page: 1,
          orderBy: [{ column: 'id', order: 'ASC' }],
          ...SearchBuilder.build(this.search),
          ...(() => (this.inCellar ? { user_id: null } : {}))(),
          ...this.is_active,
          ...this.deleted_at,
        };
      },
      error(error) {
        this.$toasted.error(error.message);
      },
    },
  },
  data() {
    return {
      search: {},
      selectedRecord: null,
      isExporting: false,
      inCellar: false,
      active_only: true,
      is_active: null,
      deleted_at: null,
    };
  },
  computed: {
    exportButtonLabel() {
      return this.isExporting ? 'Exporting...' : 'Export';
    },
  },
  methods: {
    newRecord() {
      this.selectedRecord = {
        users: [], name: '', acquired_date: '', type: '', serial_number: '', internal_key: '', model: '',
      };
    },
    queryVariables() {
      return {
        orderBy: [{ column: 'id', order: 'ASC' }],
        ...SearchBuilder.build(this.search),
      };
    },
    onActiveClick() {
      this.active_only = !this.active_only;
      if (!this.active_only) {
        this.is_active = { is_active: 'WITH' };
        this.deleted_at = {
          deleted_at:
            dayjs()
              .subtract(15, 'days')
              .format('YYYY-MM-DD hh:mm:ss'),
        };
      } else {
        this.is_active = { is_active: 'WITHOUT' };
        this.deleted_at = undefined;
      }
    },
    recoverEquipment(record) {
      this.$apollo
        .mutate({
          mutation: RECOVERY_EQUIPMENT,
          variables: {
            id: record.id,
          },
          update: () => {
            this.$apollo.queries.equipment.refetch();
          },
        })
        .catch((error) => {
          this.$toasted.error(`Unable to delete record: ${error.message}`);
        });
    },
    remove(record) {
      this.$apollo
        .mutate({
          mutation: DELETE_EQUIPMENT,
          variables: {
            id: record.id,
          },
          update: (store, { data: { deleteEquipment } }) => {
            const data = store.readQuery({
              query: EQUIPMENT_QUERY,
              variables: this.queryVariables(),
            });
            const updatedEquipment = data.equipment.data.filter(
              (c) => c.id !== ((deleteEquipment || {}).id),
            );
            store.writeQuery({
              query: EQUIPMENT_QUERY,
              variables: this.queryVariables(),
              data: {
                equipment: {
                  ...data.equipment,
                  data: updatedEquipment,
                },
              },
            });
          },
        })
        .catch((error) => {
          this.$toasted.error(`Unable to delete record: ${error.message}`);
        });
    },
    exportFile() {
      this.isExporting = true;
      this.$apollo
        .mutate({
          mutation: EXPORT_EQUIPMENT,
          variables: { ...this.search },
        })
        .then((res) => {
          let date = new Date();
          date = `${date.getDay()}-${date.getMonth()}-${date.getUTCFullYear()}`;
          Base64File.download(
            res.data.exportEquipmentToExcel,
            `equipment-${date}.xlsx`,
          );
        })
        .finally(() => {
          this.isExporting = false;
        });
    },
    insert(record) {
      const newEquipment = record;
      this.selectedRecord = null;
      this.$apollo
        .mutate({
          mutation: CREATE_EQUIPMENT,
          variables: {
            record: _.pick(record, [
              'acquired_date',
              'type',
              'location',
              'state',
              'serial_number',
              'internal_key',
              'model',
              'name',
              'user_id',
            ]),
          },
          update: (store, { data: { createEquipment } }) => {
            const data = store.readQuery({
              query: EQUIPMENT_QUERY,
              variables: this.queryVariables(),
            });
            const updatedEquipment = [createEquipment, ...data.equipment.data];
            store.writeQuery({
              query: EQUIPMENT_QUERY,
              variables: this.queryVariables(),
              data: {
                equipment: {
                  ...data.equipment,
                  data: updatedEquipment,
                },
              },
            });
          },
        })
        .catch((error) => {
          ErrorHandler.handle(error, this);
          this.selectedRecord = newEquipment;
        });
    },
  },
};
</script>
