<template>
  <div class="overflow-auto h-100">
    <table class="table">
      <thead>
        <tr>
          <th>#</th>
          <th class="sortable" @click="sortBy('name')">
            <i v-if="orderBy.column === 'name'" class="bg-transparent fas" :class="sortIcon"></i>
            Project Name
          </th>
          <th class="sortable" @click="sortBy('client_id')">
            <i v-if="orderBy.column === 'client_id'" class="bg-transparent fas" :class="sortIcon"></i>
            Client
          </th>
          <th class="sortable" @click="sortBy('type')">
            <i v-if="orderBy.column === 'type'" class="bg-transparent fas" :class="sortIcon"></i>
            Engagement
          </th>
          <th class="sortable" @click="sortBy('start_date')">
            <i v-if="orderBy.column === 'start_date'" class="bg-transparent fas" :class="sortIcon"></i>
            Start Date
          </th>
          <th class="sortable" @click="sortBy('end_date')">
            <i v-if="orderBy.column === 'end_date'" class="bg-transparent fas" :class="sortIcon"></i>
            End Date
          </th>
          <th class="sortable" @click="sortBy('budget')">
            <i v-if="orderBy.column === 'budget'" class="bg-transparent fas" :class="sortIcon"></i>
            Budget
          </th>
          <th class="sortable" @click="sortBy('actual_cost')">
            <i v-if="orderBy.column === 'actual_cost'" class="bg-transparent fas" :class="sortIcon"></i>
            Current Cost
          </th>
          <th class="sortable" @click="sortBy('actual_hours')">
            <i v-if="orderBy.column === 'actual_hours'" class="bg-transparent fas" :class="sortIcon"></i>
            Hours Spent
          </th>
          <th class="sortable" @click="sortBy('gross_margin_percentage')">
            <i v-if="orderBy.column === 'gross_margin_percentage'" class="bg-transparent fas" :class="sortIcon"></i>
            Gross Margin
          </th>
        </tr>
      </thead>
      <tbody>
        <tr :key="row.id" v-for="(row, index) in projectMetrics.data">
          <td>{{index + 1}}</td>
          <td>{{row.project}}</td>
          <td>{{row.client}}</td>
          <td>{{row.engagement}}</td>
          <td>{{$displayDate(row.start_date)}}</td>
          <td>{{$displayDate(row.end_date)}}</td>
          <td>${{$filters.formatNumber(row.budget)}}</td>
          <td>${{ $filters.formatNumber(row.actual_cost)}}</td>
          <td>{{ $filters.formatNumber(row.actual_hours)}}</td>
          <td :class="grossMarginPercentageColor(row)">{{row.gross_margin_percentage}}%</td>
        </tr>
        <tr v-if="projectMetrics && projectMetrics.data.length === 0">
          <td class="text-center" colspan="10">No records found</td>
        </tr>
        <tr v-else>
          <th colspan="6" class="text-end">
            <strong class="bg-transparent">Total:</strong>
          </th>
          <th>
            <strong class="bg-transparent">
              ${{$filters.formatNumber(totalBy('budget'))}}
            </strong>
          </th>
          <th>
            <strong class="bg-transparent">
              ${{ $filters.formatNumber(totalBy('actual_cost'))}}
            </strong>
          </th>
          <th>
            <strong class="bg-transparent">
              {{$filters.formatNumber(totalBy('actual_hours'))}}
            </strong>
          </th>
          <th>
            <strong class="bg-transparent">
              {{gross_margin_average}}%
            </strong>
          </th>
        </tr>
      </tbody>
      <SkeletonRow v-if="projectMetrics && projectMetrics.paginatorInfo.hasMorePages" :colspan=10 :executeScroll="projectMetrics != undefined" @showMore="showMore"></SkeletonRow>
    </table>
  </div>
</template>

<script>

import { SkeletonRow } from '../../shared';

export default {
  props: ['projectMetrics'],
  components: {
    SkeletonRow,
  },
  data() {
    return {
      orderBy: { column: 'projects.id', order: 'ASC' },
    };
  },
  computed: {
    sortIcon() {
      return this.asc() ? 'fa-sort-alpha-down' : 'fa-sort-alpha-down-alt';
    },
    gross_margin_average() {
      return (((this.totalBy('budget') - this.totalBy('actual_cost')) / this.totalBy('budget')) * 100).toFixed(2);
    },
  },
  methods: {
    grossMarginPercentageColor(row) {
      if (row.gross_margin_percentage >= 40) {
        return 'text-primary';
      }
      if (row.gross_margin_percentage >= 30 && row.gross_margin_percentage <= 39) {
        return 'text-success';
      }
      return 'text-danger';
    },
    showMore() {
      this.$emit('showMore');
    },
    asc() {
      return this.orderBy.order === 'ASC';
    },
    sortBy(column) {
      this.$emit('sortBy', column);
      this.orderBy.column = column;
      this.orderBy.order = this.asc() ? 'DESC' : 'ASC';
    },
    totalBy(key) {
      return _.sumBy(
        this.projectMetrics.data,
        key,
      );
    },
  },
};
</script>
