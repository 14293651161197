import { gql } from '@apollo/client/core';

export default gql`
    query(
      $page: Int,
      $user_id: ID,
    ) {
      show_learning_path_level_nodes_for_evaluations(
        page: $page,
        user_id: $user_id,
      ) {
        paginatorInfo{
          hasMorePages
        },
        data {
          id
          total_nodes
          name
          avatar
        }
      }
    }
`;
