<template>
  <div v-if="estimation">
    <DeliverableForm v-if="selectedRecord" v-model:deliverable.sync="selectedRecord"
                                                :estimation_id="$route.params.id"
                                                @close="selectedRecord = null"
                                                @insert="insertDeliverable">
    </DeliverableForm>
    <nav>
      <div class="breadcrumb p-2">
        <div class="col-md-3 m-auto p-0">
          <ol class="breadcrumb p-0 m-auto">
            <li class="breadcrumb-item">
              <router-link to="/home">
                <i v-if="!$apollo.loading" class="fas fa-home"></i>
                <i v-if="$apollo.loading" class="fas fa-sync ms-1 fa-sm" :class="{'fa-spin': $apollo.loading}"></i>
              </router-link>
            </li>
            <li class="breadcrumb-item active fw-bold" aria-current="page">
              <router-link to="/estimations">Estimations</router-link>
            </li>
            <li class="breadcrumb-item fw-bold" aria-current="page">
              {{estimation.name}}
            </li>
          </ol>
        </div>
        <div class="col-md-9 text-end">
          <button type="button" class="btn btn-company-primary" @click="exportFile()">
            <i v-if="is_exporting" class="fas fa-sync fa-spin"></i>
            <i v-if="!is_exporting" class="fa fa-file-excel"></i>
            Export
          </button>
          <button type="button" class="btn btn-company-primary" @click="selectedRecord = {}">
            <i class="fas fa-plus"></i>
            New Deliverable
          </button>
        </div>
      </div>
    </nav>
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-12">
          <ul class="nav nav-tabs" role="tablist">
            <li class="nav-item">
              <a class="nav-link active" id="estimation-tab" data-bs-toggle="tab" href="#estimation" role="tab" aria-controls="estimation" aria-selected="true">Estimation</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" id="deliverable-tab" data-bs-toggle="tab" href="#deliverable" role="tab" aria-controls="deliverable" aria-selected="false">Deliverables</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" id="notes-tab" data-bs-toggle="tab" href="#notes" role="tab" aria-controls="notes" aria-selected="false">Notes</a>
            </li>
          </ul>
          <div class="tab-content">
            <div class="d-grid gap-2 d-md-flex justify-content-md-end py-2 px-2 border border-top-0 border-bottom-0">
              <button :disabled="loading" class="btn btn-company-primary" @click="save()">
                <i v-if="loading" class="fas fa-sync fa-spin"></i>
                <i v-if="!loading" class="fas fa-save"></i>
                Save All
              </button>
            </div>
            <div class="tab-pane fade show border border-top-0 p-1 active" id="estimation" role="tabpanel" aria-labelledby="estimation-tab">
              <div class="row mt-3">
                <BucketEstimation
                  :key="bucket_estimation.id"
                  v-for="bucket_estimation in bucket_estimations"
                  :bucket_estimation="bucket_estimation"
                  :deliverables="estimation.deliverables"
                  :id="$route.params.id"
                  :order="bucket_estimation.order"
                  :max="estimation.bucket_estimations.length"
                  :loading="loading"
                  @refresh="refresh"
                  @changeOrder="changeOrder"
                ></BucketEstimation>
              </div>
            </div>
            <div class="tab-pane fade show border border-top-0 p-1" id="deliverable" role="tabpanel" aria-labelledby="deliverable-tab">
              <div class="row mt-3">
                <Deliverable v-for="deliverable in estimation.deliverables" :key="deliverable.id"
                                                                             :deliverable="deliverable"
                                                                             @refresh="refresh">
                </Deliverable>
              </div>
            </div>
            <div class="tab-pane fade show border border-top-0 p-1" id="notes" role="tabpanel" aria-labelledby="notes-tab">
              <trix-editor @trix-change="estimation.notes = $event.target.value; $forceUpdate()"></trix-editor>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { ESTIMATION_QUERY } from './queries';
import { CREATE_DELIVERABLE } from './tabs/deliverables/mutations';
import { UPDATE_BUCKET_ESTIMATION, UPDATE_ESTIMATION, EXPORT_ESTIMATION } from './mutations';
import BucketEstimation from './tabs/BucketEstimation.vue';
import Deliverable from './tabs/deliverables/Deliverable.vue';
import DeliverableForm from './tabs/deliverables/DeliverableForm.vue';
import RequestBuilder from './RequestBuilder';
import { ErrorHandler, Base64File } from '../../shared';

export default {
  components: { BucketEstimation, Deliverable, DeliverableForm },
  data() {
    return {
      loading: false,
      is_exporting: false,
      selectedRecord: null,
    };
  },
  apollo: {
    estimation: {
      query: ESTIMATION_QUERY,
      errorPolicy: 'all',
      variables() {
        return { id: this.$route.params.id };
      },
      error(error) {
        this.$toasted.error(error.message);
      },
    },
  },
  computed: {
    bucket_estimations() {
      // Create a copy of each element in the array before modifying it
      return _.sortBy(this.estimation.bucket_estimations.map((element, index) => ({
        ...element,
        order: element.order || index + 1,
      })), 'order');
    },
  },
  methods: {
    exportFile() {
      this.is_exporting = true;
      this.$apollo.mutate({
        mutation: EXPORT_ESTIMATION,
        variables: {
          id: this.$route.params.id,
        },
      }).then((res) => {
        Base64File.download(res.data.exportEstimationToExcel, `estimation-${this.estimation.name}.xlsx`);
      }).finally(() => {
        this.is_exporting = false;
      });
    },
    save() {
      this.loading = true;
      const promises = this.estimation.bucket_estimations.map((bucketEstimation) => this.$apollo.mutate({
        mutation: UPDATE_BUCKET_ESTIMATION,
        fetchPolicy: 'no-cache',
        variables: {
          id: bucketEstimation.id,
          bucket_estimation: RequestBuilder.buildBucketEstimation(
            this.$route.params.id,
            bucketEstimation,
          ),
        },
      }));
      promises.push(
        this.$apollo.mutate({
          mutation: UPDATE_ESTIMATION,
          variables: {
            id: this.estimation.id,
            estimation: RequestBuilder.build(this.estimation),
          },
        }),
      );
      Promise.all(promises).catch((error) => {
        ErrorHandler.handle(error, this);
        this.loading = false;
      }).then(() => {
        this.refresh();
        this.loading = false;
      });
    },
    changeOrder(number, order) {
      const array = this.bucket_estimations.splice(order - 1, 1);
      this.bucket_estimations.splice(number - 1, 0, array[0]);
      this.bucket_estimations.forEach((item, index) => {
        item.order = index + 1;
      });
      this.estimation.bucket_estimations = this.bucket_estimations;
    },
    refresh() {
      this.$apollo.queries.estimation.refetch();
    },
    insertDeliverable(deliverable) {
      deliverable.estimation_id = parseInt(this.$route.params.id, 10);
      deliverable.is_completed = false;
      const newDeliverable = deliverable;
      this.selectedRecord = null;
      this.$apollo.mutate({
        mutation: CREATE_DELIVERABLE,
        variables: {
          deliverable,
        },
      }).then(() => {
        this.refresh();
      }).catch((error) => {
        ErrorHandler.handle(error, this);
        this.selectedRecord = newDeliverable;
      });
    },
  },
};
</script>
