import { useVuelidate } from '@vuelidate/core';
import { required, maxLength, email } from '@vuelidate/validators';

export default {
  data() {
    return {
      v$: useVuelidate(), // Inicialización de vuelidate
    };
  },
  validations: {
    alarm: {
      notification_recipients: { required, maxLengthValue: maxLength(255), email },
      threshold_type: { required },
      threshold_value: { required },
    },
  },
};
