import { gql } from '@apollo/client/core';

import LEARNING_PATH_FRAGMENT from './fragments';

export const CREATE_LEARNING_PATH = gql`
  mutation($learning_path: LearningPathInput!){
    createLearningPath(input: $learning_path){
      ...learning_path
    }
  }
  ${LEARNING_PATH_FRAGMENT}
`;

export const UPDATE_LEARNING_PATH = gql`
  mutation($id: ID!, $learning_path: LearningPathInput!){
    updateLearningPathStatus(id: $id, input: $learning_path){
      ...learning_path
    }
  }
  ${LEARNING_PATH_FRAGMENT}
`;

export const DELETE_LEARNING_PATH = gql`
  mutation($id: ID!){
    deleteLearningPath(id: $id){
      id
    }
  }
`;
