<template>
  <div>
    <nav>
      <div class="breadcrumb p-2">
        <div class="row">
          <div class="col-auto ms-3 p-0">
            <SpinnerRouterLink :loading="$apollo.loading">Embed Reports</SpinnerRouterLink>
          </div>
        </div>
        <div class="row m-auto p-0 d-flex justify-content-start" v-if="profile">
          <ReportLine
            :key="embed_report.id" v-for="embed_report in profile.embed_reports"
            :report="embed_report"
            @selected="selected" @remove="remove"
          ></ReportLine>
        </div>
        <div class="container-fluid">
          <div class="row">
            <div class="col order-last text-end">
              <div v-if="profile">
                <button class="btn btn-company-primary float-end" v-if="!newReport"  @click="newReport = {}">
                  <i class="fas fa-plus"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
    <div class="form-floating">
                  <Form
                    v-if="newReport"
                    v-model:report.sync="newReport"
                    @close="newReport = null"
                    @insert="insert"
                  ></Form>
                </div>
    <div class="row vh-100">
      <div class="col-md-12 h-100">
        <iframe
          v-if="currentReport"
          width="100%"
          height="100%"
          frameborder="0"
          style="border:0"
          :src="selectedUrl"
          allowfullscreen
        ></iframe>
        <Empty v-if="!currentReport" icon="fas fa-chart-line"/>
      </div>
    </div>
  </div>
</template>

<script>

import EMBED_REPORTS_QUERY from './queries';
import { CREATE_EMBED_REPORT, DELETE_EMBED_REPORT } from './mutations';
import ReportLine from './ReportLine.vue';
import Form from './Form.vue';
import ErrorHandler from '../../shared/ErrorHandler';
import { SpinnerRouterLink } from '../shared';

export default {
  components: { ReportLine, Form, SpinnerRouterLink },
  apollo: {
    profile: {
      query: EMBED_REPORTS_QUERY,
      errorPolicy: 'all',
      error(error) {
        this.$toasted.error(error.message);
      },
    },
  },
  data() {
    return {
      currentReport: null,
      newReport: null,
    };
  },
  created() {
    if (this.currentReport == null && this.profile) {
      this.currentReport = _.head(this.profile.embed_reports);
    }
  },
  methods: {
    selected(report) {
      this.currentReport = report;
    },
    remove(report) {
      this.$apollo.mutate({
        mutation: DELETE_EMBED_REPORT,
        variables: {
          id: parseInt(report.id, 10),
        },
        update: (store, { data: { deleteEmbedReport } }) => {
          const data = store.readQuery({ query: EMBED_REPORTS_QUERY });
          const updatedEmbedReports = data.profile.embed_reports.filter((r) => r.id !== deleteEmbedReport.id);
          store.modify({ fields: { profile(existingProfile = { data: [] }) { return { ...existingProfile, data: updatedEmbedReports }; } } });
          this.$apollo.queries.profile.refetch();
        },
      }).catch((error) => {
        this.$toasted.error(`Unable to delete record: ${error.message}`);
      });
    },
    insert(report) {
      this.$apollo.mutate({
        mutation: CREATE_EMBED_REPORT,
        variables: {
          embed_report: report,
        },
        update: (store, { data: { createEmbedReport } }) => {
          const data = store.readQuery({ query: EMBED_REPORTS_QUERY });
          const updatedEmbedReports = [createEmbedReport, ...data.profile.embed_reports];
          store.modify({ fields: { profile(existingProfile = { data: [] }) { return { ...existingProfile, data: updatedEmbedReports }; } } });
          this.newReport = null;
          this.$apollo.queries.profile.refetch();
        },
      }).catch((error) => {
        ErrorHandler.handle(error, this);
      });
    },
  },
  computed: {
    selectedUrl() {
      if (this.profile === undefined) {
        return '';
      }
      return this.currentReport.url || '';
    },
  },
};
</script>
