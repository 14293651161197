import { gql } from '@apollo/client/core';

export default gql`
  fragment healthScoreConfigurationItem on HealthScoreConfigurationItem {
    id
    name
    description
    percentage_weight
    success_operator
    success_value_a
    success_value_b
  }
`;
