import { useVuelidate } from '@vuelidate/core';
import { required, minValue } from '@vuelidate/validators';

export default {
  data() {
    return {
      v$: useVuelidate(), // Inicialización de vuelidate
    };
  },
  validations: {
    currentProjectMember: {
      start_date: { required },
      contract_end_date: { required },
      hours_per_week: { required, minValueValue: minValue(0) },
      project_role: { required },
      user: { required },
    },
  },
};
