import { gql } from '@apollo/client/core';

export default gql`
  query($id: ID!){
    performance_evaluation_form(id: $id){
      id
      name
      questions{
        id
        name
        order
      }
    }
  }
`;
