import { gql } from 'apollo-boost';

export default gql`
  fragment healthScoreProjectValue on HealthScoreMetrics {
    id
    name
    description
    percentage_weight
    success_operator
    success_value_a
    success_value_b
    value
  }
`;
