import { gql } from '@apollo/client/core';

export default gql`
  query(
    $page: Int,
    $user_id: ID,
    $orderBy: [OrderByClause!]) {
  promotions(
    page: $page,
    user_id: $user_id,
    orderBy: $orderBy
  ) {
    paginatorInfo {
      hasMorePages
    }
    data{
      id
      date
      next_promotion_date
      next_promotion_date_diff
      user {
        id
        name
      }
      from_job_title {
        id
        internal_description
      }
      to_job_title {
        id
        internal_description
      }
      next_job_title {
        id
        internal_description
      }
    }
    }
  }
`;
