import { gql } from '@apollo/client/core';

export default gql`
  fragment signature on Signature {
    id
    width
    image
    height
    details {
      content
      color
      font_size
      margin
      padding
      position
      width
      height
      left
      top
      font_weight
      font_family
      font_style
    }
  }
`;
