import { gql } from '@apollo/client/core';

export default gql`
  fragment interview on Interview {
    id
    user_id
    date
    is_successful
    notes
    user {
      id
      name
    }
  }
`;
