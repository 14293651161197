<template>
  <div>
    <nav>
      <div class="breadcrumb p-2">
        <div class="row">
          <div class="col-auto ms-3 p-0">
            <SpinnerRouterLink :loading="$apollo.loading">People by Client</SpinnerRouterLink>
          </div>
        </div>
        <div class="w-100 row">
          <div class="col-6">
            <div class="company-control pb-3">
              <label for="start_date">Start Date:</label>
              <DatePicker :date="start_date" placeholder="Select Date" @change="start_date = $event"></DatePicker>
            </div>
          </div>
          <div class="col-6">
            <div class="company-control">
              <label for="end_date">End Date:</label>
              <DatePicker :date="end_date" placeholder="Select Date" @change="end_date = $event"></DatePicker>
            </div>
          </div>
        </div>
        <div class="w-100 row">
          <div class="col-12 mt-2">
            <ClientPicker :records="clients" :hidden="show_active"></ClientPicker>
          </div>
        </div>
        <div class="w-100 row">
          <div class="col-md-12 mt-2">
            <div class="form-check form-switch">
              <input type="checkbox" class="form-check-input" :id="`layout`" v-model="show_active">
              <label class="form-check-label m-auto" :for="`layout`">Active</label>
            </div>
          </div>
        </div>
      </div>
    </nav>
    <div>
      <div class="col-md-12 text-center" :hidden="!clients[0] && !show_active">
        <div id="chart"></div>
      </div>
      <div class="col-md-12 text-center" v-if="!clients[0] && !show_active">
        <div class="p-5 mb-4 bg-light rounded-3">
          <h1>Please select one or more Clients to proceed.</h1>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import Highcharts from 'highcharts';
import ClientPicker from '../../clients/Picker.vue';
import PEOPLE_BY_CLIENT from './queries';
import { SpinnerRouterLink } from '../../shared';

export default {
  components: { ClientPicker, SpinnerRouterLink },
  data() {
    const clients = {
      object: this.$route.query.clients,
      string: [this.$route.query.clients],
      undefined: [],
    }[typeof this.$route.query.clients];

    return {
      clients,
      end_date: this.$route.query.end_date || dayjs().format('YYYY-MM-DD'),
      start_date: this.$route.query.start_date || dayjs(this.end_date).add(-6, 'months').format('YYYY-MM-DD'),
      show_active: this.$route.query.show_active === 'true',
    };
  },
  apollo: {
    people_by_client: {
      query: PEOPLE_BY_CLIENT,
      errorPolicy: 'all',
      variables() {
        return {
          clients: this.show_active ? [] : this.clients,
          start_date: this.start_date,
          end_date: this.end_date,
          show_active: this.show_active,
        };
      },
      update(data) {
        return data.people_by_client.map((row) => ({
          ...row,
          data: JSON.parse(row.data),
        }));
      },
      error(error) {
        this.$toasted.error(error.message);
      },
    },
  },
  computed: {
    categories() {
      let categories = [];
      this.people_by_client.forEach((e) => {
        categories = _.uniq([...categories, ...Object.keys(e.data)]);
      });
      return categories;
    },
    series() {
      return this.people_by_client.map((row) => (
        { name: row.client, data: this.categories.map((c) => (row.data[c] || 0)) }
      ));
    },
    queryState() {
      return {
        clients: this.clients.length,
        start_date: this.start_date,
        end_date: this.end_date,
        show_active: this.show_active,
      };
    },
  },
  watch: {
    queryState: {
      handler() {
        this.$router.push({ query: { start_date: null, end_date: null } }).catch(() => { });
        const toggle = this.show_active ? { show_active: this.show_active } : { clients: [...this.clients] };
        this.$router.push({ query: { start_date: this.start_date, end_date: this.end_date, ...toggle } }).catch(() => { });
      },
      deep: true,
    },
    people_by_client() {
      this.drawChart();
    },
  },
  methods: {
    drawChart() {
      Highcharts.chart('chart', {
        chart: {
          type: 'line',
          minHeight: '40%',
        },
        title: {
          text: 'People By Client',
        },
        subtitle: {
          text: `from ${this.start_date} to ${this.end_date}`,
        },
        yAxis: {
          title: {
            text: 'Number of People',
          },
        },
        xAxis: {
          categories: this.categories,
          lineWidth: 0,
        },
        series: this.series,
      });
    },
  },
};
</script>
