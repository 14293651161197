import { gql } from '@apollo/client/core';

import PERFORMANCE_EVALUATION_FORM_FRAGMENT from './fragments';

export const CREATE_PERFORMANCE_EVALUATION_FORM = gql`
  mutation($performance_evaluation_form: PerformanceEvaluationFormInput!){
    createPerformanceEvaluationForm(input: $performance_evaluation_form){
      ...performance_evaluation_form
    }
  }
  ${PERFORMANCE_EVALUATION_FORM_FRAGMENT}
`;

export const UPDATE_PERFORMANCE_EVALUATION_FORM = gql`
  mutation($id: ID!, $performance_evaluation_form: PerformanceEvaluationFormInput!){
    updatePerformanceEvaluationForm(id: $id, input: $performance_evaluation_form){
      ...performance_evaluation_form
    }
  }
  ${PERFORMANCE_EVALUATION_FORM_FRAGMENT}
`;

export const DELETE_PERFORMANCE_EVALUATION_FORM = gql`
  mutation($id: ID!){
    deletePerformanceEvaluationForm(id: $id){
      id
    }
  }
`;
