import { gql } from '@apollo/client/core';

import BUSINESS_UNIT_FRAGMENT from './fragments';

export const CREATE_BUSINESS_UNIT = gql`
  mutation($business_unit: BusinessUnitInput!){
    createBusinessUnit(input: $business_unit){
      ...business_unit
    }
  }
  ${BUSINESS_UNIT_FRAGMENT}
`;

export const UPDATE_BUSINESS_UNIT = gql`
  mutation($id: ID!, $business_unit: BusinessUnitInput!){
    updateBusinessUnit(id: $id, input: $business_unit){
      ...business_unit
    }
  }
  ${BUSINESS_UNIT_FRAGMENT}
`;

export const DELETE_BUSINESS_UNIT = gql`
  mutation($id: ID!){
    deleteBusinessUnit(id: $id){
      id
    }
  }
`;
