import { gql } from '@apollo/client/core';

export default gql`
  query ($start_date: DateTime!, $end_date: DateTime!) {
    myTime(start_date: $start_date, end_date: $end_date) {
      id
      name
      buckets {
        id
        name
      }
      logs {
        id
        hours
        activities
        bucket_estimation_id
        created_at
      }
      has_weekends
      ids_to_delete
    }
  }
`;
