import { gql } from '@apollo/client/core';

import INTERVIEW_FRAGMENT from './fragments';

export const CREATE_INTERVIEW = gql`
  mutation($interview: InterviewInput!){
    createInterview(input: $interview){
      ...interview
    }
  }
  ${INTERVIEW_FRAGMENT}
`;

export const UPDATE_INTERVIEW = gql`
  mutation($id: ID!, $interview: InterviewInput!){
    updateInterview(id: $id, input: $interview){
      ...interview
    }
  }
  ${INTERVIEW_FRAGMENT}
`;

export const DELETE_INTERVIEW = gql`
  mutation($id: ID!){
    deleteInterview(id: $id){
      id
    }
  }
`;
