<template>
  <div class="container-fluid">

    <div class="p-3 mb-2 border bg-light d-flex justify-content-between align-items-center">
      <span class="h4">{{localForm.name}}</span>
      <span>
        1 - Poor
        2 - Unsatisfactory
        3 - Satisfactory
        4 - Very Satisfactory
        5 - Outstanding
      </span>
    </div>
    <template v-for="question in localForm.questions" :key="`q-${question.id}`">
      <div class="card">
        <div class="card-body h4">
          {{question.name}}
        </div>
        <div class="card-body">
          <div class="btn-group" role="group" aria-label="Basic radio toggle button group">
            <template v-for="row in scale" :key="`${question.id}-${row.score}`">
                <input type="radio" class="btn-check"
                        :id="`${question.id}-${row.score}-score`"
                        :name="`${question.id}-score`"
                        :value="row.score"
                        v-model="question.answers[0].score"
                        :disabled="readOnly"
                        @change="updateAnswer">
                <label class="btn btn-outline-dark"
                        :for="`${question.id}-${row.score}-score`">{{row.score}} - {{row.description}}</label>
            </template>
            <input type="radio" class="btn-check"
                        :id="`${question.id}-null-score`"
                        :name="`${question.id}-score`"
                        value="n/a"
                        v-model="question.answers[0].score"
                        :disabled="readOnly"
                        @change="updateAnswer">
            <label class="btn btn-outline-dark" :for="`${question.id}-null-score`">N/A</label>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import _ from 'lodash';

export default {
  props: ['form', 'readOnly'],
  data() {
    return {
      scale: [
        { score: 1, description: 'Poor' },
        { score: 2, description: 'Unsatisfactory' },
        { score: 3, description: 'Satisfactory' },
        { score: 4, description: 'Very Satisfactory' },
        { score: 5, description: 'Outstanding' },
      ],
    };
  },
  computed: {
    localForm() {
      const form = _.cloneDeep(this.form);
      form.questions.forEach((q) => {
        if (q.answers.length === 0) {
          q.answers.push({ id: null, score: null });
        }
      });
      return form;
    },
  },
  methods: {
    updateAnswer() {
      this.$emit('updated', this.localForm);
    },
  },
};
</script>
