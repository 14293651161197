import { gql } from '@apollo/client/core';
import PERFORMANCE_EVALUATION_FORM_FRAGMENT from './fragments';

export default gql`
  query($page: Int,
        $name: String,
        $orderBy: [OrderByClause!]) {
    performance_evaluation_forms(
      page: $page,
      name: $name,
      orderBy: $orderBy) {
      paginatorInfo {
        hasMorePages
      }
      data {
        ...performance_evaluation_form
      }
    }
  }
  ${PERFORMANCE_EVALUATION_FORM_FRAGMENT}
`;
