<template>
  <div>
    <nav>
      <div class="breadcrumb p-2">
        <div class="col-md-6 m-auto p-0">
          <ol class="breadcrumb p-0 m-auto">
            <li class="breadcrumb-item">
              <router-link to="/home">
                <i class="fas fa-home"></i>
              </router-link>
            </li>
            <li class="breadcrumb-item active fw-bold" aria-current="page">
              <router-link to="/projects">Projects</router-link>
            </li>
            <li class="breadcrumb-item active fw-bold" aria-current="page">
              <router-link :to="`/projects?name=${project?.name}`">{{ project?.name }}</router-link>
            </li>
            <li class="breadcrumb-item fw-bold" aria-current="page">
              Health Score Metrics
            </li>
          </ol>
        </div>
        <div class="col-md-6 m-auto p-0">
          <Search
            :search.sync="search"
            :inputs="['name']"
          ></Search>
        </div>
      </div>
    </nav>
    <div class="row" v-if="healthScoreProjectValues">
      <Empty v-if="!healthScoreProjectValues.length" icon="fas fa-chart-pie" :can_create="true" @callCreate="selectedRecord = {}"/>
      <Card :key="record.id" v-for="record in healthScoreProjectValues"
        :record="record"
        :project_id="projectId"
        @on-new-item="onNewItem"
      ></Card>
    </div>
  </div>
</template>

<script>
import { gql } from 'apollo-boost';
import HEALTH_SCORE_PROJECT_VALUES_QUERY from './queries';
import Card from './Card.vue';
import { Search } from '../../shared';
import { SearchBuilder } from '../../../shared';

export default {
  components: { Card, Search },
  apollo: {
    project: {
      query: gql`query($id: ID!) { project(id: $id) { id name }}`,
      errorPolicy: 'all',
      variables() {
        return { id: this.projectId };
      },
      error(error) {
        this.$toasted.error(error.message);
      },
    },
    healthScoreProjectValues: {
      query: HEALTH_SCORE_PROJECT_VALUES_QUERY,
      errorPolicy: 'all',
      variables() {
        return { project_id: this.projectId, ...SearchBuilder.build(this.search) };
      },
      error(error) {
        this.$toasted.error(error.message);
      },
    },
  },
  data() {
    return {
      projectId: this.$route.params.id,
      search: {},
      selectedRecord: null,
    };
  },
  methods: {
    onNewItem(healthScoreProjectValue) {
      this.healthScoreProjectValues.find((p) => p.id === healthScoreProjectValue.health_score_configuration_item_id).value = healthScoreProjectValue.value;
    },
  },
};
</script>
