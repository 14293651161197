<template>
  <div :id="record.id" class="col-md-3 pb-2">
    <div class="card text-center">
      <div class="card-body">
        <div v-if="record.avatar">
          <div class="d-flex align-items-center justify-content-center">
            <img class="rounded-circle" :src="record.avatar" />
          </div>
        </div>
        <h5 class="card-title mt-3">
          <strong>{{ record.name }}</strong>
        </h5>
        <p class="card-text">
          <strong>Total</strong> nodes <strong>ready</strong> for evaluation:
          <strong>{{ record.total_nodes }}</strong>
        </p>
        <router-link :to="`/learning_paths/evaluations/${record.id}`" class="btn btn-company-primary">
          Review
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['record'],
};
</script>
