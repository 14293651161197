<template>
  <div>
    <label for="item">
      <slot></slot>
    </label>
    <div class="form">
      <div class="row">
        <div class="mb-3 col-md-3">
          <label for="name">Name</label>
          <input type="text" class="form-control" id="name" :class="{'is-invalid': v$.localRecord.name.$invalid}" v-model="localRecord.name">
          <div v-for="error of v$.localRecord.name.$silentErrors" :key="error.$uid">
            <div class="text-danger">{{ error.$message }}</div>
          </div>
        </div>
        <div class="mb-3 col-md-2">
          <label for="position">Position</label>
          <input type="text" class="form-control" id="position" placeholder="CEO, COO, CTO, PM..." v-model="localRecord.position">
        </div>
        <div class="mb-3 col-md-3">
          <label for="email">Email</label>
          <input type="email" class="form-control" id="email" :class="{'is-invalid': v$.localRecord.email.$invalid}" v-model="localRecord.email">
        </div>
        <div class="mb-3 col-md-2">
          <label for="phone">Phone</label>
          <input type="tel" class="form-control" id="phone" v-model="localRecord.phone">
        </div>
        <div class="mb-3 col-md-2 d-flex align-items-end">
          <button type="button" :disabled="v$.localRecord.$invalid" class="btn btn-success form-control" @click="add()">
            <i class="fas fa-plus"></i>
            Add
          </button>
        </div>
      </div>
    </div>
    <div class="row pt-2">
      <div class="col-md-12">
        <hr class="m-0">
        <table class="table m-0" v-if="contacts">
          <tbody>
            <template v-for="(contact, index) in contacts">
              <tr v-if="oldContact!=contact && !contact.deleted" :key="index">
                <td colspan="4" class="row px-0">
                    <div class="m-0 col-md-3">
                      <span>{{contact.name}}</span>
                    </div>
                    <div class="m-0 col-md-2">
                      <span>{{contact.position}}</span>
                    </div>
                    <div class="m-0 col-md-5">
                      <span>{{contact.email}} <b>/</b> {{contact.phone}}</span>
                    </div>
                    <div class="m-0 col-md-2 d-flex align-items-end justify-content-center px-3">
                      <div class="d-flex">
                        <button class="btn btn-warning flex-fill ms-1" title="Edit contact" @click="edit(contact)"    :disabled="editingContact.id!=null">
                          <i class="fas fa-edit"></i>
                        </button>
                        <button class="btn btn-company-secondary  flex-fill ms-1" title="Remove contact" @click="remove(contact)" :disabled="editingContact.id!=null">
                          <i class="fas fa-trash"></i>
                        </button>
                      </div>
                    </div>
                </td>
              </tr>
              <tr v-else-if="oldContact==contact" :key="'edit-' + index">
                <td colspan="4" class="bg-light pe-2 px-0">
                  <div class="row">
                    <div class="m-0 col-md-3">
                      <input type="text" class="form-control" id="name" v-model="editingContact.name">
                    </div>
                    <div class="m-0 col-md-2">
                      <input type="text" class="form-control" id="position" placeholder="CEO, COO, CTO, PM..." v-model="editingContact.position">
                    </div>
                    <div class="m-0 col-md-3">
                      <input type="email" class="form-control" id="email" v-model="editingContact.email">
                    </div>
                    <div class="m-0 col-md-2">
                      <input type="tel" class="form-control" id="phone" v-model="editingContact.phone">
                    </div>
                    <div class="m-0 col-md-2 d-flex align-items-end justify-content-around">
                      <button class="btn btn-company-secondary col" title="Save changes" @click="cancelEdit()" required>
                        <i class="fas fa-times"></i>
                        Cancel
                      </button>
                      <button class="btn btn-company-primary col" title="Abort Changes" @click="saveEdit()" required>
                        <i class="fas fa-check"></i>
                        Accept
                      </button>
                    </div>
                  </div>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import Validations from './Validations';

export default {
  extends: Validations,
  props: ['contacts'],
  data() {
    return {
      localRecord: {
        name: '',
        position: '',
        email: '',
        phone: '',
      },
      editingContact: { id: null },
      oldContact: null,
    };
  },
  mounted() {
    this.contacts.forEach((c) => { c.deleted = false; });
  },
  methods: {
    remove(contact) {
      contact.deleted = true;
      this.$forceUpdate();
    },
    edit(contact) {
      this.editingContact = { ...contact };
      this.oldContact = contact;
    },
    saveEdit() {
      const index = _.findIndex(this.contacts, (o) => o === this.oldContact);
      this.contacts[index] = this.editingContact;
      this.editingContact = { id: null };
    },
    cancelEdit() {
      this.editingContact = { id: null };
      this.oldContact = null;
    },
    add() {
      this.contacts.push({
        name: this.localRecord.name,
        email: this.localRecord.email,
        position: this.localRecord.position,
        phone: this.localRecord.phone,
        notes: this.localRecord.notes,
      });
      this.localRecord = {
        name: '',
        position: '',
        email: '',
        phone: '',
      };
    },
  },
};
</script>
