import { gql } from '@apollo/client/core';

export default gql`
  mutation($project_id: ID, $delete: [ID]!, $items: [MyTimeInput]!) {
    response: logMyTime(project_id: $project_id, delete: $delete, items: $items) {
      id
      project_id
      hours
      created_at
      bucket_estimation_id
      activities
    }
  }
`;
