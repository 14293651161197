<template>
  <tr :key="`${row.client_id}-${row.project_id}-${row.member_id}`" v-for="(row, index) in rowsByClient">
    <td :rowspan="rowsByClient.length" v-if="index === 0">{{clientName}}</td>
    <td :rowspan="rowsByClient.length" v-if="index === 0">{{directIndirect}}</td>
    <td v-if="showProjectMargin(index, row.project_id)" :rowspan="projectRowSpan(row.project_id)">
      <router-link :to="`/projects/${row.project_id}/actions`" target="_blank" class="text-decoration-none">
        {{row.project_name}}
      </router-link>
    </td>
    <td class="text-center" v-if="showProjectMargin(index, row.project_id)" :rowspan="projectRowSpan(row.project_id)">
      <i class="fas fa-circle" :class="classByStatus(row.project_status)"></i>
    </td>
    <td v-if="showProjectMargin(index, row.project_id)" :rowspan="projectRowSpan(row.project_id)">
      <router-link :to="{ name: 'resume', params: { id: row.manager_id }}" target="_blank" class="text-decoration-none d-flex align-items-center gap-2">
        <img width="25rem" class="rounded-circle" :src="row.manager_avatar">
        {{row.manager}}
      </router-link>
    </td>
    <td>
      <DateColor :value="row.end_date" :configuration="configuration"></DateColor>
    </td>
    <td>
      <router-link :to="{ name: 'resume', params: { id: row.member_id }}" target="_blank" class="text-decoration-none d-flex align-items-center gap-2">
        <img width="25rem" class="rounded-circle" :src="row.member_avatar">
        {{row.member}}
      </router-link>
    </td>
    <td>{{row.job_title}}</td>
    <td>${{$filters.formatNumber(row.cost) }}</td>
    <td>${{ $filters.formatNumber(row.rate) }}</td>
    <td class="text-center">
      <MarginColor :value="individualGrossMarginPercentaje(row)" :configuration="configuration"></MarginColor>
    </td>
    <td class="text-center" v-if="showProjectMargin(index, row.project_id)" :rowspan="projectRowSpan(row.project_id)">
      <MarginColor :value="grossMarginBy('project_id', row.project_id)" :configuration="configuration"></MarginColor>
    </td>
    <td class="text-center" v-if="index === 0" :rowspan="rowsByClient.length">
      <MarginColor :value="grossMarginBy('client_id', row.client_id)" :configuration="configuration"></MarginColor>
    </td>
    <td class="text-center fw-bold" v-if="showProjectMargin(index, row.project_id)" :rowspan="countBy('project_id', row.project_id)">
      {{countBy('project_id', row.project_id)}}
    </td>
    <td class="text-center fw-bold" v-if="index === 0" :rowspan="rowsByClient.length">
      {{countBy('client_id', row.client_id)}}
    </td>
  </tr>
</template>
<script>
import MarginColor from './MarginColor.vue';
import DateColor from './DateColor.vue';

export default {
  components: { MarginColor, DateColor },
  props: ['rowsByClient', 'client_id', 'configuration'],
  data() {
    return {
      project_min_row_span: {},
    };
  },
  computed: {
    first() {
      return this.rowsByClient[0];
    },
    clientName() {
      return this.first.client_name;
    },
    directIndirect() {
      return this.first.is_direct ? 'Direct' : 'Indirect';
    },
  },
  methods: {
    showProjectMargin(index, projectId) {
      this.project_min_row_span[projectId] = _.min([(this.project_min_row_span[projectId] ?? this.rowsByClient.length), index]);
      return this.project_min_row_span[projectId] === index;
    },
    projectRowSpan(projectId) {
      return this.rowsByClient.filter((row) => row.project_id === projectId).length;
    },
    grossMargin(cost, rate) {
      if (rate === 0) {
        return 0;
      }
      const grossMargin = ((rate - cost) / rate) * 100;
      return grossMargin.toFixed(2);
    },
    individualGrossMarginPercentaje(row) {
      return this.grossMargin(row.cost, row.rate);
    },
    countBy(key, value) {
      return this.rowsByClient.filter((row) => row[key] === value).length;
    },
    grossMarginBy(key, value) {
      const cost = _.sumBy(this.rowsByClient.filter((row) => row[key] === value), 'cost');
      const rate = _.sumBy(this.rowsByClient.filter((row) => row[key] === value), 'rate');
      return this.grossMargin(cost, rate);
    },
    classByStatus(status) {
      const cssClasses = { red: 'text-danger', yellow: 'text-warning', green: 'text-success' };
      return cssClasses[status.toLowerCase()] || '';
    },
  },
};
</script>
