import { gql } from 'apollo-boost';

import HEALTH_SCORE_PROJECT_VALUE_FRAGMENT from './fragments';

export default gql`
  query($project_id: ID!, $name: String) {
    healthScoreProjectValues(project_id: $project_id, name: $name) @connection(key: "healthScoreProjectValues", filter: ["project_id", "name"]) {
        ...healthScoreProjectValue
    }
  }
  ${HEALTH_SCORE_PROJECT_VALUE_FRAGMENT}
`;
