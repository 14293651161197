<template>
    <div>
    <nav>
      <div class="breadcrumb p-2 pb-4">
        <div class="row">
          <div class="col-auto ms-3 p-0">
            <SpinnerRouterLink :loading="$apollo.loading">Project Member History</SpinnerRouterLink>
          </div>
        </div>
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12">
              <Search v-model="search"
                      :inputs="['projects', 'users', 'dateRanges']"
                      :default_values="defaultDates"></Search>
            </div>
          </div>
        </div>
      </div>
    </nav>
    <div class="row" v-if="projectMemberHistory">
      <div class="col-md-12 text-nowrap scroll-x h-100 overflow-auto">
        <table v-if="projectMemberHistory" class="table">
          <thead>
            <tr>
              <SortByColumn class="d-table-cell" :sort_by_field="'event_type'" :orderBy="orderBy" @refresh="refresh">Event</SortByColumn>
              <SortByColumn class="d-table-cell" :sort_by_field="'created_at'" :orderBy="orderBy" @refresh="refresh">Date</SortByColumn>
              <SortByColumn class="d-table-cell" :sort_by_field="'project_id'" :orderBy="orderBy" @refresh="refresh">Project</SortByColumn>
              <SortByColumn class="d-table-cell" :sort_by_field="'user_id'" :orderBy="orderBy" @refresh="refresh">Person</SortByColumn>
              <SortByColumn class="d-table-cell" :sort_by_field="'project_role_id'" :orderBy="orderBy" @refresh="refresh">Role</SortByColumn>
              <SortByColumn class="d-table-cell" :sort_by_field="'created_by'" :orderBy="orderBy" @refresh="refresh">Responsible</SortByColumn>
              <SortByColumn class="d-table-cell" :sort_by_field="'start_date'" :orderBy="orderBy" @refresh="refresh">Start Date</SortByColumn>
              <SortByColumn class="d-table-cell" :sort_by_field="'end_date'" :orderBy="orderBy" @refresh="refresh">End Date</SortByColumn>
              <SortByColumn class="d-table-cell" :sort_by_field="'hours_per_week'" :orderBy="orderBy" @refresh="refresh">Hours a week</SortByColumn>
              <SortByColumn class="d-table-cell" :sort_by_field="'hourly_rate'" :orderBy="orderBy" @refresh="refresh">Hourly rate</SortByColumn>
            </tr>
          </thead>
          <tbody>
            <tr :key="row.id" v-for="row in projectMemberHistory.data">
              <td class="text-capitalize">
                <i :class="eventIcon(row.event_type)"></i>
                {{row.event_type.replaceAll('_', ' ')}}
              </td>
              <td>{{$displayDate(row.created_at)}}</td>
              <td>{{row.project.name}}</td>
              <td>{{row.user.name}}</td>
              <td>
                <div v-if="wasChanged(row, 'project_role_id')">
                  <span class="text-danger">{{oldValue(row, 'project_role_id')}}</span>
                  <i class="fas fa-arrow-right"></i>
                  <span class="text-success">{{newValue(row, 'project_role_id')}}</span>
                </div>
                <span v-else>
                  {{row.project_role.name}}
                </span>
              </td>
              <td>{{row.creator.name}}</td>
              <td>{{$displayDate(row.start_date)}}</td>
              <td>
                <div v-if="wasChanged(row, 'end_date')">
                  <span class="text-danger">{{$displayDate(oldValue(row, 'end_date'))}}</span>
                  <i class="fas fa-arrow-right"></i>
                  <span class="text-success">{{$displayDate(newValue(row, 'end_date'))}}</span>
                </div>
              </td>
              <td>
                <div v-if="wasChanged(row, 'hours_per_week')">
                  <span class="text-danger">{{ $filters.formatNumber(oldValue(row, 'hours_per_week'))}}</span>
                  <i class="fas fa-arrow-right"></i>
                  <span class="text-success">{{ $filters.formatNumber(newValue(row, 'hours_per_week') )}}</span>
                </div>
                <span v-else>
                  {{ $filters.formatNumber(row.hours_per_week ) }}
                </span>
              </td>
              <td>
                <div v-if="wasChanged(row, 'hourly_rate')">
                  <span class="text-danger">${{ $filters.formatNumber(oldValue(row, 'hourly_rate'))}}</span>
                  <i class="fas fa-arrow-right"></i>
                  <span class="text-success">${{ $filters.formatNumber(newValue(row, 'hourly_rate'))}}</span>
                </div>
                <span v-else>
                  ${{ $filters.formatNumber(row.hourly_rate ) }}
                </span>
              </td>
            </tr>
            <SkeletonRow v-if="projectMemberHistory && projectMemberHistory.paginatorInfo.hasMorePages" :colspan="10" :executeScroll="projectMemberHistory != undefined" @showMore="showMore('projectMemberHistory')"></SkeletonRow>
          </tbody>
        </table>
        <Empty v-if="!projectMemberHistory.data.length" icon="fas fa-list-alt"/>
      </div>
    </div>
  </div>
</template>

<script>

import dayjs from 'dayjs';
import {
  Search, SkeletonRow, SortByColumn, SpinnerRouterLink,
} from '../../shared';
import PROJECT_MEMBER_HISTORY from './queries';
import { SearchBuilder, PaginationHandler } from '../../../shared';

export default {
  extends: PaginationHandler,
  components: {
    Search, SkeletonRow, SortByColumn, SpinnerRouterLink,
  },
  data() {
    return {
      orderBy: { column: 'created_at', order: 'DESC' },
      search: { },
    };
  },
  apollo: {
    projectMemberHistory: {
      query: PROJECT_MEMBER_HISTORY,
      errorPolicy: 'all',
      variables() {
        return {
          orderBy: [this.orderBy],
          ...SearchBuilder.build(this.search),
        };
      },
      skip() {
        return !(this.search.start_date || this.search.end_date);
      },
      error(error) {
        this.$toasted.error(error.message);
      },
    },
  },
  computed: {
    defaultDates() {
      return {
        start_date: dayjs().subtract(1, 'weeks').startOf('isoweek').format('YYYY-MM-DD'),
        end_date: dayjs().subtract(1, 'weeks').endOf('isoweek').format('YYYY-MM-DD'),
      };
    },
  },
  methods: {
    queryVariables() {
      return { orderBy: [{ column: this.orderBy.column, order: this.orderBy.order }], ...SearchBuilder.build(this.search) };
    },
    eventIcon(event) {
      const dict = {
        created: 'fas fa-user-plus',
        updated: 'fas fa-wrench',
        deleted: 'fas fa-user-minus',
        time_increased: 'fas fa-angle-double-up',
        time_reduced: 'fas fa-angle-double-down',
        role_changed: 'fas fa-random',
        left_project: 'fas fa-sign-out-alt',
        rate_increased: 'fas fa-angle-double-up',
        rate_reduced: 'fas fa-angle-double-down',
        contract_end_date_extended: 'fa-solid fa-up-right-and-down-left-from-center',
        contract_end_date_shortened: 'fa-solid fa-down-left-and-up-right-to-center',
      };
      return dict[event];
    },
    wasChanged(row, key) {
      const json = JSON.parse(row.delta);
      return row.delta && json[key];
    },
    oldValue(row, key) {
      const json = JSON.parse(row.delta);
      return json[key].from;
    },
    newValue(row, key) {
      const json = JSON.parse(row.delta);
      return json[key].to;
    },
    refresh() {
      this.$apollo.queries.projectMemberHistory.refetch();
    },
  },
};
</script>
