import { useVuelidate } from '@vuelidate/core';
import { required, email, maxLength } from '@vuelidate/validators';

export default {
  data() {
    return {
      v$: useVuelidate(), // Inicialización de vuelidate
    };
  },
  validations: {
    localRecord: {
      name: { required, maxLengthValue: maxLength(255) },
      email: { email, maxLengthValue: maxLength(255) },
      website: { maxLengthValue: maxLength(255) },
      country_id: { required },
    },
  },
};
