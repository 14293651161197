<template>
  <div class="row" v-if="profile">
    <div :key="project_participation.id" class="col-md-6 pb-2" v-for="project_participation in localProjectParticipations">
      <div class="card h-100">
        <div class="card-header">
          {{project_participation.project.name}}
          <span class="float-end">
            {{project_participation.start_date}} - {{project_participation.end_date || 'Now'}}
          </span>
        </div>
        <div class="card-body">
          <div class="row mb-2">
            <div class="col-md-12">
              <h5 class="card-title">{{project_participation.project_role.name}}</h5>
              <p class="card-text" v-html="project_participation.project.description"></p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <h5 class="card-title">My Project Responsabilities:</h5>
              <textarea class="form-control" v-model="project_participation.responsibilities"
              :placeholder="placeholder" rows="13"></textarea>
              <button class="btn btn-company-primary mt-2" @click="update(project_participation)">
                <i v-if="loading" class="fas fa-sync fa-spin"></i>
                <i v-if="!loading" class="fas fa-save"></i>
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { UPDATE_PROJECT_MEMBER } from './mutations';

export default {
  props: ['profile'],
  data() {
    return {
      loading: false,
      localProjectParticipations: [],
    };
  },
  methods: {
    update(projectParticipation) {
      this.loading = true;
      this.$apollo.mutate({
        mutation: UPDATE_PROJECT_MEMBER,
        variables: {
          id: projectParticipation.id,
          project_member: {
            responsibilities: projectParticipation.responsibilities,
          },
        },
      }).catch((error) => {
        this.$toasted.error(`Unable to save the record: ${error.message}`);
      }).then(() => {
        this.$toasted.success('Changes were saved');
      }).finally(() => {
        this.loading = false;
      });
    },
  },
  computed: {
    placeholder() {
      return [
        'Researching, designing, implementing and managing software programs',
        'Testing and evaluating new programs',
        'Monitoring apps in production',
        'Writing and implementing efficient code',
        'Deploying web apps to several environments',
        'Fixing bugs',
        'Training users and team members',
        'Working closely with other developers, UX designers, business and systems analysts',
        'Having daily meetings with the development team',
        'Estimating new requirements',
        'Leading technical teams',
        'Writing specs/test for every new feature added',
      ].join('\n');
    },
  },
  watch: {
    profile: {
      handler(newProfile) {
        if (newProfile && newProfile.project_participations) {
          this.localProjectParticipations = newProfile.project_participations.map((participation) => ({
            ...participation,
          }));
        }
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>
