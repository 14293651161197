import { gql } from '@apollo/client/core';

export default gql`
query($job_title_id: ID, $user_id: ID, $country_id: ID) {
  costsByJobTitle(job_title_id: $job_title_id, user_id: $user_id, country_id: $country_id) {
    job_title_id
    user_id
    cost
    user_name
    job_title_name
  }
}
`;
