import { gql } from '@apollo/client/core';

export default gql`
  query($state: String) {
    projectAttachments(state: $state) {
      data {
        id
        name
        description
        start_date
        end_date
        attachments {
          id
          name
          url
        }
      }
    }
  }
`;
