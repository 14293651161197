<template>
  <div>
    <Form v-if="selectedRecord" v-bind:record="selectedRecord"
                                @close="selectedRecord = null"
                                @insert="insert"></Form>
    <nav>
      <div class="breadcrumb p-2">
        <div class="col-md-5 m-auto p-0">
          <ol class="breadcrumb p-0 m-auto">
            <li class="breadcrumb-item">
              <router-link to="/home">
                <i v-if="!$apollo.loading" class="fas fa-home"></i>
                <i v-if="$apollo.loading" class="fas fa-sync ms-1 fa-sm" :class="{ 'fa-spin': $apollo.loading }"></i>
              </router-link>
            </li>
            <li class="breadcrumb-item active fw-bold" aria-current="page">
              <router-link :to="{ name: 'Clients' }"> Clients </router-link>
            </li>
            <li class="breadcrumb-item active fw-bold" aria-current="page">
              {{ client.name }}
            </li>
            <li class="breadcrumb-item fw-bold" aria-current="page">
              Users
            </li>
          </ol>
        </div>
        <div class="col-md-5 m-auto p-0">
        </div>
        <div class="col-md-2 text-end">
          <button type="button" class="btn btn-company-primary" @click="newUserForClient()"
            v-if="$can('CreateUserForClient')">
            <i class="fas fa-plus"></i>
            New
          </button>
        </div>
      </div>
    </nav>
    <div class="row" v-if="users">
      <Card :key="index" v-for="(record, index) in users.data" :record="record" @remove="remove" @refresh="refresh">
      </Card>
    </div>
  </div>
</template>

<script>

import { gql } from '@apollo/client/core';
import QUERY_USERS from './queries';
import Form from './Form.vue';
import Card from './Card.vue';
import { INSERT_USER, USER_DELETE } from './mutations';

export default {
  components: {
    Card, Form,
  },
  apollo: {
    client: {
      query: gql`query ($id:ID) { client(id:$id) {id name} }`,
      errorPolicy: 'all',
      variables() {
        return { id: this.$route.params.id };
      },
      error(error) {
        this.$toasted.error(error.message);
      },
    },
    users: {
      query: QUERY_USERS,
      errorPolicy: 'all',
      variables() {
        return { page: 1, client_id: this.$route.params.id };
      },
      error(error) {
        this.$toasted.error(error.message);
      },
    },
  },
  data() {
    return {
      selectedRecord: null,
      generatedPassword: '',
      hashedPassword: '',
      client: {}, // Prevents undefined error when loading the page
    };
  },
  methods: {
    queryVariables() {
      return { client_id: this.$route.params.id };
    },
    newUserForClient() {
      this.selectedRecord = { client_id: this.$route.params.id };
    },
    refresh() {
      this.$apollo.queries.client.refetch();
    },
    generatePassword() {
      const length = 8;
      const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*';
      let password = '';
      for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * charset.length);
        password += charset.charAt(randomIndex);
      }
      this.generatedPassword = password;
    },
    insert(record) {
      this.generatePassword();
      this.selectedRecord = null;
      const user = {
        name: record.name,
        email: record.email,
        client_id: this.$route.params.id,
        password: this.generatedPassword,
      };
      this.$apollo.mutate({
        mutation: INSERT_USER,
        variables: {
          user,
        },
        update: (store, { data: { createUserForClient } }) => {
          const data = store.readQuery({ query: QUERY_USERS, variables: this.queryVariables() });
          store.modify({ fields: { users(existingUsers = { data: [] }) { return { ...existingUsers, data: [createUserForClient, ...data.users.data] }; } } });
        },
      }).catch((error) => {
        this.$toasted.error(`Unable to create record: ${error.message}`);
      });
    },
    remove(record) {
      this.$apollo
        .mutate({
          mutation: USER_DELETE,
          variables: {
            id: record.id,
          },
          update: (store, { data: { deleteUser } }) => {
            const data = store.readQuery({ query: QUERY_USERS, variables: this.queryVariables() });
            const updatedUser = data.users.data.filter((c) => c.id !== deleteUser.id);
            store.modify({ fields: { users(existingUsers = { data: [] }) { return { ...existingUsers, data: updatedUser }; } } });
          },
        }).catch((error) => {
          this.$toasted.error(`Unable to delete record: ${error.message}`);
        });
    },
  },
};
</script>
