import { gql } from '@apollo/client/core';

export default gql`
  query($start_date: DateTime, $end_date: DateTime){
    technology_distribution(start_date: $start_date, end_date: $end_date){
      name
      frequency
    }
  }
`;
