import { gql } from '@apollo/client/core';

import INTERVIEW_QUESTION_FRAGMENT from './fragments';

export const CREATE_INTERVIEW_QUESTION = gql`
  mutation($interviewQuestion: InterviewQuestionInput!){
    createInterviewQuestion(input: $interviewQuestion){
      ...interviewQuestion
    }
  }
  ${INTERVIEW_QUESTION_FRAGMENT}
`;

export const UPDATE_INTERVIEW_QUESTION = gql`
  mutation($id:ID!, $interviewQuestion: InterviewQuestionInput!){
    updateInterviewQuestion(id: $id, input: $interviewQuestion){
      ...interviewQuestion
    }
  }
  ${INTERVIEW_QUESTION_FRAGMENT}
`;

export const DELETE_INTERVIEW_QUESTION = gql`
  mutation($id: ID!){
    deleteInterviewQuestion(id: $id){
      id
    }
  }
`;
