import { gql } from '@apollo/client/core';

export default gql`
    fragment projectMinute on ProjectMinute {
        id
        name
        date
        created_at
        project_id
        user {
            id
            name
        }
    }
`;
