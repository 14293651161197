import { gql } from '@apollo/client/core';

export default gql`
    fragment paid_time_off_history on PaidTimeOffHistory {
        id
        days
        accumulated_days
        reason
        created_at
        comments
        user {
            id
            name
        }
        updated_by {
            id
            name
        }
    }
`;
