<template>
  <div>
    <Form v-if="selectedRecord" v-bind:record="selectedRecord" @close="selectedRecord = null" @insert="insert"></Form>
    <nav>
      <div class="breadcrumb p-2">
        <div class="row">
          <div class="col-auto ms-3 p-0">
            <SpinnerRouterLink :loading="$apollo.loading">Partners</SpinnerRouterLink>
          </div>
        </div>
        <div class="container-fluid">
          <div class="row">
            <div class="col-12 col-sm-7 m-auto">
              <Search
                v-model="search"
                :inputs="['name', 'partnerType']"
              ></Search>
            </div>
            <div class="col-12 col-sm-5 order-last text-end mt-2 pt-2">
              <button type="button" class="btn btn-company-primary" @click="selectedRecord = { contacts: [ ] }" v-if="$can('CreatePartner')">
                <i class="fas fa-plus"></i>
                New
              </button>
            </div>
          </div>
        </div>
      </div>
    </nav>
    <div class="row" v-if="partners">
      <Card :key="index" v-for="(record, index) in partners.data" :record="record" @remove="remove" @update="update"></Card>
      <Empty v-if="!partners.data.length" icon="fas fa-handshake" :can_create="true" @callCreate="selectedRecord = { contacts: [ ] }"/>
    </div>
    <SkeletonCards v-if="partners && partners.paginatorInfo.hasMorePages" :cards=3 :executeScroll="partners != undefined" @showMore="showMore('partners')"></SkeletonCards>
  </div>
</template>

<script>
import { QUERY, QUERY_WITH_COSTS } from './queries';
import { CREATE_MUTATION, CREATE_MUTATION_WITH_COST, DELETE_MUTATION } from './mutations';
import Form from './Form.vue';
import Card from './Card.vue';
import { Search, SkeletonCards, SpinnerRouterLink } from '../shared';
import { SearchBuilder, ErrorHandler, PaginationHandler } from '../../shared';
import RequestBuilder from './RequestBuilder';

export default {
  extends: PaginationHandler,
  components: {
    Form, Card, Search, SkeletonCards, SpinnerRouterLink,
  },
  apollo: {
    partners: {
      errorPolicy: 'all',
      query() {
        return this.$can('ReadCostPerUser') ? QUERY_WITH_COSTS : QUERY;
      },
      variables() {
        return {
          page: 1,
          orderBy: [{ column: 'id', order: 'ASC' }],
          ...SearchBuilder.build(this.search),
        };
      },
      error(error) {
        this.$toasted.error(error.message);
      },
    },
  },
  data() {
    return {
      search: { },
      selectedRecord: null,
    };
  },
  methods: {
    queryVariables() {
      return { orderBy: [{ column: 'id', order: 'ASC' }], ...SearchBuilder.build(this.search) };
    },
    remove(record) {
      this.$apollo.mutate({
        mutation: DELETE_MUTATION,
        variables: {
          id: record.id,
        },
      }).then(({ data: { deletePartner } }) => {
        this.partners = {
          ...this.partners,
          data: this.partners.data.filter((partner) => partner.id !== deletePartner.id),
        };
      }).catch((error) => {
        this.$toasted.error(`Unable to delete record: ${error.message}`);
      });
    },
    insert(record) {
      const newRecord = { ...record };
      this.selectedRecord = null;
      this.$apollo.mutate({
        mutation: this.$can('ReadCostPerUser') ? CREATE_MUTATION_WITH_COST : CREATE_MUTATION,
        variables: {
          record: RequestBuilder.build(record),
        },
        update: (store, { data: { createPartner } }) => {
          const query = this.$can('ReadCostPerUser') ? QUERY_WITH_COSTS : QUERY;
          const data = store.readQuery({ query, variables: { is_active: true, ...this.queryVariables() } });
          store.writeQuery({
            query,
            variables: { is_active: true, ...this.queryVariables() },
            data: {
              ...data,
              partners: {
                ...data.partners,
                data: [createPartner, ...data.partners.data],
              },
            },
          });
        },
      }).catch((error) => {
        ErrorHandler.handle(error, this);
        this.selectedRecord = newRecord;
      });
    },
    update(record) {
      const index = _.findIndex(this.partners.data, { id: record.id });
      this.partners = {
        ...this.partners,
        data: [
          ...this.partners.data.slice(0, index),
          record,
          ...this.partners.data.slice(index + 1),
        ],
      };
    },
  },
};
</script>
