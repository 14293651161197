<template>
  <div>
    <nav>
      <div class="breadcrumb p-2">
        <div class="row">
          <div class="col-auto ms-3 p-0">
            <SpinnerRouterLink :loading="$apollo.loading">Anniversaries</SpinnerRouterLink>
          </div>
        </div>
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12 m-auto mt-2">
              <Search
                v-model="search"
                :inputs="['users', 'onlyMyProjectsCheckbox']"
              ></Search>
            </div>
          </div>
        </div>
      </div>
    </nav>
    <div class="row" v-if="anniversariesByMonth">
      <div class="col-md-12 text-nowrap h-100 scroll-x">
        <table class="table">
          <thead>
            <tr>
              <th></th>
              <th>Person</th>
              <th>Years of service</th>
              <th>Hiring Date</th>
              <th>Next Work Aniversary</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="row in anniversariesByMonth">
              <tr :key="`a-${row.month}-${index}`" v-for="(td, index) in row.data">
                <td style="writing-mode: vertical-rl; cursor: default" :rowspan="row.data.length" v-if="index === 0">{{ row.month }}</td>
                <td>{{td.name}}</td>
                <td>{{td.years_of_service}}</td>
                <td>{{$displayDate(td.hiring_date)}}</td>
                <td>
                  <span v-if="td.next_work_aniversary">
                    <i v-if="isToday(td.next_work_aniversary)" class="fa-solid fa-star text-warning"></i>
                    {{$displayDate(td.next_work_aniversary)}} ({{ remainingDays(td.next_work_aniversary) }})
                  </span>
                  <i v-else class="fas fa-circle text-danger" :title="`Birthday is not set for ${td.name}`"></i>
                </td>
              </tr>
            </template>
            <tr v-if="anniversariesByMonth && anniversariesByMonth.length === 0">
              <td class="text-center" colspan="5">No records found</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <Empty v-if="!anniversariesByMonth?.length" icon="fas fa-birthday-cake"/>
  </div>
</template>

<script>

import dayjs from 'dayjs';
import { Search, SpinnerRouterLink } from '../shared';
import ANNIVERSARIES_QUERY from './queries';

export default {
  components: { Search, SpinnerRouterLink },
  data() {
    return {
      search: { only_my_projects: false },
    };
  },
  computed: {
    anniversariesByMonth() {
      const months = _.compact(_.uniq(_.map(this.anniversaries, (row) => (row.next_work_aniversary ? this.monthNumberToEnglish(row.next_work_aniversary) : null))));
      return months.map((month) => ({ month, data: this.anniversaries.filter((row) => this.monthNumberToEnglish(row.next_work_aniversary) === month) }));
    },
  },
  methods: {
    refresh() {
      this.$apollo.queries.anniversaries.refetch();
    },
    isToday(date) {
      return dayjs(date, 'YYYY-MM-DD').format('MM-DD') === dayjs().format('MM-DD');
    },
    monthNumberToEnglish(date) {
      return dayjs(date, 'YYYY-MM-DD').format('MMMM');
    },
    remainingDays(endDate) {
      return dayjs(endDate, 'YYYY-MM-DD').endOf('day').fromNow();
    },
  },
  apollo: {
    anniversaries: {
      query: ANNIVERSARIES_QUERY,
      errorPolicy: 'all',
      variables() {
        return {
          ...this.search,
        };
      },
      error(error) {
        this.$toasted.error(error.message);
      },
    },
  },
};
</script>
