import { gql } from '@apollo/client/core';

import BUSINESS_UNIT_FRAGMENT from './fragments';

export default gql`
  query($page: Int, $name: String) {
    business_units(
      page: $page,
      name: $name,
      orderBy: { column: "id", order: ASC }) @connection(key: "business_units", filter: ["name"]) {
      paginatorInfo {
        hasMorePages
      }
      data {
        ...business_unit
      }
    }
  }
  ${BUSINESS_UNIT_FRAGMENT}
`;
