import { gql } from '@apollo/client/core';

export default gql`
query (
  $page: Int, 
  $notification_setting_id: ID, 
  $orderBy: [OrderByClause!]) {
  notification_templates (
    page: $page, 
    notification_setting_id: $notification_setting_id, 
    orderBy: $orderBy
  ) @connection(key: "notification_templates", filter: ["notification_setting_id"]) {
    paginatorInfo {
      hasMorePages
    }
    data {
      id
      name
      text
      notification_setting_id
    }
  }
}
`;
