import { gql } from '@apollo/client/core';

export default gql`
  query($page: Int, $name: String, $email: String, $is_bot: Boolean) {
    user_directory(page: $page, name: $name, email: $email, is_bot: $is_bot) {
      paginatorInfo {
        hasMorePages
      }
      data {
        id
        name
        email
        avatar
        personal_phone
        phone_extension
      }
    }
  }
`;
