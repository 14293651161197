import { gql } from '@apollo/client/core';

export default gql`
  fragment learning_path_level_node_user on LearningPathLevelNodeUser {
    id
    user_id
    learning_path_level_node {
      id
      name
      evaluation_methodology
      learning_path_level {
        id
        name
        learning_path {
          id
          name
        }
      }
    }
    user {
      id
      name
    }
  }
`;
