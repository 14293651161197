import { gql } from '@apollo/client/core';

import TECHNOLOGY_FRAGMENT from './fragments';

export const CREATE_TECHNOLOGY = gql`
  mutation($technology: TechnologyInput!){
    createTechnology(input: $technology){
      ...technology
    }
  }
  ${TECHNOLOGY_FRAGMENT}
`;

export const UPDATE_TECHNOLOGY = gql`
  mutation($id: ID!, $technology: TechnologyInput!){
    updateTechnology(id: $id, input: $technology){
      ...technology
    }
  }
  ${TECHNOLOGY_FRAGMENT}
`;

export const DELETE_TECHNOLOGY = gql`
  mutation($id: ID!){
    deleteTechnology(id: $id){
      id
    }
  }
`;
