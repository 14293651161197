import { gql } from '@apollo/client/core';

export default gql`
  query($start_date: Date!, $end_date: Date!, $user_id: ID) {
    timeline(user_id: $user_id, start_date: $start_date, end_date: $end_date){
      x
      x2
      y
      project
      user
      user_id
      start_date
      end_date
    }
  }
`;
