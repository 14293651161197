<template>
<div :id="record.id" class="col-md-6 pb-2">
  <Form v-if="is_editting" :record="record"
                            @close="is_editting = false"
                            @update="update"></Form>
  <div v-if="!is_editting" class="card h-100">
    <div class="card-body pb-0">
      <h5 class="card-title">
        {{$displayDate(record.date)}}
      </h5>
      <h6 class="card-subtitle mb-2 text-body-secondary">{{record.user.name}}</h6>
      <p v-html='record.name'></p>
    </div>
    <div class="card-body">
      <button v-if="!confirmation && $can('UpdateProjectMinute')" class="btn btn-company-primary" @click="is_editting = true">
        <i class="fas fa-pen"></i>
        Edit
      </button>
      <router-link class="btn btn-company-primary" :to="{name:'Actions', params: { id: record.project_id }, query: {project_minute_id: record.id}}">
        <i class="fa-solid fa-bolt"></i>
          Actions
      </router-link>
      <Confirmation v-if="$can('DeleteProjectMinute')"
        v-model="confirmation"
        @accepted="remove()"
      />
    </div>
  </div>
</div>
</template>

<script>
import { UPDATE_PROJECT_MINUTE } from './mutations';
import Form from './Form.vue';
import { Confirmation } from '../shared';

export default {
  components: { Form, Confirmation },
  props: ['record'],
  data() {
    return {
      is_editting: false,
      confirmation: false,
      actions: null,
    };
  },
  methods: {
    update(record) {
      record.project_id = parseInt(this.$route.params.id, 10);
      this.$apollo.mutate({
        mutation: UPDATE_PROJECT_MINUTE,
        variables: {
          id: parseInt(record.id, 10),
          projectMinute: _.pick(record, ['name', 'date', 'project_id']),
        },
      }).then(() => {
        this.is_editting = false;
      });
    },
    remove() {
      this.$emit('remove', this.record);
      this.is_editting = false;
      this.confirmation = false;
    },
  },
};
</script>
