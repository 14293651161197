<template>
  <div>
    <Form v-if="selectedRecord" v-bind:record="selectedRecord"
                                  @close="selectedRecord = null"
                                  @insert="insert"></Form>
    <nav>
      <div class="breadcrumb p-2">
        <div class="col-md-5 m-auto p-0">
          <ol class="breadcrumb p-0 m-auto">
            <li class="breadcrumb-item">
              <router-link to="/home">
                <i v-if="!$apollo.loading" class="fas fa-home"></i>
                <i v-if="$apollo.loading" class="fas fa-sync ms-1 fa-sm" :class="{'fa-spin': $apollo.loading}"></i>
              </router-link>
            </li>
            <li class="breadcrumb-item active fw-bold" aria-current="page">
              <router-link to="/users">Users</router-link>
            </li>
            <li class="breadcrumb-item active fw-bold" aria-current="page">
              <router-link :to="`/users?name=${user?.name}`">{{ user?.name }}</router-link>
            </li>
            <li class="breadcrumb-item fw-bold" aria-current="page">
              Interviews
            </li>
          </ol>
        </div>
        <div class="col-md-5 m-auto p-0">
          <Search v-model="search"></Search>
        </div>
        <div class="col-md-2 text-end">
          <button type="button" class="btn btn-company-primary" @click="newInterview()" v-if="$can('CreateInterview')">
            <i class="fas fa-plus"></i>
            New
          </button>
        </div>
      </div>
    </nav>
    <div class="row" v-if="interviews">
      <Empty v-if="!interviews.data.length" icon="fas fa-users"/>
      <Card :key="index" v-for="(record, index) in interviews.data" :record="record" @remove="remove" @refresh="refresh"></Card>
    </div>
    <SkeletonCards v-if="interviews && interviews.paginatorInfo.hasMorePages" :cards=4 :executeScroll="interviews != undefined" @showMore="showMore('interviews')"></SkeletonCards>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import { gql } from '@apollo/client/core';
import INTERVIEWS_QUERY from './queries';
import { CREATE_INTERVIEW, DELETE_INTERVIEW } from './mutations';
import { SkeletonCards } from '../shared';
import Form from './Form.vue';
import Card from './Card.vue';
import Search from './Search.vue';
import { SearchBuilder, ErrorHandler, PaginationHandler } from '../../shared';

export default {
  extends: PaginationHandler,
  components: {
    SkeletonCards, Form, Card, Search,
  },
  apollo: {
    user: {
      query: gql`query ($id: ID!) { user (id: $id) { id name }}`,
      errorPolicy: 'all',
      variables() {
        return { id: this.$route.params.id };
      },
      error(error) {
        this.$toasted.error(error.message);
      },
    },
    interviews: {
      query: INTERVIEWS_QUERY,
      errorPolicy: 'all',
      variables() {
        return { page: 1, user_id: parseInt(this.$route.params.id, 10), ...SearchBuilder.build(this.search) };
      },
      error(error) {
        this.$toasted.error(error.message);
      },
    },
  },
  data() {
    return {
      search: {},
      selectedRecord: null,
    };
  },
  methods: {
    queryVariables() {
      return { user_id: parseInt(this.$route.params.id, 10), ...SearchBuilder.build(this.search) };
    },
    newInterview() {
      this.selectedRecord = {
        is_successful: false,
        user_id: parseInt(this.$route.params.id, 10),
        date: dayjs().format('YYYY-MM-DD'),
        notes: '',
      };
    },
    refresh() {
      this.$apollo.queries.interviews.refetch();
    },
    remove(interview) {
      this.$apollo.mutate({
        mutation: DELETE_INTERVIEW,
        variables: {
          id: interview.id,
        },
      }).then(() => {
        this.refresh();
      }).catch((error) => {
        this.$toasted.error(`Unable to delete record: ${error.message}`);
      });
    },
    insert(interview) {
      const newInterview = interview;
      this.selectedRecord = null;
      this.$apollo.mutate({
        mutation: CREATE_INTERVIEW,
        variables: {
          interview,
        },
      }).then(() => {
        this.refresh();
      }).catch((error) => {
        ErrorHandler.handle(error, this);
        this.selectedRecord = newInterview;
      });
    },
  },
};
</script>
