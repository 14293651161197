<template>
  <div>
    <nav>
      <div class="breadcrumb p-2">
        <div class="row">
          <div class="col-auto ms-3 p-0">
            <SpinnerRouterLink :loading="$apollo.loading">Notification Settings</SpinnerRouterLink>
          </div>
        </div>
        <div class="container-fluid">
          <div class="row">
            <div class="col-12 m-auto">
              <Search
                v-model="search"
                :inputs="['name']"
              ></Search>
            </div>
          </div>
        </div>
      </div>
    </nav>
    <div class="row" v-if="notification_settings">
      <Empty v-if="!notification_settings.data.length" icon="fas fa-search"/>
      <Card :key="index" v-for="(record, index) in notification_settings.data" :record="record"></Card>
    </div>
    <SkeletonCards v-if="notification_settings && notification_settings.paginatorInfo.hasMorePages" :cards=6 :executeScroll="notification_settings != undefined" @showMore="showMore('notification_settings')"></SkeletonCards>
  </div>
</template>

<script>
import NOTIFICATION_SETTINGS_QUERY from './queries';
import Card from './Card.vue';
import { Search, SkeletonCards, SpinnerRouterLink } from '../shared';
import { SearchBuilder, PaginationHandler } from '../../shared';

export default {
  extends: PaginationHandler,
  components: {
    Card, Search, SkeletonCards, SpinnerRouterLink,
  },
  apollo: {
    notification_settings: {
      query: NOTIFICATION_SETTINGS_QUERY,
      errorPolicy: 'all',
      variables() {
        return { page: 1, ...SearchBuilder.build(this.search) };
      },
      error(error) {
        this.$toasted.error(error.message);
      },
    },
  },
  data() {
    return {
      search: {},
    };
  },
  methods: {
    queryVariables() {
      return SearchBuilder.build(this.search);
    },
  },
};
</script>
