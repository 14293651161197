<style scoped>
.w-70 {
  width: 70%;
}
</style>

<template>
  <div class="row px-4 ">
    <div class="company-control">
      <label for="notification_recipients">Notify:</label>
      <input ref="notify" id="notification_recipients" v-model="alarm.notification_recipients" type="text" class="form-control"
        placeholder="Email" :class="{ 'is-invalid': v$.alarm.notification_recipients.$invalid }">
      <div v-for="error of v$.alarm.notification_recipients.$silentErrors" :key="error.$uid">
        <div class="text-danger">{{ error.$message }}</div>
      </div>
    </div>
    <div class="d-flex gap-2">
      <div class="company-control col">
        <label for="threshold_type">When value is:</label>
        <select id="threshold_type" v-model="alarm.threshold_type" class="form-control form-select"
          :class="{ 'is-invalid': v$.alarm.threshold_type.$invalid }">
          <option value="GREATER_THAN">Greater than</option>
          <option value="LESS_THAN">Less than</option>
        </select>
        <div v-for="error of v$.alarm.threshold_type.$silentErrors" :key="error.$uid">
          <div class="text-danger">{{ error.$message }}</div>
        </div>
      </div>
      <div class="company-control col">
        <label for="threshold_value">Than:</label>
        <input id="threshold_value" v-model="alarm.threshold_value" type="number" class="form-control" placeholder="Value"
          :class="{ 'is-invalid': v$.alarm.threshold_value.$invalid }">
        <div v-for="error of v$.alarm.threshold_value.$silentErrors" :key="error.$uid">
          <div class="text-danger">{{ error.$message }}</div>
        </div>
      </div>
    </div>
    <div class="row mx-auto mt-2 gap-2">
      <button :disabled="v$.alarm.$invalid" @click="insert" type="button" class="btn btn-company-primary col">
        <i v-if="!loading" class="fas fa-save"></i>
        <i v-else class="fas fa-sync" :class="{ 'fa-spin': loading }"></i>
        Save
      </button>
      <button @click="() => cancel(false)" type="button" class="btn btn-company-primary col">
        <i class="fas fa-times"></i>
        Cancel
      </button>
    </div>
    <table class="table mt-3">
      <tbody>
        <tr v-for="(alarm, key) in indicator.alarms" :key="key">
          <td class="col-9">
            Notify <span class="bg-company-third-ligth">{{ alarm.notification_recipients }}</span>
            when the value is <span class="bg-company-third-ligth">{{ alarm.threshold_type.replace('_', ' ') }}</span>
            than <span class="bg-company-third-ligth">{{ alarm.threshold_value }}</span>
          </td>
          <td class="col-3 text-end" v-if="$can('DeleteIndicatorAlarms')">
            <Confirmation @accepted="remove" :id="alarm.id" :show-labels="false"></Confirmation>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import Confirmation from '../shared/Confirmation.vue';
import { CREATE_ALARM, DELETE_ALARM } from './mutations';
import { INDICATORS_QUERY } from './queries';
import { SearchBuilder } from '../../shared';
import Validations from './Validations';

export default {
  mixins: [Validations],
  components: { Confirmation },
  props: { hidden: Boolean, indicator: Object, cancel: Function },
  data() {
    return {
      alarm: {},
      loading: false,
      search: {},
      confirmation: false,
    };
  },
  methods: {
    queryVariables() {
      return {
        orderBy: [{ column: 'id', order: 'ASC' }],
        is_visible: true,
        ...SearchBuilder.build(this.search),
      };
    },
    insert() {
      this.loading = true;
      this.alarm.indicator_id = this.indicator.id;
      this.alarm.threshold_value = parseFloat(this.alarm.threshold_value);
      this.$apollo.mutate({
        mutation: CREATE_ALARM,
        variables: {
          alarm: this.alarm,
        },
        update: (store, { data: { createAlarm } }) => {
          const data = store.readQuery({ query: INDICATORS_QUERY, variables: { page: 1, ...this.queryVariables() } });
          const newData = data.indicators.data.map((indicator) => {
            if (indicator.id === this.indicator.id) {
              return {
                ...indicator,
                alarms: [createAlarm, ...indicator.alarms],
              };
            }
            return indicator;
          });
          store.writeQuery({ query: INDICATORS_QUERY, variables: this.queryVariables(), data: { indicators: { data: newData, paginatorInfo: data.indicators.paginatorInfo } } });
        },
      }).then(() => {
        this.alarm = {};
        this.loading = false;
        this.$refs.notify.focus();
      });
    },
    remove(id) {
      this.$apollo.mutate({
        mutation: DELETE_ALARM,
        variables: {
          id,
        },
        update: (store, { data: { deleteAlarm } }) => {
          const data = store.readQuery({ query: INDICATORS_QUERY, variables: { page: 1, ...this.queryVariables() } });
          const newData = {
            ...data,
            indicators: {
              ...data.indicators,
              data: data.indicators.data.map((indicator) => {
                if (indicator.id === this.indicator.id) {
                  return {
                    ...indicator,
                    alarms: indicator.alarms.filter((t) => t.id !== deleteAlarm.id),
                  };
                }
                return indicator;
              }),
            },
          };
          store.writeQuery({ query: INDICATORS_QUERY, variables: this.queryVariables(), data: newData });
        },
      });
    },
  },
};
</script>
