<template>
  <div class="float-end w-25 card z-1" v-if="localRecord">
    <h5 class="card-header">New Embed Report</h5>
    <div class="card-body">
      <div class="mb-3">
        <label for="title">Title</label>
        <input v-focus type="text" class="form-control" :class="{'is-invalid': v$.localRecord.title.$invalid}" id="title" v-model="localRecord.title"/>
        <div v-for="error of v$.localRecord.title.$silentErrors" :key="error.$uid">
          <div class="text-danger">{{ error.$message }}</div>
        </div>
        <label for="url">Url</label>
        <input type="text" class="form-control" :class="{'is-invalid': v$.localRecord.url.$invalid}" id="url" v-model="localRecord.url" placeholder="Datastudio, PowerBI, Tableau embed link here" />
        <div v-for="error of v$.localRecord.url.$silentErrors" :key="error.$uid">
          <div class="text-danger">{{ error.$message }}</div>
        </div>
      </div>
    </div>
    <div class="card-header d-grid">
      <button :disabled="v$.localRecord.$invalid" type="button" class="btn btn-company-primary" @click="save()">
        <i class="fas fa-save"></i>
        Save
      </button>
      <button type="button" class="btn btn-company-secondary" @click="$emit('close')">
        <i class="fas fa-times"></i>
        Cancel
      </button>
    </div>
  </div>
</template>
<script>
import _ from 'lodash';
import Validations from './Validations';

export default {
  extends: Validations,
  props: ['report'],
  data() {
    return {
      localRecord: _.cloneDeep(this.report),
    };
  },
  watch: {
    localRecord: {
      deep: true,
      handler(newValue) {
        if (!_.isEqual(newValue, this.report)) {
          this.$emit('update:report', newValue);
        }
      },
    },
    report: {
      deep: true,
      handler(newValue) {
        if (!_.isEqual(newValue, this.localRecord)) {
          this.localRecord = _.cloneDeep(newValue);
        }
      },
    },
  },
  methods: {
    save() {
      this.$emit('insert', this.localRecord);
    },
  },
};
</script>
