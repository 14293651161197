<template>
  <div>
    <nav>
      <div class="breadcrumb p-2">
        <div class="row">
          <div class="col-auto ms-3 p-0">
            <SpinnerRouterLink :loading="$apollo.loading">Gcoin Balances</SpinnerRouterLink>
          </div>
        </div>
        <div class="container-fluid">
          <div class="row">
            <div class="col-12 col-sm-12 m-auto">
              <Search v-model="search" :inputs="['name', 'email', 'isActive', 'onlyMyTeamCheckbox']"></Search>
            </div>
          </div>
        </div>
      </div>
    </nav>
    <div class="row" v-if="users">
      <Empty v-if="!users.data.length" icon="fa-solid fa-piggy-bank"/>
      <Card :key="record.id"
        v-for="record in users.data"
        :record="record"
        @recharged="reload()"
      ></Card>
    </div>
    <SkeletonCards
      v-if="users && users.paginatorInfo.hasMorePages"
      :cards=3
      :executeScroll="users != undefined"
      @showMore="showMore('users')"
    ></SkeletonCards>
  </div>
</template>

<script>

import GCOIN_BALANCES from './queries';
import Card from './Card.vue';
import { Search, SkeletonCards, SpinnerRouterLink } from '../shared';
import {
  SearchBuilder,
  ErrorHandler,
  PaginationHandler,
} from '../../shared';

export default {
  extends: PaginationHandler,
  components: {
    Card, Search, SkeletonCards, SpinnerRouterLink,
  },
  data() {
    return {
      search: { only_my_team: false, active_only: true },
      is_exporting: false,
      orderBy: { column: 'id', order: 'DESC' },
    };
  },
  apollo: {
    users: {
      query: GCOIN_BALANCES,
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
      variables() {
        return {
          page: 1,
          ...SearchBuilder.build(this.search),
          orderBy: [this.orderBy],
        };
      },
      error(error) {
        ErrorHandler.handle(error, this);
      },
    },
  },
  methods: {
    queryVariables() {
      return SearchBuilder.build(this.search);
    },
    reload() {
      this.$apollo.queries.users.refetch();
    },
  },
};
</script>
