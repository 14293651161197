<template>
    <div>
      <div class="row">
        <div class="col-md-12 text-center">
          <div id="chart"></div>
        </div>
      </div>
      <div class="row align-items-center" v-if="!$apollo.loading">
        <div class="col-md-3">
          <div class="h-100" id="tachometer_chart"> </div>
        </div>
        <div class="col-md-3 text-center text-success">
          <i class="fa-solid fa-users fa-2x"></i>
          <h2>Promoters:</h2>
          <h3>
            {{ informationData.promoters }} (<span class="fw-bold">{{ informationData.promoters_percentage }}%</span>)
          </h3>
        </div>
        <div class="col-md-3 text-center text-body-secondary">
          <i class="fa-solid fa-users fa-2x"></i>
          <h2>Neutrals:</h2>
          <h3>
            {{ informationData.neutrals }} (<span class="fw-bold">{{ informationData.neutrals_percentage }}%</span>)
          </h3>
        </div>
        <div class="col-md-3 text-center text-danger">
          <i class="fa-solid fa-users fa-2x"></i>
          <h2>Detractors:</h2>
          <h3>
            {{ informationData.detractors }} (<span class="fw-bold">{{ informationData.detractors_percentage }}%</span>)
          </h3>
        </div>
      </div>
    </div>
</template>

<script>
import Highcharts from 'highcharts';
import AccessibilityModule from 'highcharts/modules/accessibility';

// Activar el módulo de accesibilidad
AccessibilityModule(Highcharts);

export default {
  props: ['informationData', 'source'],
  mounted() {
    this.drawCharts();
  },
  watch: {
    informationData() {
      this.drawCharts();
    },
  },
  computed: {
    chartData() {
      const data = [];
      for (let i = 0; i <= 10; i++) {
        const value = this.informationData[`total_${i}`];
        data.push({
          y: value,
          percentage: Math.round(((value) * 100) / (this.informationData.total)),
        });
      }
      return data;
    },
    sourceActivator() {
      return (this.source) ? `Source: <a href="${this.source}" rel="noopener noreferrer nofollow" target="_blank"">${this.title}</a>` : '';
    },
  },
  methods: {
    drawCharts() {
      this.drawScoreChart();
      this.drawTachometerChart();
    },
    drawScoreChart() {
      Highcharts.chart('chart', {
        accessibility: {
          enabled: true,
        },
        colors: ['#c30000', '#ea0000', '#ff0800', '#ff5700', '#ff8700', '#ffae00', '#ffda00', '#e6e7e8', '#e6e7e8', '#6dce2e', '#00ab32'],
        title: {
          text: 'Net Promoter Score',
        },
        subtitle: {
          text: `Source: <a href="${this.source}" target="_blank">Here</a>`,
        },
        xAxis: {
          categories: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
          lineWidth: 0,
        },
        yAxis: {
          title: {
            text: 'Responses',
            style: {
              fontSize: '16px',
              fontFamily: 'Verdana, sans-serif',
            },
          },
        },
        series: [{
          cursor: 'pointer',
          colorByPoint: true,
          type: 'column',
          name: 'Responses',
          borderColor: false,
          data: this.chartData,
          showInLegend: false,
          events: {
            click: (event) => {
              const route = this.$router.resolve({ path: this.source, query: { score: event.point.category } });
              window.open(route.href, '_blank');
            },
          },
        }],
        plotOptions: {
          column: {
            dataLabels: {
              enabled: true,
              format: '{percentage}%',
            },
          },
        },
      });
    },
    drawTachometerChart() {
      Highcharts.chart('tachometer_chart', {
        chart: {
          type: 'gauge',
          plotBackgroundColor: null,
          plotBackgroundImage: null,
          plotBorderWidth: 0,
          plotShadow: false,
          height: '80%',
          marginBottom: '0',
        },
        title: {
          text: '',
        },
        pane: {
          startAngle: -100,
          endAngle: 100,
          background: null,
          size: '50%',
        },
        yAxis: {
          min: -100,
          max: 100,
          tickPixelInterval: 72,
          tickPosition: 'inside',
          tickColor: Highcharts.defaultOptions.chart.backgroundColor || '#FFFFFF',
          tickLength: 20,
          tickWidth: 2,
          minorTickInterval: null,
          labels: {
            distance: 30,
            style: {
              fontSize: '14px',
            },
          },
          plotBands: [{
            from: -100,
            to: 100,
            color: '#ea0000',
            thickness: 20,
          }, {
            from: 20,
            to: 70,
            color: '#ff8700',
            thickness: 20,
          }, {
            from: 70,
            to: 80,
            color: '#ffda00',
            thickness: 20,
          },
          {
            from: 80,
            to: 100,
            color: '#00ab32',
            thickness: 20,
          }],
        },
        series: [{
          name: 'NPS',
          data: [this.informationData.nps_percentage],
          dataLabels: {
            format: '{y}',
            borderWidth: 0,
            color: (
              Highcharts.defaultOptions.title
              && Highcharts.defaultOptions.title.style
              && Highcharts.defaultOptions.title.style.color
            ) || '#000',
            style: {
              fontSize: '16px',
            },
          },
          dial: {
            radius: '100%',
            backgroundColor: 'black',
            baseWidth: 12,
            baseLength: '0%',
            rearLength: '0%',
          },
          pivot: {
            backgroundColor: 'black',
            radius: 6,
          },
        }],
      });
    },
  },
};
</script>
