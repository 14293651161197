import { gql } from '@apollo/client/core';

export default gql`
  mutation($id: ID!) {
    refreshIndicator(id: $id) {
      id
      name
      frequency
      description
      last_updated_at
      most_recent_value
      details {
        data {
          id
          value
          created_at
        }
      }
    }
  }
`;

export const CREATE_ALARM = gql`
  mutation($alarm: IndicatorAlarmInput!){
    createAlarm(input: $alarm) {
      id
      threshold_type
      threshold_value
      notification_recipients
      indicator_id
    }
  }
`;

export const DELETE_ALARM = gql`
  mutation($id: ID!){
    deleteAlarm(id: $id) {
      id
    }
  }
`;

export const HANDLE_FAVORITES = gql`
  mutation($favorite: FavoritesInput!){
    handleFavorites(input: $favorite)
  }
`;
