import {
  S3Client, GetObjectCommand, DeleteObjectCommand,
} from '@aws-sdk/client-s3';
import { getSignedUrl } from '@aws-sdk/s3-request-presigner';
import { CognitoIdentityClient } from '@aws-sdk/client-cognito-identity';
import { fromCognitoIdentityPool } from '@aws-sdk/credential-provider-cognito-identity';
import { Upload } from '@aws-sdk/lib-storage';
import { BUCKET_NAME, BUCKET_REGION, IDENTITY_POOL_ID } from './env';

export default {
  install(app) {
    const client = new S3Client({
      region: BUCKET_REGION,
      credentials: fromCognitoIdentityPool({
        client: new CognitoIdentityClient({ region: BUCKET_REGION }),
        identityPoolId: IDENTITY_POOL_ID,
      }),
    });

    app.config.globalProperties.$s3Upload = async (params) => {
      params.Bucket = BUCKET_NAME;
      const uploader = new Upload({
        client,
        params,
      });
      return uploader.done();
    };

    app.config.globalProperties.$s3Delete = (params) => {
      params.Bucket = BUCKET_NAME;
      const command = new DeleteObjectCommand(params);
      return client.send(command);
    };

    app.config.globalProperties.$s3Location = (key) => `https://${BUCKET_NAME}.s3.${BUCKET_REGION}.amazonaws.com/${key}`;

    app.config.globalProperties.$s3GetSignedUrl = (key) => {
      const command = new GetObjectCommand({ Bucket: BUCKET_NAME, Key: key });
      return getSignedUrl(client, command, { expiresIn: 60 });
    };
  },
};
