import { gql } from '@apollo/client/core';

import NOTIFICATION_SETTING_FRAGMENT from './fragments';

export default gql`
  query($page: Int, $name: String) {
    notification_settings(
      page: $page,
      name: $name,
      orderBy: { column: "id", order: ASC }) @connection(key: "notification_settings", filter: ["name"]) {
      paginatorInfo {
        hasMorePages
      }
      data {
        ...notification_setting
      }
    }
  }
  ${NOTIFICATION_SETTING_FRAGMENT}
`;
