import { gql } from '@apollo/client/core';

export const GET_INTEGRATIONS = gql`
  query($page: Int,
        $name: String,
        $orderBy: [OrderByClause!]) {
    integrations(
      page: $page,
      name: $name,
      orderBy: $orderBy) {
      paginatorInfo {
        hasMorePages
      }
      data {
        id
        name
        user
        password
        is_active
      }
    }
  }
`;

export const GET_INTEGRATION = gql`
  query($identification: String) {
    integration(
      identification: $identification) {
        id
        name
        is_active
    }
  }
`;
