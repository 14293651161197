import { gql } from '@apollo/client/core';

export default gql`
  query($page: Int,
        $model: String,
        $integration_id: ID!,
        $orderBy: [OrderByClause!]) {
    integrationMappings(
      page: $page,
      model: $model,
      integration_id: $integration_id,
      orderBy: $orderBy) {
      paginatorInfo {
        hasMorePages
      }
      data {
        id
        integration_id
        model
        internal_id
        external_id
        name
      }
    }
  }
`;
