<template>
  <div>
    <Form v-if="selectedRecord" v-bind:record="selectedRecord" @close="selectedRecord = null" @insert="insert">
    </Form>
    <nav>
      <div class="breadcrumb p-2">
        <div class="row">
          <div class="col-auto ms-3 p-0">
            <SpinnerRouterLink :loading="$apollo.loading">Products</SpinnerRouterLink>
          </div>
        </div>
        <div class="container-fluid">
          <div class="row">
            <div class="col-12 col-sm-6">
              <Search v-model="search" :inputs="['name']"></Search>
            </div>
            <div class="col-12 col-sm-6 order-last text-end mt-2 pt-2">
              <button type="button" class="btn btn-company-primary" @click="selectedRecord = {'countries': []}"
                v-if="$can('CreateProduct')">
                <i class="fas fa-plus"></i>
                New
              </button>
            </div>
          </div>
        </div>
      </div>
    </nav>
    <div class="row" v-if="products">
      <Empty v-if="!products.data.length" icon="fa-solid fa-shop" :can_create="true" @callCreate="selectedRecord = {'countries': []}"/>
      <Card :key="index" v-for="(record, index) in products.data" :record="record" @remove="remove"></Card>
    </div>
    <SkeletonCards v-if="products && products.paginatorInfo.hasMorePages" :cards=4
      :executeScroll="products != undefined" @showMore="showMore('products')"></SkeletonCards>
  </div>
</template>

<script>

import PRODUCTS_QUERY from './queries';
import { DELETE_PRODUCT, CREATE_PRODUCT } from './mutations';
import RequestBuilder from './RequestBuilder';
import Form from './Form.vue';
import Card from './Card.vue';
import { Search, SkeletonCards, SpinnerRouterLink } from '../shared';
import { SearchBuilder, ErrorHandler, PaginationHandler } from '../../shared';

export default {
  extends: PaginationHandler,
  components: {
    Form, Card, Search, SkeletonCards, SpinnerRouterLink,
  },
  apollo: {
    products: {
      query: PRODUCTS_QUERY,
      errorPolicy: 'all',
      variables() {
        return { page: 1, ...SearchBuilder.build(this.search) };
      },
      error(error) {
        this.$toasted.error(error.message);
      },
    },
  },
  data() {
    return {
      search: {},
      selectedRecord: null,
    };
  },
  methods: {
    queryVariables() {
      return SearchBuilder.build(this.search);
    },
    remove(product) {
      this.$apollo.mutate({
        mutation: DELETE_PRODUCT,
        variables: {
          id: product.id,
        },
        update: (store, { data: { deleteProduct } }) => {
          const data = store.readQuery({ query: PRODUCTS_QUERY, variables: this.queryVariables() });
          const updatedProduct = data.products.data.filter((t) => t.id !== deleteProduct.id);
          store.modify({ fields: { products(existingProducts = { data: [] }) { return { ...existingProducts, data: updatedProduct }; } } });
          const keySplit = product.image_url.split('/').reverse();
          this.$s3Delete({ Key: `${keySplit[1]}/${keySplit[0]}` }).then(() => {});
        },
      }).catch((error) => {
        this.$toasted.error(`Unable to delete record: ${error.message}`);
      });
    },
    insert(product) {
      const fileName = `products/${Date.now()}_${product.image_url.name}`;
      // upload logo
      const params = { Key: fileName, Body: product.image_url };
      this.$s3Upload(params).then(() => {
        product.image_url = this.$s3Location(fileName);
        const newProduct = product;
        this.selectedRecord = null;
        this.$apollo.mutate({
          mutation: CREATE_PRODUCT,
          variables: {
            product: RequestBuilder.build(product),
          },
          update: (store, { data: { createProduct } }) => {
            const data = store.readQuery({ query: PRODUCTS_QUERY, variables: this.queryVariables() });
            store.modify({ fields: { products(existingProducts = { data: [] }) { return { ...existingProducts, data: [createProduct, ...data.products.data] }; } } });
          },
        }).catch((error) => {
          ErrorHandler.handle(error, this);
          // We restore the initial user input
          this.selectedRecord = newProduct;
        });
      });
    },
  },
};
</script>
