import { gql } from '@apollo/client/core';

import PROFILE_FRAGMENT from './fragments';

export const UPDATE_PROFILE = gql`
  mutation($user: ProfileInput!){
    updateProfile(input: $user){
      ...profile
    }
  }
  ${PROFILE_FRAGMENT}
`;

export const UPDATE_ANY_PROFILE = gql`
  mutation($user_id: ID!, $user: ProfileInput!){
    updateAnyProfile(id: $user_id, input: $user){
      ...profile
    }
  }
  ${PROFILE_FRAGMENT}
`;

export const UPDATE_PROJECT_MEMBER = gql`
  mutation($id: ID!, $project_member: ProjectResponsibilityInput!){
    updateProjectMember(id: $id, input: $project_member){
      id
    }
  }
`;
