import { gql } from '@apollo/client/core';

export default gql`
    fragment interviewQuestion on InterviewQuestion {
        id
        technology_id
        difficulty
        question
        answer
        tags {
          id
          name
        }
    }
`;
