<template>
  <tr id="skeleton">
    <td :colspan="colspan">
      <div class="skeleton">
        <div class="skeleton-rect"></div>
      </div>
    </td>
  </tr>
</template>
<script>

export default {
  props: ['colspan', 'executeScroll'],
  mounted() {
    this.scroll();
  },
  methods: {
    scroll() {
      const observer = new IntersectionObserver((entries) => {
        if (this.executeScroll && entries[0].isIntersecting === true) {
          this.$emit('showMore');
        }
      }, { threshold: [0.5] });
      observer.observe(document.querySelector('#skeleton'));
    },
  },
};
</script>

<style scoped>
/* Styles for the loading skeleton */
.skeleton {
  position: relative;
  margin-bottom: 15px;
  background-color: #f0f0f0;
  overflow: hidden;
  border-radius: 8px;
}

/* Styles for the loading "cards" */
.skeleton-rect {
  background-color: #e0e0e0;
  width: 100%;
  height: 50px; /* Fixed height to simulate a card */
  border-radius: 8px;
  animation: pulse 1.5s infinite ease-in-out;
}

/* Animation for the blinking effect */
@keyframes pulse {
  0% {
    background-color: #e0e0e0;
  }
  50% {
    background-color: #f4f4f4;
  }
  100% {
    background-color: #e0e0e0;
  }
}

/* Show only one card when the device is Small or Extra small */
@media (max-width: 767.98px) {
  .skeleton:not(:first-child) {
    display: none;
  }
}
</style>
