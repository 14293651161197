import { gql } from '@apollo/client/core';
import HOLIDAY_FRAGMENT from './fragments';

export default gql`
  query($page: Int,
        $name: String
        $country_id: ID,
        $user_id: ID,
        $year: Int,
        $month: Int,) {
    holidays(
      page: $page,
      name: $name,
      country_id: $country_id,
      user_id: $user_id,
      year: $year,
      month: $month,
      orderBy: { column: "date", order: ASC }
      ) @connection(key: "holidays", filter: ["name", "year", "country_id", "user_id", "month"]) {
      paginatorInfo {
        hasMorePages
      }
      data {
        ...holiday
      }
    }
  }
  ${HOLIDAY_FRAGMENT}
`;
