import { gql } from '@apollo/client/core';

import { LEARNING_PATH_LEVEL_FRAGMENT } from './fragments';

export const CREATE_LEARNING_PATH_LEVEL = gql`
  mutation($learning_path_level: LearningPathLevelInput!){
    createLearningPathLevel(input: $learning_path_level){
      ...learning_path_level
    }
  }
  ${LEARNING_PATH_LEVEL_FRAGMENT}
`;

export const UPDATE_LEARNING_PATH_LEVEL = gql`
  mutation($id: ID!, $learning_path_level: LearningPathLevelInput!){
    updateLearningPathLevel(id: $id, input: $learning_path_level){
      ...learning_path_level
    }
  }
  ${LEARNING_PATH_LEVEL_FRAGMENT}
`;

export const DELETE_LEARNING_PATH_LEVEL = gql`
  mutation($id: ID!){
    deleteLearningPathLevel(id: $id){
      id
    }
  }
`;

export const UPDATE_LEARNING_PATH_LEVELS_ORDER = gql`
  mutation($learning_path_levels: [LearningPathLevelsOrderInput!]!){
    updateLearningPathLevelsOrder(input: $learning_path_levels){
      id
      order
    }
  }
`;
