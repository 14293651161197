<template>
  <div class="row task-container flex-nowrap">
    <div class="input-group col-md-6 p-0 flex-fill">
      <span class="input-group-text grabbable text-default rounded-0">
        <i class="fas fa-circle" :style="{ 'color': localItem.deliverable ? localItem.deliverable.color : '#495057' }"></i>
      </span>
      <input v-if="isDuplicated" type="text" class="form-control rounded-0 w-25 text-danger" placeholder="Name" v-model="localItem.name" title="Duplicated Line" />
      <input v-if="!isDuplicated" type="text" class="form-control rounded-0 w-25" placeholder="Name" v-model="localItem.name" :title="localItem.name" />
    </div>
    <div class="input-group col-md-6 p-0 flex-fill">
      <input type="number" class="form-control rounded-0 col-md-3" placeholder="Hours" v-model="localItem.hours"/>
      <select v-model="localItem.deliverable" class="form-control rounded-0 form-select col-md-8">
        <option v-if="localItem.deliverable" :selected="true" :value="localItem.deliverable">
          {{localItem.deliverable.name}}
        </option>
        <option v-for="(deliverable, index) in filterDeliverables(localItem.deliverable)" :value="deliverable" :key="index">
          {{deliverable.name}}
        </option>
      </select>
      <span class="input-group-text sortable rounded-0">
        <input type="checkbox" class="form-check-input sortable" v-model="localItem.is_completed"/>
      </span>
      <span class="input-group-text sortable text-danger rounded-0" @click="$emit('deleteLine', localItem)">
        <i class="fas fa-times"></i>
      </span>
    </div>
  </div>
</template>
<script>
export default {
  props: ['bucket_estimation_item', 'isDuplicated', 'deliverables'],
  data() {
    return {
      localItem: _.cloneDeep(this.bucket_estimation_item),
    };
  },
  methods: {
    filterDeliverables(deliverable) {
      if (deliverable) {
        return this.deliverables.filter((item) => item.id !== deliverable.id);
      }
      return this.deliverables;
    },
  },
  created() {
    this.debouncedUpdate = _.debounce((newItem) => {
      this.$emit('updateItem', _.cloneDeep(newItem));
    }, 500);
  },
  watch: {
    bucket_estimation_item: {
      handler(newItem) {
        if (!_.isEqual(newItem, this.localItem)) {
          this.localItem = _.cloneDeep(newItem);
        }
      },
      deep: true,
    },
    localItem: {
      handler(newItem) {
        if (!_.isEqual(newItem, this.bucket_estimation_item)) {
          this.debouncedUpdate(newItem);
        }
      },
      deep: true,
    },
  },
};
</script>
