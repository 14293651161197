import { gql } from '@apollo/client/core';

import HOLIDAY_FRAGMENT from './fragments';

export const CREATE_HOLIDAY = gql`
  mutation($holiday: HolidayInput!) {
    createHoliday(input: $holiday) {
      ...holiday
    }
  }
  ${HOLIDAY_FRAGMENT}
`;

export const UPDATE_HOLIDAY = gql`
  mutation($id: ID!, $holiday: HolidayInput!) {
    updateHoliday(id: $id, input: $holiday) {
      ...holiday
    }
  }
  ${HOLIDAY_FRAGMENT}
`;

export const DELETE_HOLIDAY = gql`
  mutation($id: ID!) {
    deleteHoliday(id: $id) {
      id
    }
  }
`;
