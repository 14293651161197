import { gql } from '@apollo/client/core';

export default gql`
  query($user_id: ID,
        $project_id: ID,
        $only_my_projects: Boolean,
        $orderBy: [OrderByClause!]){
    grossMarginPerResource(user_id: $user_id,
                           project_id: $project_id,
                           only_my_projects: $only_my_projects,
                           orderBy: $orderBy){
      id
      project
      person
      cost
      hourly_rate
      profit
      gross_margin
      status
    }
  }
`;
