<template>
  <div>
    <Form v-if="record" v-bind:record="record"
                        :is_uploading="isUploading"
                        @close="record = null"
                        @insert="insert"></Form>
    <nav>
      <div class="breadcrumb p-2">
        <div class="col-md-4 m-auto p-0">
          <ol class="breadcrumb p-0 m-auto">
            <li class="breadcrumb-item p-0">
              <router-link to="/home">
                <i class="fas fa-home"></i>
              </router-link>
            </li>
            <li class="breadcrumb-item active fw-bold" aria-current="page">
              <router-link :to="entity.parent_url">{{entity.entity_type}}</router-link>
            </li>
            <li class="breadcrumb-item active fw-bold" aria-current="page">
              <router-link :to="entity.parent_url+`?name=${entityAttachments?.name}`">{{ entityAttachments?.name }}</router-link>
            </li>
            <li class="breadcrumb-item fw-bold" aria-current="page">
              Documents
            </li>
          </ol>
        </div>
        <div class="col-md-6 m-auto p-0">
          <Search
            v-model="search"
            :inputs="['name']"
          ></Search>
        </div>
        <div class="col-md-2 text-end">
          <button type="button" class="btn btn-company-primary" @click="newRisk()" v-if="$can(entity.permissions)">
            <i class="fas fa-plus"></i>
            New
          </button>
        </div>
      </div>
    </nav>
    <div class="row" v-if="entityAttachments">
      <Empty v-if="!entityAttachments.attachments.length" icon="fas fa-project-diagram"/>
      <Card :key="`a-${record.id}`" v-for="record in entityAttachments.attachments"
            :record="record"
            :entity_id="entity.entity_id"
            :entity_type="entity.entity_type"
            :read_only="!$can(entity.permissions)"
            @remove="remove"></Card>
    </div>
  </div>
</template>

<script>
import {
  PROJECT_ATTACHMENTS_QUERY, USER_ATTACHMENTS_QUERY, CLIENT_ATTACHMENTS_QUERY, PARTNER_ATTACHMENTS_QUERY,
} from './queries';
import UPSERT_ATTACHMENTS from './mutations';
import { Search } from '../shared';
import Form from './Form.vue';
import Card from './Card.vue';
import { SearchBuilder, ErrorHandler, PaginationHandler } from '../../shared';

export default {
  extends: PaginationHandler,
  components: { Form, Card, Search },
  computed: {
    entity() {
      const entityTypes = {
        project_documents: {
          model: 'Project',
          parent_url: '/projects',
          query: PROJECT_ATTACHMENTS_QUERY,
          permissions: 'AdminProjectAttachments',
        },
        user_documents: {
          model: 'User',
          parent_url: '/users',
          query: USER_ATTACHMENTS_QUERY,
          permissions: 'AdminUserAttachments',
        },
        partner_documents: {
          model: 'Partner',
          parent_url: '/partners',
          query: PARTNER_ATTACHMENTS_QUERY,
          permissions: 'AdminPartnerAttachments',
        },
        client_documents: {
          model: 'Client',
          parent_url: '/clients',
          query: CLIENT_ATTACHMENTS_QUERY,
          permissions: 'AdminClientAttachments',
        },
      };
      return {
        entity_id: this.$route.params.id,
        entity_type: entityTypes[this.$route.name].model,
        parent_url: entityTypes[this.$route.name].parent_url,
        permissions: entityTypes[this.$route.name].permissions,
        query: entityTypes[this.$route.name].query,
      };
    },
  },
  apollo: {
    entityAttachments: {
      query() {
        return this.entity.query;
      },
      errorPolicy: 'all',
      variables() {
        return { page: 1, entity_id: parseInt(this.$route.params.id, 10), ...SearchBuilder.build(this.search) };
      },
      error(error) {
        this.$toasted.error(error.message);
      },
    },
  },
  data() {
    return {
      search: {},
      record: null,
      isUploading: false,
    };
  },
  methods: {
    queryVariables() {
      return { project_id: parseInt(this.$route.params.id, 10), ...SearchBuilder.build(this.search) };
    },
    remove(record) {
      this.$apollo.mutate({
        mutation: UPSERT_ATTACHMENTS,
        variables: {
          upsertAttachment: {
            entity_id: this.entity.entity_id,
            entity_type: this.entity.entity_type,
            attachments: { delete: [record.id] },
          },
        },
      }).catch((error) => {
        this.$toasted.error(`Unable to delete record: ${error.message}`);
      }).then(() => {
        this.entityAttachments = {
          ...this.entityAttachments,
          attachments: this.entityAttachments.attachments.filter((a) => a.id !== record.id),
        };
      });
    },
    newRisk() {
      this.record = { };
    },
    insert(record) {
      const newRecord = record;
      const fileName = `attachments/${Date.now()}_${record.file.name}`;
      this.isUploading = true;
      const params = { Key: fileName, Body: record.file };
      this.$s3Upload(params).then(() => {
        record.url = this.$s3Location(fileName);
        record.type = record.file.type;
        this.$apollo.mutate({
          mutation: UPSERT_ATTACHMENTS,
          variables: {
            upsertAttachment: {
              entity_id: this.entity.entity_id,
              entity_type: this.entity.entity_type,
              attachments: {
                upsert: [
                  { ..._.pick(record, ['name', 'type', 'url', 'expires_at']) },
                ],
              },
            },
          },
        }).then((mutationResponse) => {
          this.record = null;
          this.entityAttachments = {
            ...this.entityAttachments,
            attachments: [...this.entityAttachments.attachments, mutationResponse.data.UpsertAttachments[0]],
          };
          this.isUploading = false;
        }).catch((error) => {
          ErrorHandler.handle(error, this);
          // We restore the initial user input
          this.record = newRecord;
          this.isUploading = false;
        });
      });
    },
  },
};
</script>
