import { computed, ref } from 'vue';
import WeekManager from './WeekManager';

export default class {
  constructor() {
    this.records = ref([]);
    this.savedHours = computed(() => _.sumBy(this.records.value, (o) => o.savedHours));
    this.draftHours = computed(() => _.sumBy(this.records.value, (o) => o.draftHours));
    this.isLoading = false;
  }

  next(records) {
    this.records = records.map((record) => {
      record.isSaving = false;
      record.savedHours = _.sumBy(record.logs, (o) => o.hours);
      record.logs = ref(record.logs || []);

      record.draftHours = computed(() => {
        if (record.has_weekends) return _.sumBy(record.logs.value, (o) => o.hours);
        const logs = record.logs.value.filter((o) => !WeekManager.isWeekend(o.created_at));
        return _.sumBy(logs, (o) => o.hours);
      });
      return record;
    });
  }

  update(index, logs) {
    this.records[index].logs = logs;
    this.records[index].savedHours = _.sumBy(logs, (o) => o.hours);
  }
}
