<template>
<table class="table">
  <thead>
    <tr>
      <th class="d-table-cell sortable" @click="sortBy('project_id')">
        <i v-if="orderBy.column === 'project_id'" class="bg-transparent fas" :class="sortIcon"></i>
        Project
      </th>
      <th class="d-table-cell sortable" @click="sortBy('user_id')">
        <i v-if="orderBy.column === 'user_id'" class="bg-transparent fas" :class="sortIcon"></i>
        Person
      </th>
      <th class="d-table-cell">Activities</th>
      <th class="d-table-cell sortable" @click="sortBy('hours')">
        <i v-if="orderBy.column === 'hours'" class="bg-transparent fas" :class="sortIcon"></i>
        Hours
      </th>
      <th class="d-table-cell sortable" v-if="showCosts" :title="costsMessage()" @click="sortBy('costs')">
        <i v-if="orderBy.column === 'costs'" class="bg-transparent fas" :class="sortIcon"></i>
        Costs *
      </th>
    </tr>
  </thead>
  <tbody>
    <template v-for="day in days" :key="`d${day}`">
      <tr>
        <th colspan="3" class="bg-company-primary d-table-cell">{{day}}</th>
        <th class="bg-company-primary d-table-cell">{{totalBy('date', day, 'hours')}}</th>
        <th v-if="showCosts" class="bg-company-primary d-table-cell">${{ $filters.formatNumber(totalBy('date', day, 'costs').toFixed(2))}}</th>
      </tr>
      <tr v-for="hour in filterBy('date', day)" :key="hour.id">
        <td>{{!!hour.project ? hour.project.name :'Internal hours'}}</td>
        <td>{{hour.user.name}}</td>
        <td class="text-wrap" v-html="hour.activities" v-if="hour.activities"></td>
        <td v-else>-</td>
        <td>{{hour.hours}}</td>
        <td v-if="showCosts">${{ $filters.formatNumber(hour.costs)}}</td>
      </tr>
    </template>
    <tr v-if="days && days.length === 0">
      <td class="text-center" :colspan="showCosts ? 6 : 5">No records found</td>
    </tr>
    <SkeletonRow v-if="hours && hours.paginatorInfo.hasMorePages" :colspan="showCosts ? 6 : 5" :executeScroll="hours != undefined" @showMore="showMore"></SkeletonRow>
    <tr v-if="days && days.length > 0">
      <td class="text-end" colspan="3">
        <strong>Total</strong>
      </td>
      <td><strong>{{total}}</strong></td>
      <td v-if="showCosts"><strong>${{ $filters.formatNumber(totalCosts)}}</strong></td>
    </tr>
  </tbody>
</table>
</template>

<script>

import Helper from '../Helper';

export default {
  extends: Helper,
  props: ['hours', 'holidays', 'orderBy', 'show_costs'],
  methods: {
    totalBy(key, value, sumKey) {
      return _.sumBy(
        _.filter(this.hours.data, (h) => this.$displayDate(h[key]) === value),
        sumKey,
      );
    },
  },
};
</script>
