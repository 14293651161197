import { gql } from '@apollo/client/core';

export default gql`
mutation($start_date: DateTime, $end_date: DateTime, $user_id: ID) {
    exportVacationHistoryToExcel(
        start_date: $start_date,
        end_date: $end_date,
        user_id: $user_id
  )
}
`;
