import { gql } from '@apollo/client/core';

export default gql`
  query($page: Int,
        $name: String,
        $is_bot: Boolean,
        $orderBy: [OrderByClause!]) {
    bots(
      page: $page,
      name: $name,
      is_bot: $is_bot,
      orderBy: $orderBy) {
      paginatorInfo {
        hasMorePages
      }
      data {
        id
        name
        is_bot
        api_token
        permissions{
          id
          name
        }
      }
    }
  }
`;
