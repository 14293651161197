import { gql } from '@apollo/client/core';

import LEARNING_PATH_LEVEL_NODE_USER_FRAGMENT from './fragments';

export default gql`
  mutation($learning_path_node_user: LearningPathEvaluationInput!){
    evaluateLearningPath(input: $learning_path_node_user){
      ...learning_path_level_node_user
    }
  }
  ${LEARNING_PATH_LEVEL_NODE_USER_FRAGMENT}
`;
