import { gql } from '@apollo/client/core';

export default gql`
  query($start_date: DateTime!, $end_date: DateTime!, $orderBy: [OrderByClause!]) {
    me {
      id
      bucket_estimation_hours(start_date: $start_date, end_date: $end_date, orderBy: $orderBy) {
        id
        hours
        date: created_at
        activities
        project_id
        project {
          id
          name
        }
      }
    }
  }
`;
