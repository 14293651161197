<template>
  <div :id="record.id" class="col-md-4 col-sm-12 pb-2">
    <Form
      v-if="is_editting"
      v-bind:record="record"
      @close="is_editting = false"
      @update="update"
    ></Form>
    <ProjectForm
      v-if="new_project"
      v-model:project="new_project"
      @close="new_project = null"
      @insert="createProject"
      @insert-redirect="createProjectAndRedirect"
    ></ProjectForm>
    <div v-if="canShow" class="card">
      <div class="card-body pb-0">
        <div class="float-start">
          <p class="hours style-chooser w-100 h-100 text-body-secondary text-uppercase d-flex flex-column justify-content-center align-items-center border-end pe-2">
            <span class="fs-5 lh-1 link-success">{{ total_hours }}</span>
            hours
          </p>
        </div>
        <div class="container">
          <div class="row">
            <div class="col-12">
              <h5 class="card-title">{{ record.name }}</h5>
              <h6 class="card-subtitle mb-2">{{ record.client.name }}</h6>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body card-row pt-0 pb-0" v-if="record.bucket_estimations.length > 0">
        <div class="row">
          <div class="col-md-12">
            <strong>Buckets:</strong>
          </div>
          <div :key="bucket_estimation.id" class="col-md-12 pb-1" v-for="bucket_estimation in sorted_bucket_estimations">
            <div class="progress" role="progressbar" aria-label="Percentage" :aria-valuenow="percentage(bucket_estimation)" aria-valuemin="0" aria-valuemax="100">
              <div class="progress-bar text-start ps-2 bg-company-primary"
                    :class="percentage(bucket_estimation) == 0 ? 'text-dark bg-transparent w-100' : ''"
                    :style="{ width: percentage(bucket_estimation) + '%'}">
                {{ bucket_estimation.bucket.name }} ({{bucket_estimation.total_hours}}) hours
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body card-row pt-0 pb-0" v-if="record.technologies.length > 0">
        <div class="row">
          <div class="col-md-12">
            <strong>Technologies:</strong>
          </div>
          <div class="col-md-12">
            <span
              :key="technology.id"
              v-for="technology in record.technologies"
              class="badge bg-company-third-ligth me-1 ms-1">
              {{ technology.name }}
            </span>
          </div>
        </div>
      </div>
      <div class="card-body card-row pt-0 pb-0" v-if="total_hours > 0">
        <div class="row">
          <div class="col-md-12">
            <strong>Billable hours:</strong>
          </div>
        </div>
        <div class="row">
          <div class="col-md-9">
            <span
              class="badge bg-company-third-ligth me-1 ms-1">
              {{ total_hours }}
            </span>
          </div>
        </div>
      </div>
      <div class="card-body card-row pt-0 pb-0" v-if="total_hours > 0">
        <div class="row">
          <div class="col-md-12">
            <strong>Time to finish:</strong>
          </div>
          <div class="col-md-9">
            <span
              class="badge bg-company-third-ligth me-1 ms-1">
              {{ effective_hours }} ({{ detailTime(effective_hours) }})
            </span>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <div class="ms-1">
          <button
            v-if="!confirmation && $can('UpdateEstimation')"
            class="btn btn-company-primary m-1"
            @click="is_editting = true">
              <i class="fas fa-pen"></i>
              Edit
          </button>
          <Confirmation v-if="$can('DeleteEstimation')"
            v-model="confirmation"
            @accepted="remove()"
          />
          <button
            v-if="!confirmation && $can('CopyEstimation')"
            class="btn btn-company-primary m-1"
            @click="copy()"
          >
            <i v-if="is_copying" class="fas fa-sync fa-spin"></i>
            <i v-if="!is_copying" class="fas fa-clone"></i>
            Copy
          </button>
          <button
            v-if="!confirmation && $can('ExportEstimationToExcel')"
            class="btn btn-company-primary m-1"
            @click="exportFile()"
          >
            <i v-if="is_exporting" class="fas fa-sync fa-spin"></i>
            <i v-if="!is_exporting" class="fa fa-file-excel"></i>
            Export
          </button>
          <router-link
            class="btn btn-company-primary m-1"
            v-if="!confirmation"
            :to="{
                name: 'estimation_detail',
                params: { id: record.id }
            }">
            <i class="fas fa-list"></i>
            Details
          </router-link>
          <button
            v-if="canCreateProject && !confirmation"
            class="btn btn-company-primary m-1"
            @click="newRecord()"
            title="Turn into project"
          >
            <i class="fas fa-plus"></i>
            Project
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {
  UPDATE_ESTIMATION,
  COPY_ESTIMATION,
  EXPORT_ESTIMATION,
} from './mutations';
import { CREATE_PROJECT } from '../projects/mutations';
import Form from './Form.vue';
import ProjectForm from '../projects/MinimalForm.vue';
import RequestBuilder from './RequestBuilder';
import ProjectRequestBuilder from '../projects/RequestBuilder';
import { ErrorHandler, Base64File } from '../../shared';
import { Confirmation } from '../shared';

export default {
  components: { Form, ProjectForm, Confirmation },
  props: ['record'],
  data() {
    return {
      is_editting: false,
      is_copying: false,
      is_exporting: false,
      is_removing: false,
      confirmation: false,
      hour_equivalences: {
        months: 160,
        weeks: 40,
        days: 8,
        hours: 1,
      },
      new_project: null,
    };
  },
  computed: {
    total_hours() {
      return _.sum(this.hours_array());
    },
    development_hours() {
      return _.sumBy(
        this.record.bucket_estimations.filter(
          (bucketEstimation) => !bucketEstimation.bucket.is_parallel,
        ),
        'total_hours',
      );
    },
    parallel_hours_array() {
      return _.map(
        this.record.bucket_estimations.filter(
          (bucketEstimation) => bucketEstimation.bucket.is_parallel,
        ),
        'total_hours',
      );
    },
    effective_hours() {
      const developmentHours = this.development_hours;
      const maxParallelHours = Math.max(...this.parallel_hours_array);
      if (maxParallelHours > developmentHours) {
        return developmentHours + (maxParallelHours - developmentHours);
      }
      return developmentHours;
    },
    sorted_bucket_estimations() {
      return _.sortBy(this.record.bucket_estimations, 'total_hours');
    },
    max_hours() {
      if (this.record.bucket_estimations.length === 0) {
        return 0;
      }
      return Math.max(...this.hours_array());
    },
    canShow() {
      return !this.is_editting && !this.new_project;
    },
    canCreateProject() {
      const hasProjects = this.record.projects && (this.record.projects || []).length > 0;
      return this.$can('CreateProject') && !hasProjects;
    },
  },
  methods: {
    hours_array() {
      return _.map(this.record.bucket_estimations, 'total_hours');
    },
    percentage(bucketEstimation) {
      return (bucketEstimation.total_hours * 100) / this.max_hours;
    },
    update(record) {
      this.$apollo.mutate({
        mutation: UPDATE_ESTIMATION,
        variables: {
          id: record.id,
          estimation: RequestBuilder.build(record),
        },
      }).then(() => {
        this.is_editting = false;
      }).catch((error) => {
        ErrorHandler.handle(error, this);
      });
    },
    copy() {
      this.is_copying = true;
      this.$apollo.mutate({
        mutation: COPY_ESTIMATION,
        variables: {
          id: this.record.id,
        },
      }).finally(() => {
        this.is_copying = false;
        this.$emit('refresh');
      });
    },
    exportFile() {
      this.is_exporting = true;
      this.$apollo.mutate({
        mutation: EXPORT_ESTIMATION,
        variables: {
          id: this.record.id,
        },
      }).then((res) => {
        Base64File.download(res.data.exportEstimationToExcel, `estimation-${this.record.name}.xlsx`);
      }).finally(() => {
        this.is_exporting = false;
      });
    },
    remove() {
      this.$emit('remove', this.record);
      this.is_removing = false;
      this.confirmation = false;
    },
    newRecord() {
      this.new_project = {
        name: this.record.name,
        budget: 0,
        description: `${this.record.name} project`,
        client_id: this.record.client_id,
        estimation_id: this.record.id,
        status: 'Green',
        type: 'Fixed Bid',
        state: 'In Progress',
        is_internal: false,
        is_confidential: false,
        technologies: this.record.technologies || [],
        members: [],
        vertical_markets: [],
        tags: [],
        contacts: [],
      };
    },
    createProject(project) {
      // We save the user input in case of an error
      const backup = project;
      this.new_project = null;
      this.$apollo
        .mutate({
          mutation: CREATE_PROJECT,
          variables: {
            project: ProjectRequestBuilder.build(project),
          },
        })
        .then((response) => {
          // Forces the UI to hide the 'turn into project' button without calling refresh
          const updatedRecord = {
            ...this.record,
            projects: [response.data.createProject],
          };
          this.$emit('update:record', updatedRecord);
        })
        .catch((error) => {
          ErrorHandler.handle(error, this);
          // We restore the initial user input
          this.new_project = backup;
        });
    },
    createProjectAndRedirect(project) {
      // We save the user input in case of an error
      const backup = project;
      this.new_project = null;
      this.$apollo
        .mutate({
          mutation: CREATE_PROJECT,
          variables: {
            project: ProjectRequestBuilder.build(project),
          },
        })
        .then(() => {
          this.$router.push({
            path: 'projects',
            query: {
              name: project.name,
              state: project.state,
            },
          });
        })
        .catch((error) => {
          ErrorHandler.handle(error, this);
          // We restore the initial user input
          this.new_project = backup;
        });
    },
    detailTime(hours) {
      const keys = ['months', 'weeks', 'days', 'hours'];
      const detail = [];
      keys.forEach((key) => {
        const t = Math.floor(hours / this.hour_equivalences[key]);
        if (t > 0) {
          detail.push(`${t} ${t === 1 ? key.replace('s', '') : key}`);
        }
        hours -= t * this.hour_equivalences[key];
      });
      return detail.join(', ');
    },
  },
};
</script>
