import { gql } from '@apollo/client/core';

export default gql`
  query($user_id: ID, $only_my_projects: Boolean){
    anniversaries(only_my_projects: $only_my_projects, user_id: $user_id){
      name
      hiring_date
      years_of_service
      next_work_aniversary
    }
  }
`;
