import { gql } from '@apollo/client/core';

import BADGE_FRAGMENT from './fragments';

export const CREATE_BADGE = gql`
  mutation($badge: BadgeInput!){
    createBadge(input: $badge){
      ...badge
    }
  }
  ${BADGE_FRAGMENT}
`;

export const UPDATE_BADGE = gql`
  mutation($id: ID!, $badge: BadgeInput!){
    updateBadge(id: $id, input: $badge){
      ...badge
    }
  }
  ${BADGE_FRAGMENT}

`;

export const DELETE_BADGE = gql`
  mutation($id: ID!){
    deleteBadge(id: $id){
      id
    }
  }
`;
