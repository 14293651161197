<style scoped>
.task-container {
  margin-right: -16px;
  margin-left: -16px;
}
</style>
<template>
  <div class="col-md-6 col-sm-12 pb-2">
    <ul class="list-group">
      <li class="list-group-item bg-company-primary">
        {{bucket_estimation.bucket.name}}
        <span v-if="bucket_estimation.bucket.is_parallel" title="Parallel work">
           <i class="fas fa-people-arrows"></i>
        </span>
        <span>
          ({{progress}}% Completed)
        </span>
        <span class="float-end">{{total}}</span>
        <span title="Move Right" class="float-end sortable me-3" v-if="order < max" @click="$emit('changeOrder', order + 1, order)">
          <i class="fas fa-arrow-right"></i>
        </span>
        <span title="Move Left" class="float-end sortable me-3" v-if="order > 1" @click="$emit('changeOrder', order - 1, order)">
          <i class="fas fa-arrow-left"></i>
        </span>
      </li>
      <template v-if="deliverables.length">
        <VueDraggable v-model="sortedTasks" @end="updateOrder">
          <li class="list-group-item pb-0 pt-0 border-0" v-for="bucket_estimation_item in bucket_estimation_items" :key="bucket_estimation_item.id">
            <TaskLineWithDeliverable :bucket_estimation_item="bucket_estimation_item"
                                     :isDuplicated="isLineDuplicated(bucket_estimation_item.name)"
                                     :deliverables="deliverables"
                                     @deleteLine="deleteLine"
                                     @updateItem="updateItem">
            </TaskLineWithDeliverable>
          </li>
        </VueDraggable>
        <li class="list-group-item pb-0 pt-0 border-0">
          <div class="row task-container flex-nowrap">
            <div class="input-group col-md-6 p-0 flex-fill">
              <span class="input-group-text text-default rounded-0">
                <i class="fas fa-circle"></i>
              </span>
              <input ref="name" v-focus type="text" class="form-control rounded-0 w-25" placeholder="Name" v-model="newTask.name" @change="addLine()"/>
            </div>
            <div class="input-group col-md-6 p-0 flex-fill">
              <input type="number" class="form-control rounded-0 col-3" placeholder="Hours" v-model="newTask.hours" @change="addLine()"/>
              <select v-model="newTask.deliverable" class="form-control rounded-0 form-select col-9">
                <option :key="deliverable.id" v-for="deliverable in deliverables" :value="deliverable">{{deliverable.name}}</option>
              </select>
              <span class="input-group-text"><input disabled="true" class="form-check-input opacity-0"/></span>
              <span class="input-group-text"><i class="fas fa-times opacity-0"></i></span>
            </div>
          </div>
        </li>
      </template>
      <template v-else>
        <VueDraggable v-model="sortedTasks" @end="updateOrder" :move="true">
          <li class="list-group-item pb-0 pt-0 border-0" v-for="bucket_estimation_item in bucket_estimation_items" :key="bucket_estimation_item.id">
            <TaskLine :bucket_estimation_item="bucket_estimation_item"
                      :isDuplicated="isLineDuplicated(bucket_estimation_item.name)"
                      @deleteLine="deleteLine"
                      @updateItem="updateItem">
            </TaskLine>
          </li>
        </VueDraggable>
        <li class="list-group-item pb-0 pt-0 border-0">
          <div class="row task-container flex-nowrap">
            <div class="input-group col-md-6 p-0 flex-fill">
              <span class="input-group-text text-default rounded-0">
                <i class="fas fa-arrows-alt-v"></i>
              </span>
              <input ref="name" type="text" class="form-control rounded-0 col-md-8" placeholder="Name" v-model="newTask.name" @change="addLine()"/>
            </div>
            <div class="input-group col-md-6 p-0 flex-fill">
              <input type="number" class="form-control rounded-0 col-md-4" placeholder="Hours" v-model="newTask.hours" @change="addLine()"/>
            </div>
          </div>
        </li>
      </template>
      <li class="list-group-item d-grid p-0">
        <button :disabled="loading || hasDuplicatedLines" class="btn btn-company-secondary rounded-0" @click="save()">
          <i v-if="loading" class="fas fa-sync fa-spin"></i>
          <i v-if="!loading" class="fas fa-save"></i>
          Save
        </button>
      </li>
    </ul>
  </div>
</template>
<script>
import { VueDraggable } from 'vue-draggable-plus';
import { UPDATE_BUCKET_ESTIMATION } from '../mutations';
import RequestBuilder from '../RequestBuilder';
import ErrorHandler from '../../../shared/ErrorHandler';
import TaskLine from './TaskLine.vue';
import TaskLineWithDeliverable from './TaskLineWithDeliverable.vue';

export default {
  components: {
    VueDraggable, TaskLine, TaskLineWithDeliverable,
  },
  computed: {
    hasDuplicatedLines() {
      const tasks = _.map(this.bucket_estimation.bucket_estimation_items.filter((item) => item.name !== ''), 'name');
      return tasks.length !== _.uniq(tasks).length;
    },
    total() {
      return _.sumBy(this.bucket_estimation_items, (item) => parseInt(item.hours, 10) || 0);
    },
    progress() {
      const completedTime = _.sumBy(_.filter(this.bucket_estimation_items, 'is_completed'), 'hours');
      const remainingTime = _.sumBy(_.filter(this.bucket_estimation_items, (i) => !i.is_completed), 'hours');
      if (completedTime !== 0) {
        return ((completedTime * 100) / (completedTime + remainingTime)).toFixed(2);
      }
      return 0;
    },
    bucket_estimation_items() {
      return this.sortedTasks.length > 0
        ? [...this.sortedTasks]
        : _.sortBy([...this.bucket_estimation.bucket_estimation_items], 'order');
    },
  },
  data() {
    return {
      loading: false,
      sortedTasks: _.sortBy(this.bucket_estimation.bucket_estimation_items, 'order'),
      newTask: {
        name: '',
        hours: 0,
        is_completed: false,
        order: this.bucket_estimation.bucket_estimation_items.length,
        deliverable: _.head(_.sortBy(this.deliverables, 'id')),
      },
    };
  },
  props: ['bucket_estimation', 'id', 'deliverables', 'order', 'max'],
  methods: {
    isLineDuplicated(name) {
      return _.filter(this.bucket_estimation.bucket_estimation_items, { name }).length > 1;
    },
    addLine() {
      if (this.newTask.name && this.newTask.hours) {
        const newTaskItem = { ...this.newTask, order: this.bucket_estimation.bucket_estimation_items.length };
        this.bucket_estimation.bucket_estimation_items = [...this.bucket_estimation.bucket_estimation_items, newTaskItem];
        this.sortedTasks = [...this.sortedTasks, newTaskItem];
        this.newTask = {
          name: '',
          hours: 0,
          is_completed: false,
          order: this.bucket_estimation.bucket_estimation_items.length,
          deliverable: _.head(_.sortBy(this.deliverables, 'id')),
        };
        this.$refs.name.focus();
      }
    },
    deleteLine(bucketEstimationItem) {
      this.bucket_estimation.bucket_estimation_items = this.bucket_estimation.bucket_estimation_items.map((item) => {
        if (item.id === bucketEstimationItem.id) {
          return { ...item, name: '', hours: 0 };
        }
        return item;
      });
      this.sortedTasks = [...this.bucket_estimation.bucket_estimation_items.filter((item) => item.name !== '')];
    },
    save() {
      this.loading = true;
      this.$apollo.mutate({
        mutation: UPDATE_BUCKET_ESTIMATION,
        variables: {
          id: this.bucket_estimation.id,
          bucket_estimation: RequestBuilder.buildBucketEstimation(
            this.id,
            this.bucket_estimation,
          ),
        },
      }).then((resp) => {
        const updatedItems = [...resp.data.updateBucketEstimation.bucket_estimation_items];
        if (!_.isEqual(this.bucket_estimation.bucket_estimation_items, updatedItems)) {
          this.bucket_estimation.bucket_estimation_items = updatedItems;
        }

        this.$emit('refresh');
        this.loading = false;
      }).catch((error) => {
        ErrorHandler.handle(error, this);
        this.loading = false;
      });
    },
    filterDeliverables(deliverable) {
      if (deliverable) {
        return this.deliverables.filter((item) => item.id !== deliverable.id);
      }
      return this.deliverables;
    },
    updateOrder() {
      const updatedTasks = this.sortedTasks.map((task, index) => ({
        ...task,
        order: index,
      }));

      if (!_.isEqual(this.bucket_estimation.bucket_estimation_items, updatedTasks)) {
        this.bucket_estimation.bucket_estimation_items = updatedTasks;
      }
    },
    updateItem(updatedItem) {
      const index = this.bucket_estimation.bucket_estimation_items.findIndex((item) => item.id === updatedItem.id);
      if (index !== -1) {
        const updatedItems = [...this.bucket_estimation.bucket_estimation_items];
        updatedItems[index] = { ...updatedItem };
        this.bucket_estimation.bucket_estimation_items = updatedItems;
      }
    },
  },
};
</script>
