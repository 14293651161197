import { gql } from '@apollo/client/core';

import PROJECT_FRAGMENT from './fragments';

export const CREATE_PROJECT = gql`
  mutation($project: ProjectInput!){
    createProject(input: $project){
      ...project
    }
  }
  ${PROJECT_FRAGMENT}
`;

export const UPDATE_PROJECT = gql`
  mutation($id: ID!, $project: ProjectInput!){
    updateProject(id: $id, input: $project){
      ...project
    }
  }
  ${PROJECT_FRAGMENT}
`;

export const DELETE_PROJECT = gql`
  mutation($id: ID!){
    deleteProject(id: $id){
      id
    }
  }
`;

export const SAVE_MY_HOURS = gql`
  mutation($project_id: ID!, $overwrite: Boolean!, $start_date: Date!, $end_date: Date!, $items: [saveMyHoursInput]){
    saveMyHours(project_id: $project_id, overwrite: $overwrite, start_date: $start_date, end_date: $end_date, items: $items)
  }
`;
