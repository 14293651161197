<template>
<div :id="record.id" class="col-md-2 pb-2">
  <Form v-if="is_editting" v-bind:record="record"
                            @close="is_editting = false"
                            @update="update"></Form>
  <div v-if="!is_editting" class="card h-100">
    <div class="card-body pb-0">
      <h5 class="card-title">
        {{record.name}}
      </h5>
    </div>
    <div class="card-body">
      <button v-if="!confirmation && $can('UpdateProjectRole')" class="btn btn-company-primary" @click="is_editting = true">
        <i class="fas fa-pen"></i>
        Edit
      </button>
      <Confirmation v-if="$can('DeleteProjectRole')"
        v-model="confirmation"
        @accepted="remove()"
      />
    </div>
  </div>
</div>
</template>

<script>
import PROJECT_ROLES_QUERY from './queries';
import { UPDATE_PROJECT_ROLE } from './mutations';
import Form from './Form.vue';
import { Confirmation } from '../shared';
import SearchBuilder from '../../shared/SearchBuilder';

export default {
  components: { Form, Confirmation },
  props: ['record'],
  data() {
    return {
      is_editting: false,
      confirmation: false,
    };
  },
  methods: {
    update(projectRole) {
      this.$apollo.mutate({
        mutation: UPDATE_PROJECT_ROLE,
        variables: {
          id: projectRole.id,
          project_role: _.pick(projectRole, ['name']),
        },
        update: (cache, { data: { updateProjectRole } }) => {
          const variables = { page: 1, ...SearchBuilder.build(this.$parent.search) };
          const data = cache.readQuery({ query: PROJECT_ROLES_QUERY, variables });
          const updatedRecords = data.project_roles.data.map((record) => (record.id === updateProjectRole.id ? updateProjectRole : record));
          const newData = {
            ...data,
            project_roles: {
              ...data.project_roles,
              data: updatedRecords,
            },
          };
          cache.writeQuery({ query: PROJECT_ROLES_QUERY, data: newData, variables });
        },
      }).catch((error) => {
        this.$toasted.error(`Unable to save record: ${error.message}`);
      }).then(() => {
        this.is_editting = false;
      });
    },
    remove() {
      this.$emit('remove', this.record);
      this.is_editting = false;
      this.confirmation = false;
    },
  },
};
</script>
