import { useVuelidate } from '@vuelidate/core';
import { required, maxLength } from '@vuelidate/validators';

export default {
  data() {
    return {
      v$: useVuelidate(), // Inicialización de vuelidate
    };
  },
  validations: {
    localRecord: {
      name: { required, maxLengthValue: maxLength(255) },
      documentation: { maxLengthValue: maxLength(255) },
    },
  },
};
