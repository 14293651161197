import { gql } from '@apollo/client/core';

import PERFORMANCE_EVALUATION_FRAGMENT from './fragments';

export const CREATE_MY_PERFORMANCE_EVALUATION = gql`
  mutation($include_forms: Boolean!, $id: ID, $performance_evaluation: MyPerformanceEvaluationInput!){
    createPerformanceEvaluation: createMyPerformanceEvaluation(input: $performance_evaluation){
      ...my_performance_evaluation
    }
  }
  ${PERFORMANCE_EVALUATION_FRAGMENT}
`;

export const CREATE_PERFORMANCE_EVALUATION = gql`
  mutation($include_forms: Boolean!, $id: ID, $performance_evaluation: PerformanceEvaluationInput!){
    createPerformanceEvaluation(input: $performance_evaluation){
      ...performance_evaluation
    }
  }
  ${PERFORMANCE_EVALUATION_FRAGMENT}
`;

export const UPDATE_PERFORMANCE_EVALUATION = gql`
  mutation($include_forms: Boolean!, $id: ID!, $performance_evaluation: PerformanceEvaluationInput!){
    updatePerformanceEvaluation(id: $id, input: $performance_evaluation){
      ...performance_evaluation
    }
  }
  ${PERFORMANCE_EVALUATION_FRAGMENT}
`;

export const DELETE_PERFORMANCE_EVALUATION = gql`
  mutation($id: ID!){
    deletePerformanceEvaluation(id: $id){
      id
    }
  }
`;
