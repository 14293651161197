<template>
  <div>
    <Form v-if="selectedRecord"
      v-bind:record="selectedRecord"
      @close="selectedRecord = null"
      @insert="insert"
    ></Form>
    <nav>
      <div class="breadcrumb p-2">
        <div class="row">
          <div class="col-auto ms-3 p-0">
            <SpinnerRouterLink :loading="$apollo.loading">Job Titles</SpinnerRouterLink>
          </div>
        </div>
        <div class="container-fluid">
          <div class="row">
            <div class="col-12 col-sm-6">
              <Search
                v-model="search"
                :inputs="['internalDescription']"
              ></Search>
            </div>
            <div class="col-12 col-sm-6 order-last text-end mt-2 pt-2">
              <button type="button" class="btn  btn-company-primary"
                @click="selectedRecord = {}" v-if="$can('CreateJobTitle')">
                <i class="fas fa-plus"></i>
                New
              </button>
            </div>
          </div>
        </div>
      </div>
    </nav>
    <div class="row" v-if="job_titles">
      <Empty v-if="!job_titles.data.length" icon="fas fa-user-ninja" :can_create="true" @callCreate="selectedRecord = {}"/>
      <Card
        :key="index" v-for="(record, index) in job_titles.data"
        :record="record"
        @remove="remove"
      ></Card>
    </div>
    <SkeletonCards
      v-if="job_titles && job_titles.paginatorInfo.hasMorePages"
      :cards=4 :executeScroll="job_titles != undefined"
      @showMore="showMore('job_titles')"
    ></SkeletonCards>
  </div>
</template>

<script>
import JOB_TITLES_QUERY from './queries';
import { DELETE_JOB_TITLE, CREATE_JOB_TITLE } from './mutations';
import Form from './Form.vue';
import Card from './Card.vue';
import { Search, SkeletonCards, SpinnerRouterLink } from '../shared';
import { SearchBuilder, ErrorHandler, PaginationHandler } from '../../shared';

export default {
  extends: PaginationHandler,
  components: {
    Form, Card, SkeletonCards, Search, SpinnerRouterLink,
  },
  apollo: {
    job_titles: {
      query: JOB_TITLES_QUERY,
      errorPolicy: 'all',
      variables() {
        return { page: 1, ...SearchBuilder.build(this.search) };
      },
      error(error) {
        this.$toasted.error(error.message);
      },
    },
  },
  data() {
    return {
      search: {},
      selectedRecord: null,
    };
  },
  methods: {
    queryVariables() {
      return SearchBuilder.build(this.search);
    },
    remove(record) {
      this.$apollo.mutate({
        mutation: DELETE_JOB_TITLE,
        variables: {
          id: record.id,
        },
        update: (store, { data: { deleteJobTitle } }) => {
          const data = store.readQuery({ query: JOB_TITLES_QUERY, variables: this.queryVariables() });
          const updatedJobTitle = data.job_titles.data.filter((j) => j.id !== deleteJobTitle.id);
          store.modify({ fields: { job_titles() { return { ...data.job_titles, data: updatedJobTitle }; } } });
        },
      }).catch((error) => {
        this.$toasted.error(`Unable to delete record: ${error.message}`);
      });
    },
    insert(jobTitle) {
      // We save the user input in case of an error
      const newJobTitle = jobTitle;
      this.selectedRecord = null;
      this.$apollo.mutate({
        mutation: CREATE_JOB_TITLE,
        variables: {
          job_title: jobTitle,
        },
        update: (store, { data: { createJobTitle } }) => {
          const data = store.readQuery({ query: JOB_TITLES_QUERY, variables: this.queryVariables() });
          store.modify({ fields: { job_titles(existingJobTitles = { data: [] }) { return { ...existingJobTitles, data: [createJobTitle, ...data.job_titles.data] }; } } });
        },
      }).catch((error) => {
        ErrorHandler.handle(error, this);
        // We restore the initial user input
        this.selectedRecord = newJobTitle;
      });
    },
  },
};
</script>
