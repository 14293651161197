<template>
  <table class="table">
    <thead>
      <tr>
        <th class="d-table-cell sortable" @click="sortBy('person')">
          <i v-if="orderBy.column === 'person'" class="bg-transparent fas" :class="sortIcon"></i>
          Person
        </th>
        <th class="d-table-cell sortable" @click="sortBy('costs.cost')">
          <i v-if="orderBy.column === 'costs.cost'" class="bg-transparent fas" :class="sortIcon"></i>
          Cost
        </th>
        <th class="d-table-cell sortable" @click="sortBy('hourly_rate')" title="What you charge to the client.">
          <i v-if="orderBy.column === 'hourly_rate'" class="bg-transparent fas" :class="sortIcon"></i>
          Rate
        </th>
        <th class="d-table-cell sortable" @click="sortBy('profit')" title="Profit = Rate - Profit">
          <i v-if="orderBy.column === 'profit'" class="bg-transparent fas" :class="sortIcon"></i>
          Profit
        </th>
        <th class="d-table-cell sortable" @click="sortBy('gross_margin')">
          <i v-if="orderBy.column === 'gross_margin'" class="bg-transparent fas" :class="sortIcon"></i>
          Gross Margin
        </th>
      </tr>
    </thead>
    <tbody>
      <template v-for="user in users" :key="user">
        <tr>
          <th class="bg-company-primary">{{user}}</th>
          <th class="bg-company-primary">
            <ShowCost :cost="averageBy('person', user, 'cost')"></ShowCost>
          </th>
          <th class="bg-company-primary">
            <ShowCost :cost="averageBy('person', user, 'hourly_rate')"></ShowCost>
          </th>
          <th class="bg-company-primary">
            <ShowCost :cost="averageBy('person', user, 'profit')"></ShowCost>
          </th>
          <th class="bg-company-primary">
            {{averageBy('person', user, 'gross_margin')}}%
          </th>
        </tr>
        <tr v-for="line in filterBy('person', user)" :key="line.id">
          <td>{{line.project}}</td>
          <td><ShowCost :cost="line.cost"></ShowCost></td>
          <td><ShowCost :cost="line.hourly_rate"></ShowCost></td>
          <td><ShowCost :cost="line.profit"></ShowCost></td>
          <td>
            <i class="fas fa-circle" :class="`text-${line.status}`"></i>
            {{line.gross_margin.toFixed(2)}}%
          </td>
        </tr>
      </template>
      <tr v-if="grossMarginPerResource && grossMarginPerResource.length === 0">
        <td class="text-center" colspan="4">No records found</td>
      </tr>
    </tbody>
  </table>
</template>

<script>

import Helper from '../Helper';

export default {
  extends: Helper,
  props: ['grossMarginPerResource', 'orderBy', 'show_costs'],
};
</script>
