import { gql } from '@apollo/client/core';

import PROJECT_FRAGMENT from './fragments';

export const PROJECT_QUERY = gql`
query($id: ID!) {
  project(id: $id){
    id
    name
    members {
      id
      user {
        id
        name
      }
      end_date
    }
    estimation {
      id
      bucket_estimations {
        id
        bucket {
          id
          name
        }
        total_hours
        total_hours_consumed
        last_update_at
        deliverables {
          id
          name
          is_completed
          created_at
        }
      }
      deliverables {
        id
      }
    }
  }
}
`;

export const PROJECTS_QUERY = gql`
  query($page: Int!,
        $name: String,
        $type: String,
        $state: String,
        $client_id: ID,
        $status: String,
        $is_confidential: Boolean,
        $is_internal: Boolean,
        $description: String,
        $start_date: DateTime,
        $end_date: DateTime,
        $only_my_projects: Boolean,
        $user_id: ID,
        $orderBy: [OrderByClause!]) {
    projects(
      page: $page,
      name: $name,
      type: $type,
      state: $state,
      client_id: $client_id,
      status: $status,
      is_confidential: $is_confidential,
      is_internal: $is_internal,
      description: $description,
      start_date: $start_date,
      end_date: $end_date,
      only_my_projects: $only_my_projects,
      user_id: $user_id,
      orderBy: $orderBy) @connection(key: "projects", filter: ["name", "client_id", "status", "type", "state", "only_my_projects", "user_id"]) {
      paginatorInfo {
        hasMorePages
      }
      data {
        ...project
        progress
        color
        technologies {
          id
          name
        }
        vertical_markets{
          id
          name
        }
        estimation {
          id
          bucket_estimations {
            id
            bucket {
              id
              name
            }
            total_hours
            total_hours_consumed
          }
          deliverables {
            id
            is_completed
          }
        }
        members {
          id
          user {
            id
            name
          }
          project_role {
            id
            name
          }
          is_manager
          start_date
          end_date
          contract_end_date
          hours_per_week
          project_role_id
        }
        tags {
          id
          name
        }
        contacts {
          id
          name
          email
          phone
          position
        }
      }
    }
  }
  ${PROJECT_FRAGMENT}
`;

export const MY_HOURS_QUERY = gql`
  query($start_date: Date!, $end_date: Date!, $project_id: ID) {
    myHoursGetLogHours(start_date: $start_date, end_date: $end_date, project_id: $project_id) {
      user_id
      project_id
      hours
      checked
      date
      note
      my_hours_user_id
      my_hours_project_id
      user {
        name
        id
      }
    }
  }
`;

export const PROJECT_MEMBERS_QUERY = gql`
query($id: ID!, $active: Boolean!) {
  project(id: $id){
    id
    name
    members(active: $active) {
      id
      is_manager
      user {
        id
        name
      }
    }
    contacts {
      id
      name
    }
  }
}
`;
