import { gql } from '@apollo/client/core';

export const UPDATE_INTEGRATION_MAPPING = gql`
  mutation($id: ID!, $integrationMapping: IntegrationMappingInput!){
    updateIntegrationMapping(id: $id, input: $integrationMapping){
      id
    }
  }
`;

export const CREATE_INTEGRATION_MAPPING = gql`
  mutation($mapping: IntegrationMappingInput!){
    createIntegrationMapping(input: $mapping){
      id
      model
      internal_id
      external_id
      integration_id
    }
  }
`;

export const DELETE_INTEGRATION_MAPPING = gql`
  mutation($id: ID!){
    deleteIntegrationMapping(id: $id){
      id
    }
  }
`;
