import { gql } from '@apollo/client/core';

import { USER_FRAGMENT, USER_FRAGMENT_WITH_INTERNAL_NPS } from './fragments';

export const USERS_QUERY = gql`
  query($page: Int,
        $id: ID,
        $name: String,
        $email: String,
        $active_only: Boolean,
        $is_external: Boolean,
        $is_parent: Boolean,
        $country_id: ID,
        $only_my_team: Boolean,
        $orderBy: [OrderByClause!]) {
    users(
      page: $page,
      id: $id,
      name: $name,
      email: $email,
      is_active: $active_only,
      is_external: $is_external,
      is_parent: $is_parent
      country_id: $country_id,
      only_my_team: $only_my_team,
      orderBy: $orderBy) {
      paginatorInfo {
        hasMorePages
      }
      data {
        ...user
        roles {
          id
          name
        }
        technologies {
          id
          name
        }
        business_units {
          id
          name
        }
        country {
          id
          name
        }
        partner {
          id
          name
        }
        tags {
          id
          name
        }
        active_projects: projects(active: true, state: "In Progress") {
          id
          name
        }
      }
    }
  }
  ${USER_FRAGMENT}
`;

export const USERS_QUERY_WITH_INTERNAL_NPS = gql`
  query($page: Int,
        $id: ID,
        $name: String,
        $email: String,
        $active_only: Boolean,
        $is_external: Boolean,
        $is_parent: Boolean,
        $country_id: ID,
        $only_my_team: Boolean,
        $orderBy: [OrderByClause!]) {
    users(
      page: $page,
      id: $id,
      name: $name,
      email: $email,
      is_active: $active_only,
      is_external: $is_external,
      is_parent: $is_parent,
      country_id: $country_id,
      only_my_team: $only_my_team,
      orderBy: $orderBy) {
      paginatorInfo {
        hasMorePages
      }
      data {
        ...user_with_internal_nps
        roles {
          id
          name
        }
        technologies {
          id
          name
        }
        business_units {
          id
          name
        }
        country {
          id
          name
        }
        partner {
          id
          name
        }
        tags {
          id
          name
        }
        active_projects: projects(active: true, state: "In Progress") {
          id
          name
        }
      }
    }
  }
  ${USER_FRAGMENT_WITH_INTERNAL_NPS}
`;

export const USER_DETAIL = gql`
  query($id: ID!) {
    user(id: $id) {
      id
      name
      identification
      email
      avatar
      bio
      soft_skills
      technical_skills
      time_in_the_company
      hiring_date
      education
      notes
      address
      emergency_contact
      resume_score {
        score
        logs
      }
      technologies {
        id
        name
      }
      external_companies {
        id
        name
        start_date
        end_date
        duration
        external_projects {
          id
          name
          description
          duration
        }
      }
    }
  }
`;

export const PROJECT_MEMBERS = gql`
  query($user_id: ID!, $orderBy: [OrderByClause!]){
    projectMembers(user_id: $user_id, orderBy: $orderBy){
      project_id
      responsibilities
      id
      duration
      project{
        id
        client {
          id
          name
        }
        duration
        name
        start_date
        is_confidential
        description
        technologies {
          id
          name
        }
      }
    start_date
    end_date
    created_at
    }
  }
`;

export const TOP_USER_SKILLS_QUERY = gql`
  query($user_id: ID!, $limit: Int!){
    top_user_skills(user_id: $user_id, limit: $limit){
      name
      frequency
    }
  }
`;
