<template>
  <div class="company-control">
    <label for="item" v-if="$slots['default']">
      <slot></slot>
    </label>
    <div class="mb-3 border rounded w-100">
      <span :key="item.id" v-for="item in localRecord" class="badge bg-company-primary m-1">
        {{item.name}}
        <i class="fas fa-times" @click="untag(item)"></i>
      </span>
      <input list="tags" type="text" name="item" class="border-0 company-control-tags" :class="{'w-100': $slots['default']}" v-model="current_tag" @change="tag()">
    </div>
    <datalist id="tags" v-if="tagsDropDown">
      <option :key="tag.id" v-for="tag in tagsDropDown">{{tag.name}}</option>
    </datalist>
  </div>
</template>

<script>
import { dropdownWithAlias } from '../../shared';

export default {
  apollo: {
    tagsDropDown: {
      query: dropdownWithAlias('tagsDropDown'),
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
      variables() {
        return { type: 'Tag', orderBy: [{ column: 'id', order: 'ASC' }] };
      },
    },
  },
  props: ['selected'],
  data() {
    return {
      current_tag: '',
      localRecord: this.selected,
    };
  },
  methods: {
    tag() {
      const tech = this.tagsDropDown.find((t) => t.name === this.current_tag);
      if (tech) {
        this.localRecord.push({ id: tech.id, name: tech.name });
      } else {
        this.localRecord.push({ name: this.current_tag });
      }
      this.current_tag = '';
    },
    untag(item) {
      const index = this.localRecord.findIndex((e) => e === item);
      if (index >= 0) {
        this.localRecord.splice(index, 1);
      }
    },
  },
};
</script>
