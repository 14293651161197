<style scoped>
* {
  box-sizing: border-box;
}
.modal-mask {
  z-index: 1;
  top: 0;
  background-color: rgb(7, 37, 50, 0.9);
}
.modal-container {
  top: 0;
  bottom: 0;
  right: 0;
}
.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>

<template>
  <transition name="modal">
    <div class="modal-mask left-0 w-100 h-100 position-fixed overflow-auto" v-show="show">
      <div class="modal-container left-0 transition-05 m-auto position-absolute mh-100 w-50 my-5 p-3 rounded shadow-lg bg-white d-flex flex-column justify-content-center align-items-center">
        <button @click="close()" class="btn btn-company-secondary position-absolute top-0 end-0 m-2">
          <i class="fas fa-times"></i>
        </button>
        <slot></slot>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: ['show'],
  mounted() {
    document.addEventListener('keydown', (e) => {
      if (this.show && e.keyCode === 27) {
        this.close();
      }
    });
  },
  methods: {
    close() {
      this.$emit('close');
    },
  },
};
</script>
