import { gql } from '@apollo/client/core';

export default gql`
query {
  roles {
    id
    name
    permissions{
      id
      name
    }
  }
}
`;
