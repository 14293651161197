/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

// Global imports
import { createApp, configureCompat } from 'vue';
import Trix from 'trix';
import { createApolloProvider } from '@vue/apollo-option';
import ApolloClient from './apollo';
import router from './router';
import '../sass/app.scss';
import 'trix/dist/trix.css';

// Components imports
import DatePicker from './components/shared/DatePicker.vue';
import PopOver from './components/pop_over/Index.vue';
import Desktop from './components/main_menu/Desktop.vue';
import Mobile from './components/main_menu/Mobile.vue';
import Empty from './components/shared/Empty.vue';

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

import CanPlugin from './can';
import DatePlugin from './date';
import './bootstrap';
import s3Plugin from './s3';
import bsToast from './toast';

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

// Create a fresh Vue application instance
const app = createApp({});

app.directive('focus', {
  mounted(el) {
    el.focus();
  },
});

app.config.globalProperties.$filters = {
  truncate(text, stop, clamp) {
    return text.slice(0, stop) + (stop < text.length ? clamp || '...' : '');
  },
  formatNumber(value) {
    return new Intl.NumberFormat().format(value);
  },
};

app.config.compilerOptions = {
  whitespace: 'preserve',
};

const apolloProvider = createApolloProvider({
  defaultClient: ApolloClient,
});
configureCompat({
  ATTR_FALSE_VALUE: false, COMPONENT_V_MODEL: false, RENDER_FUNCTION: false, WATCH_ARRAY: false,
});

app.component('pop_over', PopOver);
app.component('desktop_menu', Desktop);
app.component('mobile_menu', Mobile);
app.component('DatePicker', DatePicker);
app.component('Empty', Empty);
app.component('Trix', Trix);

app.use(router);
app.use(apolloProvider);
app.use(CanPlugin);
app.use(DatePlugin);
app.use(bsToast);
app.use(s3Plugin);

app.mount('#app');
