<template>
  <div class="card">
    <div class="card-header d-flex">
      <div class="col p-0">
        <span v-if="!record.id">New Interview</span>
      </div>
      <div class="col text-end">
        <i class="fas fa-times" @click="$emit('close')"></i>
      </div>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="mb-3 col-md-6">
          <label class="me-sm-2" for="date">Interview Date</label>
          <DatePicker :date="localRecord.date"
                      placeholder="Select Date"
                      :css_class="{'is-invalid': v$.localRecord.date.$invalid}"
                      @change="localRecord = { ...localRecord, date: $event }"></DatePicker>
          <div v-for="error of v$.localRecord.date.$silentErrors" :key="error.$uid">
            <div class="text-danger">{{ error.$message }}</div>
          </div>
        </div>
        <div class="col-md-6 mt-4 ps-2">
          <div class="form-check">
            <div class="form-check">
              <input id="is_successful" type="checkbox" class="form-check-input" v-model="localRecord.is_successful">
              <label class="form-check-label m-auto" for="is_successful">Successful?</label>
            </div>
          </div>
        </div>
      </div>
      <div class="mb-3">
        <label for="notes">Notes</label>
        <input type="hidden" id="interview_notes" v-model="localRecord.notes">
        <trix-editor input="interview_notes" @trix-change="localRecord.notes = $event.target.value; $forceUpdate()" placeholder="Enter content"></trix-editor>
        <div v-for="error of v$.localRecord.notes.$silentErrors" :key="error.$uid">
          <div class="text-danger">{{ error.$message }}</div>
        </div>
      </div>
      <button :disabled="v$.localRecord.$invalid" type="button" class="btn btn-company-primary" @click="save()">
        <i class="fas fa-save"></i>
        Save
      </button>
      <button type="button" class="btn btn-company-secondary" @click="$emit('close')">
        <i class="fas fa-times"></i>
        Cancel
      </button>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import Validations from './Validations';
import Blinkable from '../../shared/Blinkable';

export default {
  mixins: [Blinkable, Validations],
  props: {
    record: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      localRecord: _.cloneDeep(this.record),
    };
  },
  methods: {
    save() {
      const event = (this.localRecord.id) ? 'update' : 'insert';
      this.$emit(event, this.localRecord);
    },
  },
  watch: {
    record: {
      handler(newVal) {
        this.localRecord = _.cloneDeep(newVal);
        this.$nextTick(() => {
          const trix = this.$el.querySelector('trix-editor');
          if (trix && this.localRecord.notes) {
            trix.editor.loadHTML(this.localRecord.notes);
          }
        });
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
