import { gql } from '@apollo/client/core';

export const CREATE_BOT = gql`
  mutation($bot: BotInput!){
    createBot(input: $bot){
      id
      name
      api_token
      permissions{
        id
        name
      }
    }
  }
`;

export const UPDATE_BOT = gql`
  mutation($id: ID!, $bot: BotInput!){
    updateBot(id: $id, input: $bot){
      id
    }
  }
`;

export const DELETE_BOT = gql`
  mutation($id: ID!){
    deleteBot(id: $id){
      id
    }
  }
`;
