import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';

export default {
  data() {
    return {
      v$: useVuelidate(), // Inicialización de vuelidate
    };
  },
  validations: {
    start_date: { required },
    end_date: { required },
    assigned_to: {
      user_id: { required },
    },
  },
};
