<template>
<div :id="record.id" class="col-md-3 pb-2">
  <Form v-if="is_editting" :record="record"
                            @close="is_editting = false"
                            @update="update"></Form>
  <div v-if="!is_editting" class="card h-100">
    <div class="card-body pb-0">
      <div class="image-container">
        <img v-if="signedImageUrl" :src="signedImageUrl" alt="Preview" class="img-fluid" />
      </div>

      <h5 class="card-title">
        {{record.name}}
      </h5>
      <h6 class="card-subtitle mb-2 text-body-secondary">
        Expiration Date: {{record.expires_at ? $displayDate(record.expires_at) : 'Never'}}
      </h6>
    </div>
    <div class="card-body">
      <button v-if="!confirmation && !read_only" class="btn btn-company-primary" @click="is_editting = true">
        <i class="fas fa-pen"></i>
        Edit
      </button>
      <Confirmation v-if="!read_only"
        v-model="confirmation"
        @accepted="remove()"
      />
      <button v-if="!confirmation" class="btn btn-company-primary" @click="downloadFile">
        <i class="fas fa-file-download"></i>
        Download
      </button>
    </div>
  </div>
</div>
</template>
<script>

import UPSERT_ATTACHMENTS from './mutations';
import Form from './Form.vue';
import { Confirmation } from '../shared';
import { ErrorHandler } from '../../shared';

export default {
  components: { Form, Confirmation },
  props: ['record', 'entity_id', 'entity_type', 'read_only'],
  data() {
    return {
      is_editting: false,
      confirmation: false,
      signedImageUrl: null,
    };
  },
  mounted() {
    if (this.record && this.record.type && this.record.type.startsWith('image/')) {
      this.getSignedUrl();
    }
  },
  methods: {
    getSignedUrl() {
      const key = `attachments/${decodeURI(this.record.url.split('/').pop())}`;
      this.$s3GetSignedUrl(key)
        .then((s3Url) => {
          this.signedImageUrl = s3Url;
        })
        .catch((error) => {
          ErrorHandler.handle(error, this);
        });
    },
    downloadFile() {
      const key = `attachments/${decodeURI(this.record.url.split('/').pop())}`;
      this.$s3GetSignedUrl(key).then((s3Url) => window.open(s3Url, '_blank'));
    },
    update(record) {
      this.$apollo.mutate({
        mutation: UPSERT_ATTACHMENTS,
        variables: {
          upsertAttachment: {
            entity_id: this.entity_id,
            entity_type: this.entity_type,
            attachments: {
              upsert: [
                { ..._.pick(record, ['id', 'name', 'type', 'url', 'expires_at']) },
              ],
            },
          },
        },
      }).then(() => {
        this.is_editting = false;
      }).catch((error) => {
        ErrorHandler.handle(error, this);
      });
    },
    remove() {
      this.$emit('remove', this.record);
      this.is_editting = false;
      this.confirmation = false;
    },
  },
};
</script>
