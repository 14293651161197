import { gql } from '@apollo/client/core';

import PRODUCT_FRAGMENT from './fragments';

export const CREATE_PRODUCT = gql`
  mutation($product: ProductInput!){
    createProduct(input: $product){
      ...product
    }
  }
  ${PRODUCT_FRAGMENT}
`;

export const UPDATE_PRODUCT = gql`
  mutation($id: ID!, $product: ProductInput!){
    updateProduct(id: $id, input: $product){
      ...product
    }
  }
  ${PRODUCT_FRAGMENT}

`;

export const DELETE_PRODUCT = gql`
  mutation($id: ID!){
    deleteProduct(id: $id){
      id
    }
  }
`;
