import { gql } from '@apollo/client/core';

import OPPORTUNITY_FRAGMENT from './fragments';

export const CREATE_OPPORTUNITY = gql`
  mutation($opportunity: OpportunityInput!){
    createOpportunity(input: $opportunity){
      ...opportunity
    }
  }
  ${OPPORTUNITY_FRAGMENT}
`;

export const UPDATE_OPPORTUNITY = gql`
  mutation($id: ID!, $opportunity: OpportunityInput!){
    updateOpportunity(id: $id, input: $opportunity){
      ...opportunity
    }
  }
  ${OPPORTUNITY_FRAGMENT}
`;

export const DELETE_OPPORTUNITY = gql`
  mutation($id: ID!){
    deleteOpportunity(id: $id){
      id
    }
  }
`;
