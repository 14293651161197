<template>
<div :id="record.id" class="card">
  <div class="card-header d-flex">
    <div class="col p-0">
      <span v-if="!record.id">New Project Risk</span>
    </div>
    <div class="col text-end">
      <i class="fas fa-times" @click="$emit('close')"></i>
    </div>
  </div>
  <div class="card-body">
    <div class="mb-3">
      <label for="name">Name</label>
      <input v-focus type="text" class="form-control" :class="{'is-invalid': v$.localRecord.name.$invalid}" id="name" v-model="localRecord.name">
      <div v-for="error of v$.localRecord.name.$silentErrors" :key="error.$uid">
        <div class="text-danger">{{ error.$message }}</div>
      </div>
    </div>
    <div class="mb-3">
      <label for="description">Description</label>
      <input type="hidden" id="description" v-model="localRecord.description">
      <trix-editor input="description" @trix-change="localRecord.description = $event.target.value; $forceUpdate()"
      :class="{'is-invalid': v$.localRecord.description.$invalid}" placeholder="Enter content"></trix-editor>
      <div v-for="error of v$.localRecord.description.$silentErrors" :key="error.$uid">
        <div class="text-danger">{{ error.$message }}</div>
      </div>
    </div>
    <div class="row">
      <div class="mb-3 col-md-6">
        <label for="probability">Probability</label>
        <select id="probability" class="form-control form-select" v-model="localRecord.probability" :class="{'is-invalid': v$.localRecord.probability.$invalid}">
          <option value="1">Rare</option>
          <option value="2">Unlikely</option>
          <option value="3">Possible</option>
          <option value="4">Likely</option>
          <option value="5">Almost Certain</option>
        </select>
        <div v-for="error of v$.localRecord.probability.$silentErrors" :key="error.$uid">
          <div class="text-danger">{{ error.$message }}</div>
        </div>
      </div>
      <div class="mb-3 col-md-6">
        <label for="impact">Impact</label>
        <select id="impact" class="form-control form-select" v-model="localRecord.impact" :class="{'is-invalid': v$.localRecord.impact.$invalid}">
          <option value="1">Insignificant</option>
          <option value="2">Minor</option>
          <option value="3">Moderate</option>
          <option value="4">Major</option>
          <option value="5">Severe</option>
        </select>
        <div v-for="error of v$.localRecord.impact.$silentErrors" :key="error.$uid">
          <div class="text-danger">{{ error.$message }}</div>
        </div>
      </div>
    </div>
    <button :disabled="v$.localRecord.$invalid" type="button" class="btn btn-company-primary" @click="save()">
      <i class="fas fa-save"></i>
      Save
    </button>
    <button type="button" class="btn btn-company-secondary" @click="$emit('close')">
      <i class="fas fa-times"></i>
      Cancel
    </button>
  </div>
</div>
</template>
<script>

import Validations from './Validations';
import Blinkable from '../../shared/Blinkable';

export default {
  mixins: [Blinkable, Validations],
  props: ['record'],
  data() {
    return {
      localRecord: _.cloneDeep(this.record),
    };
  },
  methods: {
    save() {
      const event = (this.localRecord.id) ? 'update' : 'insert';
      this.$emit(event, this.localRecord);
    },
  },
  watch: {
    record: {
      handler(newVal) {
        this.localRecord = _.cloneDeep(newVal);
        this.$nextTick(() => {
          const trix = document.querySelector('trix-editor');
          if (trix && this.localRecord.description) {
            trix.editor.loadHTML(this.localRecord.description);
          }
        });
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
