import { useVuelidate } from '@vuelidate/core';
import { required, maxLength, minLength } from '@vuelidate/validators';

export default {
  data() {
    return {
      v$: useVuelidate(), // Inicialización de vuelidate
    };
  },
  validations: {
    localRecord: {
      technology_id: { required },
      question: { required, minLengthValue: minLength(7) },
      answer: { required, minLengthValue: minLength(7) },
      difficulty: { required, maxLengthValue: maxLength(255) },
    },
  },
};
