<template>
  <div>
    <nav>
      <div class="breadcrumb p-2">
        <div class="row">
          <div class="col-auto ms-3 p-0">
            <SpinnerRouterLink :loading="$apollo.loading">My Time</SpinnerRouterLink>
          </div>
        </div>
        <div class="container-fluid">
          <div class="row">
            <div class="col-12 col-sm-9">
              <Search v-model="search"
                      :inputs="['dateRanges']"
                      :default_values="defaultDates"></Search>
            </div>
            <div class="col-12 col-sm-3 order-last text-end mt-2 pt-2">
              <button class="btn btn-company-primary" @click="exportFile()">
                <i v-if="is_exporting" class="fas fa-sync fa-spin"></i>
                <i v-if="!is_exporting" class="fa fa-file-excel"></i>
                Export
              </button>
            </div>
          </div>
        </div>
      </div>
    </nav>
    <div class="row" v-if="me && me.bucket_estimation_hours">
      <div class="col-md-12 text-nowrap scroll-x h-100">
        <Empty v-if="!me.bucket_estimation_hours.length" icon="fas fa-business-time"/>
        <table v-if="me.bucket_estimation_hours.length" class="table">
          <thead>
            <tr>
              <SortByColumn class="d-table-cell" :sort_by_field="'project_id'" :orderBy="orderBy" @refresh="refresh">Project</SortByColumn>
              <th class="d-table-cell">Activities</th>
              <SortByColumn class="d-table-cell" :sort_by_field="'created_at'" :orderBy="orderBy" @refresh="refresh">Date</SortByColumn>
              <SortByColumn class="d-table-cell" :sort_by_field="'hours'" :orderBy="orderBy" @refresh="refresh">Hours</SortByColumn>
            </tr>
          </thead>
          <tbody>
            <tr :key="hour.id" v-for="hour in me.bucket_estimation_hours">
              <td>{{!!hour.project ? hour.project.name :'Internal hours'}}</td>
              <td v-html="hour.activities" v-if="hour.activities"></td>
              <td v-else>-</td>
              <td>{{$displayDate(hour.date)}}</td>
              <td>{{hour.hours}}</td>
            </tr>
            <tr v-if="me && me.bucket_estimation_hours.length === 0">
              <td class="text-center" colspan="4">No records found</td>
            </tr>
            <tr v-if="me && me.bucket_estimation_hours.length > 0">
              <td class="text-end" colspan="3">
                <strong>Total</strong>
              </td>
              <td>
                <strong>
                  {{ $filters.formatNumber(total) }}
                </strong>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>

import dayjs from 'dayjs';
import MY_TIME_QUERY from './queries';
import { Search, SpinnerRouterLink, SortByColumn } from '../../shared';
import { SearchBuilder, Base64File } from '../../../shared';
import EXPORT_MY_TIME from './mutations';

export default {
  components: { Search, SortByColumn, SpinnerRouterLink },
  data() {
    return {
      orderBy: { column: 'created_at', order: 'DESC' },
      search: { },
      is_exporting: false,
    };
  },
  apollo: {
    me: {
      query: MY_TIME_QUERY,
      fetchPolicy: 'no-cache',
      variables() {
        return {
          orderBy: [this.orderBy],
          ...SearchBuilder.build(this.search),
        };
      },
      skip() {
        return !(this.search.start_date || this.search.end_date);
      },
      error(error) {
        this.$toasted.error(error.message);
      },
    },
  },
  computed: {
    total() {
      return _.sumBy(this.me.bucket_estimation_hours, 'hours');
    },
    defaultDates() {
      return {
        start_date: dayjs().subtract(1, 'weeks').startOf('isoweek').format('YYYY-MM-DD'),
        end_date: dayjs().subtract(1, 'weeks').endOf('isoweek').format('YYYY-MM-DD'),
      };
    },
  },
  methods: {
    exportFile() {
      this.is_exporting = true;
      this.$apollo.mutate({
        mutation: EXPORT_MY_TIME,
        variables: {
          orderBy: [this.orderBy],
          ...SearchBuilder.build(this.search),
          user_id: this.me.id,
        },
      }).then((res) => {
        Base64File.download(res.data.exportTimesheetToExcel, `${this.search.start_date} - ${this.search.end_date}.xlsx`);
      }).finally(() => {
        this.is_exporting = false;
      });
    },
    refresh() {
      this.$apollo.queries.me.refetch();
    },
  },
};
</script>
