<template>
  <div class="card">
    <div class="card-header d-flex">
      <div class="col text-end">
        <i class="fas fa-times" @click="$emit('close')"></i>
      </div>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="mb-3 col-md-6">
          <label for="name">Name</label>
          <input v-focus type="text" class="form-control" :class="{'is-invalid': v$.localRecord.name.$invalid}" id="name" v-model="localRecord.name">
          <div v-for="error of v$.localRecord.name.$silentErrors" :key="error.$uid">
            <div class="text-danger">{{ error.$message }}</div>
          </div>
        </div>

        <div class="mb-3 col-md-6">
          <label for="budget">Budget</label>
          <div class="input-group">
            <span class="input-group-text">$</span>
            <input type="number" class="form-control" :class="{'is-invalid': v$.localRecord.budget.$invalid}" id="budget" v-model="localRecord.budget" min="0">
          </div>
          <div v-for="error of v$.localRecord.budget.$silentErrors" :key="error.$uid">
            <div class="text-danger">{{ error.$message }}</div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="mb-3 col-md-6">
          <label for="type">Type</label>
          <select id="type" class="form-control form-select" v-model="localRecord.type" :class="{'is-invalid': v$.localRecord.type.$invalid}">
            <option>Fixed Bid</option>
            <option>Time and Materials</option>
            <option>Retainer Fee</option>
            <option>Support</option>
          </select>
          <div v-for="error of v$.localRecord.type.$silentErrors" :key="error.$uid">
            <div class="text-danger">{{ error.$message }}</div>
          </div>
        </div>
        <div class="mb-3 col-md-6">
          <label for="state">State</label>
          <select id="state" class="form-control form-select" v-model="localRecord.state" :class="{'is-invalid': v$.localRecord.state.$invalid}">
            <option>In Progress</option>
            <option>Draft</option>
            <option>On Hold</option>
            <option>Cancelled</option>
            <option>Completed</option>
          </select>
          <div v-for="error of v$.localRecord.state.$silentErrors" :key="error.$uid">
            <div class="text-danger">{{ error.$message }}</div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="mb-3 col-md-6">
          <label for="start_date">Start Date</label>
          <DatePicker :date="localRecord.start_date"
                      placeholder="Select Date"
                      :css_class="{'is-invalid': v$.localRecord.start_date.$invalid}"
                      @change="localRecord = { ...localRecord, start_date: $event }"></DatePicker>
          <div v-for="error of v$.localRecord.start_date.$silentErrors" :key="error.$uid">
            <div class="text-danger">{{ error.$message }}</div>
          </div>
        </div>
        <div class="mb-3 col-md-6">
          <label for="end_date">End Date</label>
          <DatePicker :date="localRecord.end_date"
                      placeholder="Select Date"
                      :css_class="{'is-invalid': v$.localRecord.end_date.$invalid}"
                      @change="localRecord = { ...localRecord, end_date: $event }"></DatePicker>
          <div v-for="error of v$.localRecord.end_date.$silentErrors" :key="error.$uid">
            <div class="text-danger">{{ error.$message }}</div>
          </div>
        </div>
      </div>
      <button :disabled="v$.localRecord.$invalid" type="button" class="btn btn-company-primary" @click="save()">
        <i class="fas fa-save"></i>
        Save
      </button>
      <button :disabled="v$.localRecord.$invalid" type="button" class="btn btn-company-primary" @click="saveAndRedirect()">
        <i class="fas fa-directions"></i>
        Save and open
      </button>
      <button type="button" class="btn btn-company-secondary" @click="$emit('close')">
        <i class="fas fa-times"></i>
        Cancel
      </button>
    </div>
  </div>
</template>

<script>

import { useVuelidate } from '@vuelidate/core';
import { required, maxLength } from '@vuelidate/validators';

export default {
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      localRecord: {
        name: { required, maxLengthValue: maxLength(255) },
        type: { required, maxLengthValue: maxLength(255) },
        state: { required, maxLengthValue: maxLength(255) },
        start_date: { required },
        end_date: { required },
        budget: { required },
      },
    };
  },
  props: ['project'],
  data() {
    return {
      localRecord: _.cloneDeep(this.project),
    };
  },
  methods: {
    save() {
      this.$emit('insert', this.localRecord);
    },
    saveAndRedirect() {
      this.$emit('insert-redirect', this.localRecord);
    },
  },
};
</script>
