import { gql } from '@apollo/client/core';

import LEARNING_PATH_LEVEL_NODE_USER_FRAGMENT from './fragments';

export const LEARNING_PATH_LEVEL_NODE_USER_QUERY = gql`
    query(
      $status: String!,
      $user_id: ID,
      $learning_path_level_id: ID,
      $learning_path_id: ID,
    ) {
      show_learning_path_level_node_users_with_optional_filter(
        status: $status,
        user_id: $user_id,
        learning_path_level_id: $learning_path_level_id,
        learning_path_id: $learning_path_id,
      ) {
        paginatorInfo{
          hasMorePages
        },
        data {
          ...learning_path_level_node_user
        }
      }
    }
    ${LEARNING_PATH_LEVEL_NODE_USER_FRAGMENT}
`;
export const USER_QUERY = gql`
    query(
      $id: ID!,
    ) {
      user(id: $id) {
        name
      }
    }
`;
