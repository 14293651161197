export default {
  methods: {
    updateFilter() {
      const filters = _.pickBy(this.localSearch, (v) => {
        if (typeof v === 'object' && _.isEmpty(v)) {
          return false;
        }
        return v !== '' && v != null;
      });
      this.$router.push({ query: filters }).catch(() => {});
      this.$emit('update:modelValue', filters);
    },
  },
  created() {
    if (this.localSearch) {
      // Merge query parameters with the initial localSearch values
      this.localSearch = { ...this.$route.query, ...this.localSearch };
      // make sure boolean params stay boolean and not string
      _.forOwn(this.localSearch, (value, key) => {
        if (['true', 'false'].includes(value)) {
          this.localSearch[key] = (value === 'true');
        }
      });
      this.updateFilter();
    }
  },
};
