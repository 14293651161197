import { useVuelidate } from '@vuelidate/core';
import {
  required, minValue, maxLength, numeric,
} from '@vuelidate/validators';

export default {
  data() {
    return {
      v$: useVuelidate(), // Inicialización de vuelidate
    };
  },
  validations: {
    localRecord: {
      name: { required, maxLengthValue: maxLength(255) },
      description: { required },
      budget: { required, numeric, minValue: minValue(0) },
      state: { required },
      begins: { required },
      duration: { required },
      positions_amount: { required, numeric, minValue: minValue(0) },
      seniority: { required },
      english_level: { required },
      technologies: { required },
    },
  },
};
