import { gql } from '@apollo/client/core';

import PROJECT_ROLE_FRAGMENT from './fragments';

export default gql`
  query($page: Int, $name: String) {
    project_roles(
      page: $page,
      name: $name,
      orderBy: { column: "id", order: ASC }) @connection(key: "project_roles", filter: ["name"]) {
      paginatorInfo {
        hasMorePages
      }
      data {
        ...project_role
      }
    }
  }
  ${PROJECT_ROLE_FRAGMENT}
`;
