<template>
  <div>
    <nav>
      <div class="breadcrumb p-2">
        <div class="row">
          <div class="col-auto ms-3 p-0">
            <SpinnerRouterLink :loading="$apollo.loading">Indicators</SpinnerRouterLink>
          </div>
        </div>
        <div class="container-fluid">
          <div class="row">
            <div class="col-12 col-sm-12 m-auto">
              <Search
                v-model="search"
                :inputs="['name']"
              ></Search>
            </div>
          </div>
        </div>
      </div>
    </nav>
    <div class="row" v-if="indicators">
      <Empty v-if="!indicators.data.length" icon="fas fa-chart-area"/>
      <Card
        :key="indicator.id"
        v-for="indicator in indicators.data"
        :indicator="indicator"
        @favorite="handleFavorite"
      ></Card>
    </div>
    <SkeletonCards
      v-if="indicators && indicators.paginatorInfo.hasMorePages"
      :cards=3
      :executeScroll="indicators != undefined"
      @showMore="showMore('indicators')"
    ></SkeletonCards>
  </div>
</template>

<script>
import Card from './Card.vue';
import { INDICATORS_QUERY } from './queries';
import { HANDLE_FAVORITES } from './mutations';
import { SearchBuilder, PaginationHandler } from '../../shared';
import { Search, SkeletonCards, SpinnerRouterLink } from '../shared';

export default {
  components: {
    Card, Search, SkeletonCards, SpinnerRouterLink,
  },
  extends: PaginationHandler,
  data() {
    return { search: {} };
  },
  apollo: {
    indicators: {
      query: INDICATORS_QUERY,
      variables() {
        return {
          page: 1,
          ...this.queryVariables(),
        };
      },
      errorPolicy: 'all',
      error(error) {
        this.$toasted.error(error.message);
      },
    },
  },
  methods: {
    handleFavorite(indicatorId) {
      const favorite = {
        favoritable_id: indicatorId,
        favoritable_type: 'App\\Models\\Indicator',
      };
      const updatedIndicators = this.indicators.data.map((indicator) => {
        if (indicator.id === indicatorId) {
          return { ...indicator, is_favorite: !indicator.is_favorite };
        }
        return indicator;
      });

      this.indicators = { ...this.indicators, data: updatedIndicators };

      this.$apollo.mutate({
        mutation: HANDLE_FAVORITES,
        variables: { favorite },
      });
    },
    queryVariables() {
      return {
        orderBy: [{ column: 'id', order: 'ASC' }],
        is_visible: true,
        ...SearchBuilder.build(this.search),
      };
    },
  },
};
</script>
