import { gql } from '@apollo/client/core';

export default gql`
  fragment client on Client {
    id
    name
    is_prospect
    is_direct
    email
    website
    last_known_net_promoter_score
    contacts {
      id
      name
      email
      phone
      position
    }
    country_id
  }
`;
