import { gql } from '@apollo/client/core';

import HEALTH_SCORE_CONFIGURATION_ITEM_FRAGMENT from './fragments';

export default gql`
  query($name: String) {
    healthScoreConfigurationItems(
      name: $name,
      orderBy: { column: "id", order: ASC }) @connection(key: "healthScoreConfigurationItems", filter: ["name"]) {
        ...healthScoreConfigurationItem
    }
  }
  ${HEALTH_SCORE_CONFIGURATION_ITEM_FRAGMENT}
`;
