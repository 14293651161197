import { gql } from '@apollo/client/core';

export default gql`
    fragment candidate on Candidate {
        id
        first_name
        last_name
        email
        phone_number
        resume_url
        english_level
        notes
        candidate_status_id
        candidate_status {
            id
            name
        }
        availability
        is_working
        company
        application_channel
        job_experience_started_date
        country_id
    }
`;
