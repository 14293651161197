import { gql } from '@apollo/client/core';

import JOB_TITLE_FRAGMENT from './fragments';

export default gql`
  query($page: Int,
        $internal_description: String) {
    job_titles (
      page: $page,
      internal_description: $internal_description,
      orderBy: { column: "id", order: ASC }) @connection(key: "job_titles", filter: ["internal_description"]) {
      paginatorInfo {
        hasMorePages
      }
      data {
        ...job_title
      }
    }
  }
  ${JOB_TITLE_FRAGMENT}
`;
