import { gql } from '@apollo/client/core';
import { LEARNING_PATH_LEVELS_WITH_NODES_FRAGMENT, LEARNING_PATH_NODE_USER_FRAGMENT, LEARNING_PATH_NODE_USER_PERCENTAGE_FRAGMENT } from './fragments';

export const LEARNING_PATH_LEVELS_QUERY = gql`
  query($id: ID){
    learning_path(id: $id){
      ...learning_path_levels_with_nodes
    }
  }
  ${LEARNING_PATH_LEVELS_WITH_NODES_FRAGMENT}
`;

export const LEARNING_PATH_NODE_USER_QUERY = gql`
  query{
    learning_path_node_user {
      ...learning_path_node_user
    }
  }
  ${LEARNING_PATH_NODE_USER_FRAGMENT}
`;
export const LEARNING_PATH_NODE_USER_PERCENTAGE_QUERY = gql`
  query{
    learning_path_completed_levels {
      ...learning_path_node_user_percentage
    }
  }
  ${LEARNING_PATH_NODE_USER_PERCENTAGE_FRAGMENT}
`;
