<template>
  <div>
    <div class="rounded-bottom rounded-circle bg-opacity-10 bg-dark vw-100 h-25 bottom-0 start-0 position-absolute overflow-hidden position-absolute overflow-hidden"><span class="d-block"></span></div>
    <div id="TenantCreator">
      <div class="position-relative overflow-hidden">
        <div class="vh-100 form left-0 transition-05 mt-xxl-5 m-auto position-absolute" :class="{'invisible translate-middle-y':showing > 1}">
          <tenant-form
            v-model:tenant="tenant"
            @goTo="goTo" @setFile="setFile"
          ></tenant-form>
        </div>
        <div class="vh-100 form left-0 transition-05 mt-xxl-5 m-auto position-absolute" :class="{'invisible translate-middle-y':showing > 2, 'down invisible':showing < 2}">
          <admin-form
            v-model:admin="admin"
            @goTo="goTo"
          ></admin-form>
        </div>
        <div id="creating" :class="{'show': (showing == 3)}">
          <creating v-if="showing == 3" :channel_id="channel_id"></creating>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CREATE_TENAND from './mutations';
import { ErrorHandler } from '../../shared';

// Components
import TenantForm from './TenantCreator/TenantForm.vue';
import AdminForm from './TenantCreator/AdminForm.vue';
import Creating from './TenantCreator/Creating.vue';

export default {
  components: {
    TenantForm,
    AdminForm,
    Creating,
  },
  data() {
    return {
      showing: 1,
      logo: null,
      tenant: {
        name: null,
        domain: null,
        database: null,
        logo_url: null,
      },
      admin: {
        name: null,
        email: null,
        password: null,
      },
    };
  },
  computed: {
    channel_id() {
      return btoa(this.tenant.name);
    },
  },
  methods: {
    goTo(move, recaptcha) {
      this.showing += move;
      if (this.showing === 3) this.insert(recaptcha);
    },
    setFile(file) {
      this.logo = file;
    },
    insert(recaptcha) {
      if (this.logo) {
        // Add timestamp to avoid replacing files with same name, example CV.pdf
        const fileName = `company/${Date.now()}_${this.logo.name}`;
        this.is_uploading = true;
        // upload logo
        const params = { Key: fileName, Body: this.logo };
        this.$s3Upload(params).then(() => {
          this.tenant.logo_url = this.$s3Location(fileName);
          // insert into db
          this.$apollo.mutate({
            mutation: CREATE_TENAND,
            variables: {
              record: {
                ...this.tenant,
                user_name: this.admin.name,
                user_email: this.admin.email,
                user_password: this.admin.password,
                recaptcha,
              },
            },
          }).catch((error) => {
            ErrorHandler.handle(error, this);
            this.showing -= 1;
            this.$toasted.error('Something went wrong!');
          });
        });
      } else {
        this.showing = 1;
        this.$toasted.error('You must include a company logo');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  #TenantCreator > div { height: calc(100vh - 160px);}
  #TenantCreator > div > div.form {
    top: 0%; right: 0%; bottom: 0%;
  }
  #TenantCreator > div > div.down {
    transform: translateY(100vh);
  }
  #TenantCreator > div > div.down *, #TenantCreator > div > div.down * {
    display: none;
  }
  #TenantCreator > div > div#creating {
    opacity: 0;
    visibility: hidden;
    transition: .5s;
    z-index: -10;
  }
  #TenantCreator > div > div#creating.show {
    opacity: 1;
    visibility: visible;
  }
</style>
