import { gql } from '@apollo/client/core';

export default gql`
  fragment partner on Partner {
    id
    name
    type
    website
    notes
    contacts {
      id
      name
      email
      phone
      position
    }
    users(is_active: true) {
      id
      name
    }
  }
`;
