import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';

export default {
  data() {
    return {
      v$: useVuelidate(), // Inicialización de vuelidate
    };
  },
  validations: {
    localRecord: {
      model: { required },
      internal_id: { required },
      external_id: { required },
    },
  },
};
