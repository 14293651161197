import { gql } from '@apollo/client/core';

import PROJECT_MINUTE_FRAGMENT from './fragments';

export const CREATE_PROJECT_MINUTE = gql`
  mutation($projectMinute: ProjectMinuteInput!){
    createProjectMinute(input: $projectMinute){
      ...projectMinute
    }
  }
  ${PROJECT_MINUTE_FRAGMENT}
`;

export const UPDATE_PROJECT_MINUTE = gql`
  mutation($id:ID!, $projectMinute: ProjectMinuteInput!){
    updateProjectMinute(id: $id, input: $projectMinute){
      ...projectMinute
    }
  }
  ${PROJECT_MINUTE_FRAGMENT}
`;

export const DELETE_PROJECT_MINUTE = gql`
  mutation($id: ID!){
    deleteProjectMinute(id: $id){
      id
    }
  }
`;
