import { gql } from '@apollo/client/core';

import JOB_TITLE_FRAGMENT from './fragments';

export const CREATE_JOB_TITLE = gql`
  mutation($job_title: JobTitleInput!){
    createJobTitle(input: $job_title){
      ...job_title
    }
  }
  ${JOB_TITLE_FRAGMENT}
`;

export const UPDATE_JOB_TITLE = gql`
  mutation($id: ID!, $job_title: JobTitleInput!){
    updateJobTitle(id: $id, input: $job_title){
      ...job_title
    }
  }
  ${JOB_TITLE_FRAGMENT}
`;

export const DELETE_JOB_TITLE = gql`
  mutation($id: ID!){
    deleteJobTitle(id: $id){
      id
    }
  }
`;
