<template>
  <div class="mb-5 card">
    <div class="card-header d-flex">
      <div class="col p-0">
        <span v-if="!record.id">New Node</span>
      <span v-if="localRecord.id">{{localRecord.name}}</span>
      </div>
      <div class="col text-end">
        <i class="fas fa-times" @click="$emit('close')"></i>
      </div>
    </div>
    <div class="card-body">
      <div class="mb-3">
        <label for="name">Name</label>
        <input v-focus type="text" class="form-control" :class="{'is-invalid': v$.localRecord.name.$invalid}" id="name" v-model="localRecord.name">
        <div v-for="error of v$.localRecord.name.$silentErrors" :key="error.$uid">
          <div class="text-danger">{{ error.$message }}</div>
        </div>
      </div>
      <div class="mb-3">
        <label for="description">Description</label>
        <input type="hidden" id="description" v-model="localRecord.description">
          <trix-editor input="description" @trix-change="localRecord.description = $event.target.value; $forceUpdate()"
          placeholder="Node description here..."></trix-editor>
        <div v-for="error of v$.localRecord.description.$silentErrors" :key="error.$uid">
          <div class="text-danger">{{ error.$message }}</div>
        </div>
      </div>
      <div class="mb-3">
        <label for="evaluation_methodology">Evaluation Methodology:</label>
        <input type="hidden" id="evaluation_methodology" v-model="localRecord.evaluation_methodology">
          <trix-editor input="evaluation_methodology" @trix-change="localRecord.evaluation_methodology = $event.target.value; $forceUpdate()"
          placeholder="Evaluation Methodology here..."></trix-editor>
        <div v-for="error of v$.localRecord.evaluation_methodology.$silentErrors" :key="error.$uid">
          <div class="text-danger">{{ error.$message }}</div>
        </div>
      </div>
      <div class="mb-3">
        <label for="documentation_links">Documentation Links:</label>
        <textarea v-model="localRecord.documentation_links" id="documentation_links" class="form-control"  :placeholder="exampleDocumentationLinks" rows="3" :class="{'is-invalid': v$.localRecord.documentation_links.$invalid}"></textarea>
        <div v-for="error of v$.localRecord.documentation_links.$silentErrors" :key="error.$uid">
          <div class="text-danger">{{ error.$message }}</div>
        </div>
      </div>
      <button :disabled="v$.localRecord.$invalid" type="button" class="btn btn-company-primary" @click="save()">
        <i class="fas fa-save"></i>
        Save
      </button>
      <button type="button" class="btn btn-company-secondary" @click="$emit('close')">
        <i class="fas fa-times"></i>
        Cancel
      </button>
    </div>
  </div>
</template>

<script>

import Validations from './Validations';
import Blinkable from '../../../shared/Blinkable';

export default {
  mixins: [Blinkable, Validations],
  props: ['record'],
  data() {
    return {
      localRecord: _.cloneDeep(this.record),
    };
  },
  computed: {
    exampleDocumentationLinks() {
      return [
        'https://en.wikipedia.org/wiki/Software_development',
        'https://en.wikipedia.org/wiki/Programmer',
      ].join('\n');
    },
  },
  methods: {
    save() {
      const event = (this.localRecord.id) ? 'update' : 'insert';
      this.$emit(event, this.localRecord);
    },
  },
  watch: {
    record: {
      handler(newVal) {
        this.localRecord = _.cloneDeep(newVal);
        this.$nextTick(() => {
          const descriptionEditor = this.$el.querySelector('trix-editor[input="description"]');
          const evaluationMethodologyEditor = this.$el.querySelector('trix-editor[input="evaluation_methodology"]');
          if (descriptionEditor && this.localRecord.description) {
            descriptionEditor.editor.loadHTML(this.localRecord.description);
          }
          if (evaluationMethodologyEditor && this.localRecord.evaluation_methodology) {
            evaluationMethodologyEditor.editor.loadHTML(this.localRecord.evaluation_methodology);
          }
        });
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
