<template>
  <div>
    <nav>
      <div class="breadcrumb p-2">
        <div class="col-md-3 m-auto p-0">
          <ol class="breadcrumb p-0 m-auto">
            <li class="breadcrumb-item">
              <router-link to="/home">
                <i v-if="!$apollo.loading" class="fas fa-home"></i>
                <i v-if="$apollo.loading" class="fas fa-sync ms-1 fa-sm" :class="{'fa-spin': $apollo.loading}"></i>
              </router-link>
            </li>
            <li class="breadcrumb-item active fw-bold" aria-current="page">
              <router-link to="/performance_evaluation_forms">Performance Evaluation Forms</router-link>
            </li>
            <li class="breadcrumb-item fw-bold" aria-current="page" v-if="performance_evaluation_form">
              {{performance_evaluation_form.name}}
            </li>
          </ol>
        </div>
        <div class="col-md-9 text-end">
          <button type="button" class="btn btn-company-primary" v-if="$can('UpdatePerformanceEvaluationForm')" @click="update">
            <i v-if="!$apollo.loading" class="fas fa-save"></i>
            <i v-if="$apollo.loading" class="fas fa-sync ms-1 fa-sm" :class="{'fa-spin': $apollo.loading}"></i>
            Save
          </button>
        </div>
      </div>
    </nav>
    <ol class="list-group" v-if="performance_evaluation_form">
      <template v-for="(question, index) in sortedQuestions">
        <li :key="`q-${index}`" v-if="!question.deleted" class="h4 d-flex list-group-item d-flex justify-content-between align-items-center">
          <span class="col-10">{{question.name}}</span>
          <div class="col text-end">
            <Confirmation v-if="$can('UpdatePerformanceEvaluationForm')" @accepted="softRemove(question)"/>
          </div>
        </li>
      </template>
    </ol>
    <div class="row">
      <div class="col-11">
        <input v-focus type="text" class="form-control" v-model="selectedRecord.name" placeholder="Type Question Here..." v-on:keyup.enter="add">
      </div>
      <div class="col-1">
        <button class="btn btn-company-primary" @click="add" :disabled="selectedRecord.name === ''">
          <i class="fas fa-plus"></i>
          Add
        </button>
      </div>
    </div>
  </div>
</template>

<script>

import FORM_QUESTIONS_QUERY from './queries';
import RequestBuilder from '../RequestBuilder';
import ErrorHandler from '../../../shared/ErrorHandler';
import { UPDATE_PERFORMANCE_EVALUATION_FORM } from '../mutations';
import { Confirmation } from '../../shared';

export default {
  components: { Confirmation },
  apollo: {
    performance_evaluation_form: {
      query: FORM_QUESTIONS_QUERY,
      errorPolicy: 'all',
      variables() {
        return { id: this.$route.params.id };
      },
      error(error) {
        this.$toasted.error(error.message);
      },
    },
  },
  computed: {
    sortedQuestions() {
      return _.sortBy(this.questions, ['order']);
    },
    questions() {
      return this.performance_evaluation_form.questions;
    },
  },
  data() {
    return {
      selectedRecord: { performance_evaluation_form_id: this.$route.params.id, name: '', order: this.order() },
    };
  },
  methods: {
    softRemove(question) {
      const updatedQuestion = { ...question, deleted: true };
      this.performance_evaluation_form = {
        ...this.performance_evaluation_form,
        questions: this.performance_evaluation_form.questions.map((q) => (q.id === question.id ? updatedQuestion : q)),
      };
    },
    update() {
      this.$apollo.mutate({
        mutation: UPDATE_PERFORMANCE_EVALUATION_FORM,
        variables: {
          id: this.performance_evaluation_form.id,
          performance_evaluation_form: RequestBuilder.build(this.performance_evaluation_form),
        },
      }).catch((error) => {
        ErrorHandler.handle(error, this);
      }).then(() => {
        this.$toasted.success('Form questions were updated');
        this.$apollo.queries.performance_evaluation_form.refetch();
      });
    },
    order() {
      const current = _.max(_.compact(_.map((this.questions || []), 'order'))) || 0;
      return current + 1;
    },
    add() {
      if (this.selectedRecord.name === '') {
        return;
      }
      const updatedForm = { ...this.performance_evaluation_form };
      updatedForm.questions = [...updatedForm.questions, this.selectedRecord];
      this.performance_evaluation_form = updatedForm;
      this.selectedRecord = { performance_evaluation_form_id: this.$route.params.id, name: '', order: this.order() };
    },
  },
};
</script>
