import { gql } from '@apollo/client/core';

export default gql`
  query {
    profile: me {
      embed_reports{
        id
        title
        url
      }
    }
  }
`;
