import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);

const DatePlugin = {
  install(app) {
    app.config.globalProperties.$displayDate = ((value) => {
      if (!value) {
        return 'N/A';
      }
      return dayjs(value, 'YYYY-MM-DD').format('MMM D, YYYY');
    });
  },
};

export default DatePlugin;
