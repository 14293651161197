import { gql } from '@apollo/client/core';
import CLIENT_FRAGMENT from './fragments';

export default gql`
  query ($page: Int,
         $name: String,
         $is_prospect: Boolean,
         $orderBy: [OrderByClause!]) {
    clients(
      page: $page,
      name: $name,
      is_prospect: $is_prospect,
      orderBy: $orderBy
    ) @connection(key: "clients", filter: ["name", "is_prospect"]) {
      paginatorInfo {
        hasMorePages
      }
      data {
        ...client
        country {
          id
          name
        }
      }
    }
  }
  ${CLIENT_FRAGMENT}
`;
