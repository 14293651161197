import { gql } from '@apollo/client/core';

export default gql`
    mutation(
        $client_id: ID,
        $manager_id: ID,
        $orderBy: [OrderByClause!]
    ) {
        exportAccountDashboardToExcel(
            client_id: $client_id,
            manager_id: $manager_id,
            orderBy: $orderBy
        )
    }
`;
