<template>
<div>
  <nav>
    <div class="breadcrumb p-2">
      <div class="row">
        <div class="col-auto ms-3 p-0">
          <SpinnerRouterLink :loading="$apollo.loading">
            Revenue By Manager
          </SpinnerRouterLink>
        </div>
      </div>
      <div class="col-md-10 m-auto p-0">
        <div class="float-end">
          {{uniqueCountBy('client_id')}} Clients,
          {{uniqueCountBy('project_id')}} Projects,
          {{uniqueCountBy('manager_id')}} Managers
        </div>
      </div>
    </div>
  </nav>
  <div v-if="revenue_by_manager" class="row">
    <Empty v-if="!revenue_by_manager.length" icon="fa-solid fa-dollar-sign"/>
    <div :key="index" v-for="(row, index) in revenue_by_manager" class="col-xxl-4 col-xl-4 col-lg-6 col-md-6 col-sm-6">
      <div class="card mb-3">
        <div class="row g-0">
          <div class="col-md-4">
            <img :src="row.avatar" class="img-fluid rounded-start h-100 w-100" :alt="row.manager" >
          </div>
          <div class="col-md-8">
            <div class="card-body">
              <h3 class="card-title">{{row.manager}}</h3>
              <h4 class="card-title" title="Monthly Recurrent Revenue">${{$filters.formatNumber(row.recurrent_montly_revenue )}}</h4>
              <p class="card-text">
                <small class="text-body-secondary">Leads {{row.customers_in_charge}} customers, {{row.projects_in_charge}} projects and {{row.people_in_charge}} people.</small>
              </p>
              <router-link class="btn btn-company-primary" target="_blank" :to="{ path: '/reports/account_dashboard', query: { manager_id: row.manager_id }}">
                <i class="fas fa-search"></i>
                Details
              </router-link>
              <router-link class="btn btn-company-primary" target="_blank" :to="{ path: `/reports/revenue_by_manager/${row.manager_id}` }">
                <i class="fa-solid fa-chart-line"></i>
                History
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { SpinnerRouterLink } from '../../shared';
import ACCOUNT_MANAGEMENT from '../account_dashboard/queries';

export default {
  components: { SpinnerRouterLink },
  apollo: {
    account_management: {
      query: ACCOUNT_MANAGEMENT,
      errorPolicy: 'all',
      error(error) {
        this.$toasted.error(error.message);
      },
    },
  },
  methods: {
    uniqueCountBy(field) {
      return _.uniqBy(this.account_management, field).length;
    },
  },
  computed: {
    revenue_by_manager() {
      const managers = _.uniqBy(this.account_management, 'manager_id');
      return managers.map((m) => {
        const managerRows = _.filter(this.account_management, { manager_id: m.manager_id });
        const recurrentMontlyRevenue = _.sumBy(managerRows, 'rate');
        return {
          manager_id: m.manager_id,
          manager: m.manager,
          avatar: m.manager_avatar,
          recurrent_montly_revenue: recurrentMontlyRevenue,
          people_in_charge: managerRows.filter((row) => row.member_id !== m.manager_id).length,
          customers_in_charge: _.uniqBy(managerRows, 'client_id').length,
          projects_in_charge: _.uniqBy(managerRows, 'project_id').length,
        };
      });
    },
  },
};
</script>
