import { gql } from '@apollo/client/core';

import PROJECT_RISK_FRAGMENT from './fragments';

export const CREATE_PROJECT_RISK = gql`
  mutation($projectRisk: ProjectRiskInput!){
    createProjectRisk(input: $projectRisk){
      ...projectRisk
    }
  }
  ${PROJECT_RISK_FRAGMENT}
`;

export const UPDATE_PROJECT_RISK = gql`
  mutation($id:ID!, $projectRisk: ProjectRiskInput!){
    updateProjectRisk(id: $id, input: $projectRisk){
      ...projectRisk
    }
  }
  ${PROJECT_RISK_FRAGMENT}
`;

export const DELETE_PROJECT_RISK = gql`
  mutation($id: ID!){
    deleteProjectRisk(id: $id){
      id
    }
  }
`;
