<template>
  <div>
    <nav>
      <div class="breadcrumb p-2">
        <div class="row">
          <div class="col-auto ms-3 p-0">
            <SpinnerRouterLink :loading="$apollo.loading">Permission Matrix</SpinnerRouterLink>
          </div>
        </div>
      </div>
    </nav>
    <div class="h-100 scroll-x">
      <div v-if="$can('UpdateRoles')" class="col-md-12 mb-1 text-end">
        <button type="button" class="btn btn-company-primary" @click="save()">
          <i v-if="loading" class="fas fa-sync fa-spin"></i>
          <i v-if="!loading" class="fas fa-save"></i>
          Save
        </button>
      </div>
      <table class="table fs-5" v-if="roles">
        <thead>
          <tr>
            <th class="fs-5 d-print-table-cell">
              Permission
              <input v-focus type="search" class="form-control" v-model="search.name" placeholder="Search Permission...">
            </th>
            <th :key="role.id" v-for="role in roles" class="text-capitalize text-center movile-head-table">{{role.name.replace("_", " ")}}</th>
          </tr>
        </thead>
        <tbody>
          <tr :key="permission.id" v-for="permission in allPermissions">
            <td>{{ permission.name }}</td>
            <td :key="role.id" v-for="role in roles" class="text-center">
              <i class="fas" :class="hasPermission(role, permission) ? 'fa-check text-success' : 'fa-times text-danger'"
                @click="togglePermission(role, permission)"></i>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>

import { gql } from '@apollo/client/core';
import PERMISSION_MATRIX_QUERY from './queries';
import UPDATE_ROLES from './mutations';
import { SpinnerRouterLink } from '../shared';
import { ErrorHandler } from '../../shared';

export default {
  data() {
    return {
      search: { name: '' },
      permissionChanges: [],
      loading: false,
    };
  },
  components: { SpinnerRouterLink },
  computed: {
    allPermissions() {
      if (this.permissions) {
        if (this.search.name.trim() === '') {
          return this.permissions;
        }
        return _.filter(this.permissions, (p) => p.name.toLowerCase().includes(this.search.name.toLowerCase()));
      }
      return [];
    },
  },
  methods: {
    hasPermission(role, permission) {
      const findRole = _.find(this.roles, ['id', role.id]);
      return _.some(findRole.permissions, ['id', permission.id]);
    },
    togglePermission(role, permission) {
      const findRole = _.find(this.roles, ['id', role.id]);
      if (!findRole) return;

      // Create a copy of the `role` object and modify it without affecting the Proxy
      const updatedRole = {
        ...findRole,
        permissions: [...findRole.permissions],
      };

      // Modify the copied permissions
      if (_.some(updatedRole.permissions, ['id', permission.id])) {
        updatedRole.permissions = updatedRole.permissions.filter((p) => p.id !== permission.id);
      } else {
        updatedRole.permissions.push(permission);
      }

      // Create a new array of roles with the updated role
      this.roles = this.roles.map((r) => (r.id === role.id ? updatedRole : r));

      // Handle the list of permission changes
      const existingChange = _.find(this.permissionChanges, ['role_id', role.id]);
      if (existingChange) {
        existingChange.permissions = _.map(updatedRole.permissions, 'id');
        return;
      }
      this.permissionChanges.push({ role_id: role.id, permissions: _.map(updatedRole.permissions, 'id') });
    },
    save() {
      this.loading = true;
      this.$apollo.mutate({
        mutation: UPDATE_ROLES,
        variables: {
          upsertRoles: {
            roles: this.permissionChanges.map((p) => _.pick(p, ['role_id', 'permissions'])),
          },
        },
      }).then(() => {
        this.loading = false;
        this.$toasted.success('Permissions changes saved!');
        this.permissionChanges = [];
      }).catch((error) => {
        ErrorHandler.handle(error, this);
        this.loading = false;
      });
    },
  },
  apollo: {
    roles: {
      query: PERMISSION_MATRIX_QUERY,
      errorPolicy: 'all',
      error(error) {
        this.$toasted.error(error.message);
      },
    },
    permissions: {
      query: gql`query { permissions { id name } }`,
      errorPolicy: 'all',
      error(error) {
        this.$toasted.error(error.message);
      },
    },
  },
};
</script>
