import { gql } from '@apollo/client/core';

export const CREATE_SUGGESTION = gql`
    mutation($input: SuggestionInput!) {
        createSuggestion(input: $input) {
            id
            title
            suggestion
            status
        }
    }
`;

export const UPDATE_SUGGESTION = gql`
    mutation($id: ID!, $input: SuggestionInput!) {
        updateSuggestion(id: $id, input: $input) {
            id
            title
            suggestion
            status
        }
    }
`;

export const DELETE_SUGGESTION = gql`
    mutation($id: ID!) {
        deleteSuggestion(id: $id) {
            id
        }
    }
`;
