import { gql } from '@apollo/client/core';

export default gql`
  query($user_id: ID, $only_my_projects: Boolean){
    birthdays(only_my_projects: $only_my_projects, user_id: $user_id){
      name
      date_of_birth
      current_age
      next_birthday
    }
  }
`;
