import { gql } from '@apollo/client/core';

export default gql`
  fragment deliverable on Deliverable {
    id
    name
    color
    is_completed
    estimation_id
    estimation {
      id
      name
    }
  }
`;
