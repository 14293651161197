import { gql } from '@apollo/client/core';

export default gql`
  fragment attachments on Attachment {
    id
    name
    type
    url
    expires_at
  }
`;
