<template>
  <div>
    <nav>
      <div class="breadcrumb p-2">
        <div class="row">
          <div class="col-auto ms-3 p-0">
            <SpinnerRouterLink :loading="$apollo.loading">Pending Evaluations</SpinnerRouterLink>
          </div>
        </div>
        <div class="container-fluid">
          <div class="row">
            <div class="col-12 col-sm-6 mt-2">
              <Search v-model="search" :inputs="['users']"></Search>
            </div>
          </div>
        </div>
      </div>
    </nav>

    <template v-if="show_learning_path_level_nodes_for_evaluations">
      <div class="row">
        <Card v-for="record in show_learning_path_level_nodes_for_evaluations.data" :key="record.user_id"
        :record="record" />
        <div class="col-md-12 text-center" v-if="show_learning_path_level_nodes_for_evaluations.data.length === 0">
          <p>No records found</p>
        </div>
      </div>
      <SkeletonCards
      v-if="show_learning_path_level_nodes_for_evaluations && show_learning_path_level_nodes_for_evaluations.paginatorInfo.hasMorePages"
      :cards=4 :executeScroll="show_learning_path_level_nodes_for_evaluations != undefined"
      @showMore="showMore('show_learning_path_level_nodes_for_evaluations')"></SkeletonCards>
    </template>
  </div>
</template>

<script>
import LEARNING_PATH_LEVEL_NODE_USER_QUERY from './queries';
import Card from './Card.vue';
import { Search, SpinnerRouterLink, SkeletonCards } from '../shared';
import { PaginationHandler, SearchBuilder } from '../../shared';

export default {
  extends: PaginationHandler,
  components: {
    Search,
    Card,
    SpinnerRouterLink,
    SkeletonCards,
  },
  apollo: {
    show_learning_path_level_nodes_for_evaluations: {
      query: LEARNING_PATH_LEVEL_NODE_USER_QUERY,
      errorPolicy: 'all',
      variables() {
        return { page: 1, user_id: this.$route.params.id, ...SearchBuilder.build(this.search) };
      },
      error(error) {
        this.$toasted.error(error.message);
      },
      loading() {
        this.loading = true;
      },
    },
  },
  data() {
    return {
      search: {},
    };
  },
  methods: {
    queryVariables() {
      return { user_id: parseInt(this.$route.params.id, 10), ...SearchBuilder.build(this.search) };
    },
    refresh() {
      this.$apollo.queries.show_learning_path_level_nodes_for_evaluations.refetch();
    },
  },
};
</script>
