<template>
  <div class="d-flex justify-content-center mt-5">
    <div style="width: 30rem; max-width: 99vw">
      <div>
        <div class="form-group text-center m-0">
          <h3 class="h2"><b>Register Company Information</b></h3>
        </div><br>
        <div class="form-group">
          <DropArea :message="'Drop Logo here'" @drop="setFile" />
        </div>
        <div class="form-group fs-5">
          <label class="position-absolute ms-xxl-2 mt-xl-2" for="tenat_name"><b>Name:</b></label>
          <input type="text" placeholder="My Company Name" class="form-control fs-5" :class="{'is-invalid': v$.tenant.name.$invalid}" v-model="tenant.name" @input="autoValues">
          <div v-for="error of v$.tenant.name.$silentErrors" :key="error.$uid">
            <div class="text-danger">{{ error.$message }}</div>
          </div>
        </div>
        <div class="row w-100 mx-auto">
          <button :disabled="v$.tenant.$invalid" type="button" class="fs-5 btn btn-company-primary btn-block" @click="goTo(1)">
            Next
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { useVuelidate } from '@vuelidate/core';
import { required, maxLength } from '@vuelidate/validators';
import { DropArea } from '../../shared';
import { ALLOWED_LOGO_TYPES } from '../../../shared/AllowedFileTypes';

export default {
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      tenant: {
        name: { required, maxLengthValue: maxLength(255) },
      },
    };
  },
  props: ['tenant'],
  components: { DropArea },
  watch: {
    localTenant: {
      deep: true,
      handler(newValue) {
        if (!_.isEqual(newValue, this.tenant)) {
          this.$emit('update:tenant', newValue);
        }
      },
    },
    tenant: {
      deep: true,
      handler(newValue) {
        if (!_.isEqual(newValue, this.localTenant)) {
          this.localTenant = _.cloneDeep(newValue);
        }
      },
    },
  },
  methods: {
    autoValues() {
      this.tenant.domain = (this.tenant.name).trim().replace(/ /g, '').toLowerCase();
      this.tenant.database = (this.tenant.name).trim().replace(/ /g, '_').toLowerCase();
    },
    setFile(file) {
      const fileExtension = file.name.split('.').pop();
      if (ALLOWED_LOGO_TYPES.indexOf(fileExtension) === -1) {
        this.$toasted.error(`The logo extension is not supported. Only supports: ${ALLOWED_LOGO_TYPES}`);
      } else {
        this.$emit('setFile', file);
      }
    },
    goTo($move) {
      this.$emit('goTo', $move);
    },
  },
};
</script>

<style lang="scss" scoped>
input {
    padding-left: 4.6rem;
}
</style>
