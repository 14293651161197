import { gql } from '@apollo/client/core';

export default gql`
  query($page: Int!,
        $first: Int,
        $status: String,
        $name: String,
        $type: String,
        $state: String,
        $client_id: ID,
        $action_status: String,
        $description: String,
        $only_my_projects: Boolean,
        $user_id: ID,
        $orderBy: [OrderByClause!]) {
    projects(
      page: $page,
      first: $first,
      name: $name,
      type: $type,
      state: $state,
      client_id: $client_id,
      status: $status,
      description: $description,
      only_my_projects: $only_my_projects,
      user_id: $user_id,
      orderBy: $orderBy) @connection(key: "projects_summary", filter: ["name", "client_id", "status", "type", "state", "only_my_projects", "user_id"]) {
      paginatorInfo {
        hasMorePages
      }
      data {
        id
        name
        type
        end_date
        last_known_net_promoter_score
        status
        managers {
          user {
            id
            name
          }
        }
        client {
          id
          name
        }
        actions(status: $action_status){
          id
          status
        }
      }
    }
  }
`;
