<template>
  <div class="mb-5 card">
    <div class="card-header d-flex">
      <div class="col p-0">
        <span v-if="!localRecord.id">New Partner</span>
        <span v-else>{{localRecord.name}}</span>
      </div>
      <div class="col text-end">
        <i class="fas fa-times" @click="$emit('close')"></i>
      </div>
    </div>
    <div class="card-body d-grid">
      <div class="row">
        <div class="mb-3 col-md-4">
          <label for="description">Name</label>
          <input v-focus type="text" class="form-control" :class="{'is-invalid': v$.localRecord.name.$invalid}" id="name" v-model="localRecord.name">
          <div v-for="error of v$.localRecord.name.$silentErrors" :key="error.$uid">
            <div class="text-danger">{{ error.$message }}</div>
          </div>
        </div>
        <div class="mb-3 col-md-4">
          <label for="type">Type</label>
          <select id="type" class="form-control form-select" v-model="localRecord.type" :class="{'is-invalid': v$.localRecord.type.$invalid}">
            <option>Consultant</option>
            <option>Recruitment</option>
          </select>
          <div v-for="error of v$.localRecord.type.$silentErrors" :key="error.$uid">
            <div class="text-danger">{{ error.$message }}</div>
          </div>
        </div>
        <div class="mb-3 col-md-4">
          <label for="website">Website</label>
          <input type="text" class="form-control" :class="{'is-invalid': v$.localRecord.website.$invalid}" id="website" v-model="localRecord.website">
          <div v-for="error of v$.localRecord.website.$silentErrors" :key="error.$uid">
            <div class="text-danger">{{ error.$message }}</div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="mb-3 col-md-12">
          <label for="notes">Notes:</label>
          <trix-editor @trix-change="localRecord.notes = $event.target.value; $forceUpdate()"
          placeholder="Notes here...."></trix-editor>
        </div>
      </div>
      <div class="row">
        <div class="mb-3 col-md-12">
          <ContactPicker :contacts="localRecord.contacts">Contacts:</ContactPicker>
        </div>
      </div>
      <button :disabled="v$.localRecord.$invalid" type="button" class="btn btn-company-primary" @click="save()">
        <i class="fas fa-save"></i>
        Save
      </button>
      <button type="button" class="btn btn-company-secondary" @click="$emit('close')">
        <i class="fas fa-times"></i>
        Cancel
      </button>
    </div>
  </div>
</template>

<script>

import Validations from './Validations';
import Blinkable from '../../shared/Blinkable';
import ContactPicker from '../contactables/Picker.vue';

export default {
  mixins: [Blinkable, Validations],
  components: { ContactPicker },
  props: ['record'],
  data() {
    return {
      localRecord: _.cloneDeep(this.record),
    };
  },
  methods: {
    save() {
      const event = (this.localRecord.id) ? 'update' : 'insert';
      this.$emit(event, this.localRecord);
    },
    mapContacts(contacts) {
      return {
        delete: (contacts || []).filter((contact) => contact.deleted).map((contact) => contact.id),
        upsert: (contacts || []).filter((contact) => !contact.id).map((contact) => ({
          name: contact.name,
          position: contact.position,
          email: contact.email,
          phone: contact.phone,
          notes: contact.notes,
        })).concat(
          (contacts || []).filter((contact) => contact.id)
            .map((contact) => ({
              id: contact.id,
              name: contact.name,
              position: contact.position,
              email: contact.email,
              phone: contact.phone,
              notes: contact.notes,
            })),
        ),
      };
    },
  },
  watch: {
    record: {
      handler(newVal) {
        this.localRecord = _.cloneDeep(newVal);
        this.$nextTick(() => {
          const trix = this.$el.querySelector('trix-editor');
          if (trix && this.localRecord.notes) {
            trix.editor.loadHTML(this.localRecord.notes);
          }
        });
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
