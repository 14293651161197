<template>
<div :id="record.id"  class="col-md-4 pb-2">
  <Form v-if="is_editting" v-bind:record="record"
                            @close="is_editting = false"
                            @update="update"
                            ></Form>
  <div v-if="!is_editting" class="card h-100">
    <div class="card-body p-1 pt-3">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="w-50 float-start">
              <h5 class="card-title">{{record.name}}</h5>
              <div><h6 class="card-title mb-2 text-body-secondary" v-if="record.project && !on_opportunities">{{record.project.name}}</h6></div>
              <div><h6 class="card-title mb-2 text-body-secondary" v-if="record.client && !on_opportunities">{{record.client.name}}</h6></div>
            </div>
            <h6 class="text-success float-end fw-bolder" v-if="record.taken == 'Accepted'">Accepted</h6>
            <h6 class="text-info float-end fw-bolder" v-if="record.taken == 'Open'">Open</h6>
            <h6 class="text-danger float-end fw-bolder" v-if="record.taken == 'Rejected'">Rejected</h6>
          </div>
          <div class="accordion accordion-flush pb-1">
            <div>
              <div>
                <div class="card-body card-row pt-0 pb-0 bg-white">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="w-50 float-start">
                        <p class="mb-0"><strong>English Level:</strong>
                          <span v-if="record.english_level.indexOf('A') >-1" class="badge bg-company-third-ligth" >
                            {{record.english_level}}
                          </span>
                          <span v-if="record.english_level.indexOf('B') >-1" class="badge bg-company-secondary-ligth" >
                            {{record.english_level}}
                          </span>
                          <span v-if="record.english_level.indexOf('C') >-1" class="badge bg-company-fiveth-ligth" >
                            {{record.english_level}}
                          </span>
                        </p>
                      </div>
                      <div class="w-50 float-start">
                        <div class="col-md-12">
                          <p class="mb-0"><strong>Positions:</strong>
                            <span>{{ record.positions_amount }}</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-body card-row pt-0 pb-0 bg-white">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="w-50 float-start">
                        <p class="mb-0"><strong>Budget: </strong>
                          <span class="bg-company-primary-ligth" >${{ record.budget }}</span>
                        </p>
                      </div>
                      <div class="w-50 float-start">
                        <div class="col-md-12">
                          <p class="mb-0"><strong>State:</strong>
                            <span class="badge bg-company-third-ligth " v-if="record.state">Hot</span>
                            <span class="badge bg-company-secondary-ligth " v-if="!record.state">Cold</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-body card-row pt-0 pb-0 bg-white">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="w-50 float-start">
                        <p class="mb-0"><strong>Start date: </strong>
                          <span>{{$displayDate(record.begins)}}</span>
                        </p>
                      </div>
                      <div class="w-50 float-start">
                        <div class="col-md-12">
                          <p class="mb-0"><strong>Duration:</strong>
                            <span>  {{ record.duration }}</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-body card-row pt-0 pb-0 bg-white">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="w-50 float-start">
                        <p class="mb-0"><strong>Seniority: </strong>
                          <span class="badge bg-company-third-ligth" v-if="record.seniority == 'Junior'">{{ record.seniority }}</span>
                          <span class="badge bg-company-fiveth-ligth" v-if="record.seniority == 'Mid'">{{ record.seniority }}</span>
                          <span class="badge bg-company-secondary-ligth" v-if="record.seniority == 'Senior'">{{ record.seniority }}</span>
                        </p>
                      </div>
                      <div v-if="record.country" class="float-start">
                        <span  :title="record.country.name">Location:   </span>
                         {{ record.country.name }} {{flag}}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-body card-row pt-0 pb-0 bg-white">
                  <div class="row">
                    <div class="col-md-12">
                      <strong>Technologies:  </strong>
                      <span :key="technology.id" v-for="technology in record.technologies" class="badge bg-company-secondary-ligth me-1">{{technology.name}}</span>
                    </div>
                  </div>
                </div>
                <div class="card-body card-row pt-0 pb-0 bg-white">
                  <div class="row">
                    <div class="col-md-12">
                      <p class="mb-0" v-if="record.notes"><strong>Notes:</strong>
                        <span v-html="record.notes"></span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="accordion-item border border-0">
                <div class="accordion-header custom-accordion p-0">
                  <h2 class="mb-0">
                    <button class="btn accordion-button collapsed py-2 px-3" type="button" data-bs-toggle="collapse" :data-bs-target="`#p-${record.id}a`" aria-expanded="true" :aria-controls="`p-${record.id}a`" @click="is_description_visible=!is_description_visible">
                      Description
                    </button>
                  </h2>
                </div>
                <div :id="`p-${record.id}a`" class="accordion-collapse collapse">
                  <div class="accordion-body" v-html="record.description"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-footer" v-if="on_opportunities">
      <button v-if="!confirmation && $can('UpdateOpportunity')" class="btn btn-company-primary mb-1" @click="is_editting = true">
        <i class="fas fa-pen"></i>
        Edit
      </button>
      <Confirmation v-if="$can('DeleteOpportunity')"
        v-model="confirmation"
        @accepted="remove()"
      />
    </div>
    <div class="card-footer bg-light" v-if="record.taken == 'Open'">
      <div class="row">
        <button v-if="!to_take && $can('UpdateOpportunity')" class="w-100 btn" @click="to_take = true">
          ADMINISTRATE
        </button>
        <button v-if="to_take" class="col btn bg-success text-white" @click="updateTaken('Accepted')">
          Accept
        </button>
        <button v-if="to_take" class="col btn bg-danger text-white" @click="updateTaken('Rejected')">
          Reject
        </button>
        <button v-if="to_take" class="col btn bg-secondary text-white" @click="to_take = false">
          Cancel
        </button>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import Form from './Form.vue';
import { UPDATE_OPPORTUNITY } from './mutations';
import ErrorHandler from '../../shared/ErrorHandler';
import { Confirmation } from '../shared';
import RequestBuilder from './RequestBuilder';
import { Flags } from '../../shared';

export default {
  components: { Form, Confirmation },
  props: ['record', 'on_opportunities'],
  computed: {
    flag() {
      return Flags[this.record.country.name];
    },
  },
  data() {
    return {
      is_notes_visible: false,
      is_internal_description_visible: false,
      is_description_visible: false,
      is_editting: false,
      confirmation: false,
      to_take: false,
    };
  },
  methods: {
    update(opportunity) {
      this.$apollo.mutate({
        mutation: UPDATE_OPPORTUNITY,
        variables: {
          id: parseInt(opportunity.id, 10),
          opportunity: RequestBuilder.build(opportunity),
        },
      }).then(() => {
        this.is_editting = false;
        this.$emit('refresh');
      }).catch((error) => {
        ErrorHandler.handle(error, this);
      });
    },
    remove() {
      const updatedRecord = { ...this.record };
      updatedRecord.technologies = [];
      this.update(updatedRecord);
      this.$emit('remove', updatedRecord);
      this.is_editting = false;
      this.confirmation = false;
    },
    updateTaken(newValue) {
      this.confirmation = false;
      const updatedRecord = { ...this.record, taken: newValue };
      this.update(updatedRecord);
    },
  },
};
</script>
