<template>
  <div>
    <nav>
      <div class="breadcrumb p-2">
        <div class="row">
          <div class="col-auto ms-3 p-0">
            <SpinnerRouterLink :loading="$apollo.loading">Contracts</SpinnerRouterLink>
          </div>
        </div>
      </div>
    </nav>
    <div v-if="projectAttachments" class="row">
      <Empty v-if="!projectAttachments.data.length" icon="fas fa-project-diagram"></Empty>
      <Card v-for="record in projectAttachments.data" :key="record.id" :record="record"></Card>
    </div>
  </div>
</template>

<script>
import { SpinnerRouterLink } from '../../shared';
import PROJECT_ATTACHMENTS from './queries';
import Card from './Card.vue';

export default {
  components: {
    SpinnerRouterLink, Card,
  },
  apollo: {
    projectAttachments: {
      query: PROJECT_ATTACHMENTS,
      errorPolicy: 'all',
      variables() {
        return { ...this.search };
      },
      error(error) {
        this.$toasted.error(error.message);
      },
    },
  },
  data() {
    return {
      search: { state: 'In Progress' },
    };
  },
};
</script>
