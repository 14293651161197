<template>
  <div>
    <Form v-if="selectedRecord" v-bind:record="selectedRecord" @close="selectedRecord = null" @insert="insert"></Form>
    <nav>
      <div class="breadcrumb p-2">
        <div class="row">
          <div class="col-auto ms-3 p-0">
            <ol class="breadcrumb p-0 m-auto">
              <li class="breadcrumb-item">
                <router-link to="/home">
                  <i class="fas fa-home"></i>
                </router-link>
              </li>
              <li class="breadcrumb-item active fw-bold" aria-current="page" v-if="entity.entity_type">
                <router-link :to='entity.parent_url'>{{ entity.entity_type }}</router-link>
              </li>
              <li class="breadcrumb-item fw-bold" aria-current="page" v-if="entity.entity_type">
                <router-link :to="`${entity.parent_url}?name=${parent?.name}`">{{ parent?.name }}</router-link>
              </li>
              <li class="breadcrumb-item fw-bold" aria-current="page">
                Opportunities
              </li>
            </ol>
          </div>
        </div>
        <div class="container-fluid">
          <div class="row">
            <div class="col-12 m-auto">
              <Search
                v-model="search"
                :inputs="['name']"
              ></Search>
            </div>
            <div class="col order-last text-end">
              <button type="button" class="btn  btn-company-primary"
                @click="newRecord()" v-if="entity.entity_type && $can('CreateOpportunity')">
                <i class="fas fa-plus"></i>
                New
              </button>
            </div>
          </div>
        </div>
      </div>
    </nav>
    <div class="row" v-if="opportunities">
      <Card :key="record.id" v-for="record in opportunities.data" :record="record" :on_opportunities="entity.entity_type" @remove="remove"></Card>
      <Empty v-if="!opportunities.data.length" :can_create="entity.entity_type" @callCreate="newRecord" icon="fa-solid fa-hands-holding-circle"/>
    </div>
    <SkeletonCards
      v-if="opportunities && opportunities.paginatorInfo.hasMorePages"
      :cards=3 :executeScroll="opportunities != undefined"
      @showMore="showMore('opportunities')"
    ></SkeletonCards>
  </div>
</template>

<script>
import { gql } from '@apollo/client/core';
import { CLIENTS_OPPORTUNITIES, PROJECTS_OPPORTUNITIES, OPPORTUNITIES_VIEW } from './queries';
import { Search, SkeletonCards } from '../shared';
import Form from './Form.vue';
import { CREATE_OPPORTUNITY, DELETE_OPPORTUNITY } from './mutations';
import { ErrorHandler, SearchBuilder, PaginationHandler } from '../../shared';
import Card from './Card.vue';
import RequestBuilder from './RequestBuilder';

export default {
  extends: PaginationHandler,
  data() {
    return {
      search: {},
      selectedRecord: null,
    };
  },
  components: {
    Form, Card, Search, SkeletonCards,
  },
  computed: {
    entity() {
      const entityTypes = {
        project_opportunities: {
          model: 'Project',
          parent_url: '/projects',
          query: gql`query($id: ID!) {parent: project(id: $id) { id name }}`,
          get_query: PROJECTS_OPPORTUNITIES,
          permissions: 'CreateOpportunities',
        },
        client_opportunities: {
          model: 'Client',
          parent_url: '/clients',
          query: gql`query($id: ID!) {parent: client(id: $id) { id name }}`,
          get_query: CLIENTS_OPPORTUNITIES,
          permissions: 'CreateOpportunities',
        },
      };
      if (this.$route.params.id) {
        return {
          entity_type: entityTypes[this.$route.name].model,
          parent_url: entityTypes[this.$route.name].parent_url,
          permissions: entityTypes[this.$route.name].permissions,
          query: entityTypes[this.$route.name].query,
          get_query: entityTypes[this.$route.name].get_query,
        };
      }
      return {
        entity_type: false,
        permissions: 'CreateOpportunities',
        query: gql`query {parent: opportunity @skip(if: true) { id }}`,
        get_query: OPPORTUNITIES_VIEW,
      };
    },
  },
  apollo: {
    parent: {
      query() {
        return this.entity.query;
      },
      errorPolicy: 'all',
      variables() {
        if (this.$route.params.id) {
          return { id: this.$route.params.id };
        }
        return {};
      },
      error(error) {
        this.$toasted.error(error.message);
      },
    },
    opportunities: {
      query() {
        return this.entity.get_query;
      },
      variables() {
        if (this.$route.params.id) {
          return { page: 1, id: this.$route.params.id, ...SearchBuilder.build(this.search) };
        }
        return { page: 1, ...SearchBuilder.build(this.search) };
      },
      errorPolicy: 'all',
      fetchPolicy: 'network-only',
      error(error) {
        this.$toasted.error(error.message);
      },
    },
  },
  methods: {
    queryVariables() {
      return SearchBuilder.build(this.search);
    },
    newRecord() {
      this.selectedRecord = {
        technologies: [],
      };
    },
    insert(opportunity) {
      // We save the user input in case of an error
      const newOpportunity = opportunity;
      if (this.entity.entity_type === 'Project') {
        opportunity.project_id = this.$route.params.id;
      }
      if (this.entity.entity_type === 'Client') {
        opportunity.client_id = this.$route.params.id;
      }
      this.selectedRecord = null;
      this.$apollo.mutate({
        mutation: CREATE_OPPORTUNITY,
        variables: {
          opportunity: RequestBuilder.build(opportunity),
        },
        update: (store, { data: { createOpportunity } }) => {
          const data = store.readQuery({ query: this.entity.get_query, variables: this.queryVariables() });

          const updatedOpportunities = [createOpportunity, ...data.opportunities.data];

          store.modify({
            fields: {
              opportunities(existingOpportunities = { data: [] }) {
                return {
                  ...existingOpportunities,
                  data: updatedOpportunities || [],
                };
              },
            },
          });
        },
      }).catch((error) => {
        ErrorHandler.handle(error, this);
        // We restore the initial user input
        this.selectedRecord = newOpportunity;
      });
    },
    refresh() {
      this.$apollo.queries.opportunities.refetch();
    },
    remove(opportunity) {
      this.$apollo.mutate({
        mutation: DELETE_OPPORTUNITY,
        variables: {
          id: opportunity.id,
        },
        update: (store, { data: { deleteOpportunity } }) => {
          const data = store.readQuery({ query: this.entity.get_query, variables: this.queryVariables() });
          const updatedOpportunities = data.opportunities.data.filter((o) => o.id !== deleteOpportunity.id);

          store.modify({
            fields: {
              opportunities(existingOpportunities = { data: [] }) {
                return {
                  ...existingOpportunities,
                  data: updatedOpportunities || [],
                };
              },
            },
          });
        },
      }).catch((error) => {
        this.$toasted.error(`Unable to delete record: ${error.message}`);
      });
    },
  },
};
</script>
