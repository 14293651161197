<template>
  <div class="form-inline">
    <div class="company-control">
      <label for="model">Model</label>
      <select id="model" v-model="localSearch.model" class="form-control form-select" @change="updateFilter">
        <option :value="''">Any</option>
        <option :key="value" v-for="value in models" :value="value">{{value}}</option>
      </select>
    </div>
  </div>
</template>

<script>
import Searchable from '../../../shared/Searchable';

export default {
  extends: Searchable,
  props: {
    models: Array,
    modelValue: { type: Object, default: () => ({}) },
  },
  data() {
    return {
      localSearch: { ...this.modelValue, model: this.modelValue.model || '' },
    };
  },
};
</script>
