import { gql } from '@apollo/client/core';

export default gql`
query($page: Int, $client_id: ID!){
  users(page: $page, client_id: $client_id, exclude_client_users: false)
  @connection(key: "client_users", filter: ["client_id"]){
    paginatorInfo {
      hasMorePages
    }
    data{
      id
      name
      client_id
      email
    }
  }
}`;
