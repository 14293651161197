<template>
  <div class="company-modal">
  <div class="mb-5 card">
    <div class="card-header d-flex">
      <div class="col-6 p-0">
        <span v-if="!record.id">New Holiday</span>
        <span v-if="record.id">{{localRecord.name}}</span>
      </div>
      <div class="col text-end">
        <i class="fas fa-times" @click="$emit('close')"></i>
      </div>
    </div>
    <div class="card-body d-grid">
      <div class="mb-3">
        <div class="company-control">
          <label for="name">Name</label>
          <input v-focus type="text" class="form-control" :class="{'is-invalid': v$.localRecord.name.$invalid}" id="name" v-model="localRecord.name">
          <div v-for="error of v$.localRecord.name.$silentErrors" :key="error.$uid">
            <div class="text-danger">{{ error.$message }}</div>
          </div>
        </div>
      </div>
      <div class="mb-3">
        <div class="company-control">
          <label for="date">Date</label>
          <DatePicker :date="localRecord.date"
                      placeholder="Select Date"
                      :css_class="{'is-invalid': v$.localRecord.date.$invalid}"
                      @change="localRecord = { ...localRecord, date: $event }"></DatePicker>
          <div v-for="error of v$.localRecord.date.$silentErrors" :key="error.$uid">
            <div class="text-danger">{{ error.$message }}</div>
          </div>
        </div>
      </div>
      <div class="mb-3">
        <Select v-model="localRecord.country_id"
                fieldToShow="name"
                :name="`country_id`"
                :query="countriesQuery"
                :customCssClass="'w-100'"
                :queryVariables="{ type: 'Country', orderBy: [{ column: 'name', order: 'ASC' }] }">Country</Select>
        <div v-for="error of v$.localRecord.country_id.$silentErrors" :key="error.$uid">
          <div class="text-danger">{{ error.$message }}</div>
        </div>
      </div>
      <button :disabled="v$.localRecord.$invalid" type="button" class="btn btn-company-primary" @click="save()">
        <i class="fas fa-save"></i>
        Save
      </button>
      <button type="button" class="btn btn-company-secondary" @click="$emit('close')">
        <i class="fas fa-times"></i>
        Cancel
      </button>
    </div>
  </div>
</div>
</template>

<script>

import Validations from './Validations';
import { DROPDOWNS, Blinkable } from '../../../shared';
import { Select } from '../../shared';

export default {
  mixins: [Blinkable, Validations],
  components: { Select },
  props: ['record'],
  data() {
    return {
      countriesQuery: DROPDOWNS,
      localRecord: _.cloneDeep(this.record),
    };
  },
  methods: {
    save() {
      const event = (this.localRecord.id) ? 'update' : 'insert';
      this.$emit(event, this.localRecord);
    },
  },
};
</script>
