import { gql } from '@apollo/client/core';

export default gql`
  fragment badge on Badge {
    id
    name
    description
    image_url
  }
`;
