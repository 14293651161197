<template>
  <div>
    <nav>
      <div class="breadcrumb p-2">
        <div class="row">
          <div class="col-auto ms-3 p-0">
            <SpinnerRouterLink :loading="$apollo.loading">
              Account Dashboard
            </SpinnerRouterLink>
          </div>
        </div>
        <div class="col-md-10 m-auto p-0">
          <div class="float-end">
            {{uniqueClientIds.length}} Clients,
            {{uniqueCountBy('project_id')}} Projects,
            {{uniqueMembers.length}} People,
            {{uniqueCountBy('manager_id')}} Managers
            <button class="btn btn-company-primary" @click="exportToExcel">
              <i v-if="is_exporting" class="fas fa-sync fa-spin"></i>
              <i v-if="!is_exporting" class="fa fa-file-excel"></i>
              Export
            </button>
          </div>
        </div>
        <div class="col-12 m-auto">
          <Search
            v-model="search"
            :inputs="['clients']"
          ></Search>
        </div>
      </div>
    </nav>
    <div class="row" v-if="account_management">
      <div class="col-md-12 scroll-x h-100">
        <Empty v-if="!uniqueClientIds.length" icon="fa-solid fa-house-medical"/>
        <table v-if="uniqueClientIds.length" id="account_dashboard_table" class="table table-bordered">
          <thead>
            <tr>
              <th class="d-table-cell">Account Name</th>
              <th class="d-table-cell">Account Type</th>
              <th class="d-table-cell">Project Name</th>
              <th class="d-table-cell">Status</th>
              <th class="d-table-cell">Manager</th>
              <th class="d-table-cell">End Date</th>
              <th class="d-table-cell">Member</th>
              <th class="d-table-cell">Title</th>
              <th class="d-table-cell" title="Monthly Cost">M.Cost</th>
              <th class="d-table-cell" title="Monthly Rate">M.Rate</th>
              <th class="text-center d-table-cell" colspan="3">Gross Margin</th>
              <th class="text-center d-table-cell" colspan="2">Members</th>
            </tr>
          </thead>
          <tbody>
            <Row :key="index"
                :rowsByClient="rowsByClient(client_id)"
                :configuration="configuration"
                :client_id="client_id"
                v-for="(client_id, index) in uniqueClientIds"></Row>
            <tr>
              <td class="text-end fw-bold" colspan="8">Total:</td>
              <td class="fw-bold">${{ $filters.formatNumber(totalBy('cost')) }}</td>
              <td class="fw-bold">${{ $filters.formatNumber(totalBy('rate'))}}</td>
              <td class="text-center fw-bold" colspan="3">
                <MarginColor :value="globalGrossMargin" :configuration="configuration"></MarginColor>
              </td>
              <td class="text-center fw-bold" colspan="2">
                {{uniqueCountBy('member_id')}}
              </td>
            </tr>
            <tr v-if="uniqueCountBy('member_id') > 0">
              <td class="fw-bold text-end" colspan="8">
                Avg per resource:
              </td>
              <td class="fw-bold">
                ${{ $filters.formatNumber((totalBy('cost') / uniqueCountBy('member_id')).toFixed(2) )}}
              </td>
              <td class="fw-bold">
                ${{ $filters.formatNumber((totalBy('rate') / uniqueCountBy('member_id')).toFixed(2))}}
              </td>
              <td colspan="5"></td>
            </tr>
            </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import ACCOUNT_MANAGEMENT from './queries';
import EXPORT_ACCOUNT_MANAGEMENT from './mutations';
import { CONFIGURATION } from '../../configuration/queries';
import { Search, SpinnerRouterLink } from '../../shared';
import { SearchBuilder, Base64File } from '../../../shared';
import Row from './Row.vue';
import MarginColor from './MarginColor.vue';

export default {
  components: {
    Search, SpinnerRouterLink, Row, MarginColor,
  },
  data() {
    return {
      search: {},
      orderBy: [
        { column: 'client_id', order: 'ASC' },
        { column: 'project_id', order: 'ASC' },
      ],
      is_exporting: false,
    };
  },
  computed: {
    uniqueClientIds() {
      return _.uniq((this.account_management ?? []).map((row) => row.client_id));
    },
    uniqueMembers() {
      const people = (this.account_management ?? []).map((row) => row.member_id).concat(
        (this.account_management ?? []).map((row) => row.user_id),
      );
      return _.uniq(people);
    },
    globalGrossMargin() {
      const cost = this.totalBy('cost');
      const rate = this.totalBy('rate');
      const grossMargin = ((rate - cost) / rate) * 100;
      return grossMargin.toFixed(2);
    },
  },
  methods: {
    totalBy(field) {
      return _.sumBy((this.account_management ?? []), field);
    },
    uniqueCountBy(field) {
      return _.uniq((this.account_management ?? []).map((row) => row[field])).length;
    },
    rowsByClient(clientId) {
      return (this.account_management ?? []).filter((row) => row.client_id === clientId);
    },
    exportToExcel() {
      this.is_exporting = true;
      this.$apollo.mutate({
        mutation: EXPORT_ACCOUNT_MANAGEMENT,
        variables: {
          orderBy: this.orderBy,
          ...SearchBuilder.build(this.search),
        },
      }).then((res) => {
        const base64File = res.data.exportAccountDashboardToExcel;
        Base64File.download(base64File, 'account_dashboard.xlsx');
      }).catch((error) => {
        this.$toasted.error(error.message);
      }).finally(() => {
        this.is_exporting = false;
      });
    },
  },
  apollo: {
    account_management: {
      query: ACCOUNT_MANAGEMENT,
      errorPolicy: 'all',
      variables() {
        return { orderBy: this.orderBy, ...SearchBuilder.build(this.search) };
      },
      error(error) {
        this.$toasted.error(error.message);
      },
    },
    configuration: {
      query: CONFIGURATION,
      errorPolicy: 'all',
      error(error) {
        this.$toasted.error(error.message);
      },
    },
  },
};
</script>
