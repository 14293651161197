/* eslint-disable lines-between-class-members, class-methods-use-this */
import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import isoWeek from 'dayjs/plugin/isoWeek';

dayjs.extend(weekday);
dayjs.extend(weekOfYear);
dayjs.extend(isoWeek);

export default class WeekManager {
  constructor() {
    this.days = Object.freeze(['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']);
    this.monday = dayjs().weekday(1);
    this.weekdays = this.generateWeekdays(this.monday);
  }

  currWeek() { return this.monday.week(); }
  prevWeek() {
    this.monday = dayjs(this.monday).subtract(7, 'days');
    this.weekdays = this.generateWeekdays(this.monday);
  }
  nextWeek() {
    this.monday = dayjs(this.monday).add(7, 'days');
    this.weekdays = this.generateWeekdays(this.monday);
  }

  generateWeekdays(monday) {
    return this.days.map((day, index) => {
      const weekDay = dayjs(monday).day(index);
      return {
        day,
        date: weekDay.isValid() ? weekDay : dayjs(),
        placeholder: this.getPlaceholder(weekDay.isoWeek(), weekDay.isoWeekday()),
      };
    });
  }

  static isWeekend(weekDay) { return dayjs(weekDay).day() % 6 === 0; }
  isWeekend(weekDay) { return dayjs(weekDay).day() % 6 === 0; }

  get(weekDay) {
    if (typeof weekDay === 'number') return this.weekdays[weekDay]?.date || dayjs();
    const index = this.days.findIndex((value) => value.toLowerCase() === weekDay.toLowerCase());
    return index !== -1 ? this.weekdays[index]?.date || dayjs() : dayjs();
  }

  getPlaceholder(weekNumber, weekDay) {
    const day = dayjs().isoWeek(weekNumber).isoWeekday(weekDay).format('YYYY-MM-DD');
    if (day === dayjs().format('YYYY-MM-DD')) {
      return 'What did you do today? 🙂';
    }
    if (day === dayjs().subtract(1, 'day').format('YYYY-MM-DD')) {
      return 'What did you do yesterday? 🙂';
    }
    if (day === dayjs().add(1, 'day').format('YYYY-MM-DD')) {
      return 'What do you plan to do tomorrow? 🙂';
    }
    if (dayjs().isAfter(day)) {
      return `What did you do last ${this.days[weekDay]}? 🙂`;
    }
    return `What do you plan to do this ${this.days[weekDay]}? 🙂`;
  }
}
