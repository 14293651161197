<template>
  <div>
    <nav>
      <div class="breadcrumb p-2">
        <div class="row">
          <div class="col-auto ms-3 p-0">
            <SpinnerRouterLink :loading="$apollo.loading">Send Surveys</SpinnerRouterLink>
          </div>
        </div>

        <div class="container-fluid">
          <div class="row">
            <div class="col-12 col-md-11">
              <Search
                v-model="search"
                :inputs="['clients', 'projects', 'users', 'state', 'onlyMyProjectsCheckbox', '']"
              ></Search>
            </div>
          </div>
        </div>
      </div>
    </nav>
    <div class="row" v-if="projects">
      <div class="col-md-12">
        <table class="table">
          <thead>
            <tr>
              <th>#</th>
              <SortByColumn :sort_by_field="'name'" :orderBy="orderBy" @refresh="refresh">Project</SortByColumn>
              <th>Client</th>
              <SortByColumn :sort_by_field="'last_known_net_promoter_score'" :orderBy="orderBy" @refresh="refresh">Most Recent Score</SortByColumn>
              <SortByColumn :sort_by_field="'last_known_net_promoter_score_date'" :orderBy="orderBy" @refresh="refresh">Date</SortByColumn>
              <th v-if="$can('CreateNetPromoterScoreLink')"></th>
            </tr>
          </thead>
          <tbody>
            <tr :key="index" v-for="(row, index) in projects.data">
              <td>{{index + 1}}</td>
              <td>{{row.name}}</td>
              <td>{{row.client.name}}</td>
              <td>{{row.last_known_net_promoter_score}}</td>
              <td :title="row.last_known_net_promoter_score_date">{{ timeAgo(row.last_known_net_promoter_score_date) }}
              </td>
              <td v-if="$can('CreateNetPromoterScoreLink')">
                <CopyButton :client_id="row.client.id" :project_id="row.id"></CopyButton>
              </td>
            </tr>
            <SkeletonRow v-if="projects && projects.paginatorInfo.hasMorePages" :colspan="6"
              :executeScroll="projects != undefined" @showMore="showMore('projects')"></SkeletonRow>
          </tbody>
        </table>
      </div>
    </div>
    <Empty v-if="!projects?.data.length" icon="fa-solid fa-paper-plane"/>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import { SearchBuilder, PaginationHandler } from '../../shared';
import { NET_PROMOTER_SCORE_SURVEYS } from './queries';
import {
  Search, SortByColumn, SkeletonRow, SpinnerRouterLink,
} from '../shared';
import CopyButton from './CopyButton.vue';

export default {
  extends: PaginationHandler,
  components: {
    Search,
    SkeletonRow,
    SortByColumn,
    CopyButton,
    SpinnerRouterLink,
  },
  apollo: {
    projects: {
      query: NET_PROMOTER_SCORE_SURVEYS,
      errorPolicy: 'all',
      variables() {
        return {
          page: 1, first: 12, orderBy: [this.orderBy], ...SearchBuilder.build(this.search),
        };
      },
      error(error) {
        this.$toasted.error(error.message);
      },
    },
  },
  data() {
    return {
      orderBy: { column: 'last_known_net_promoter_score_date', order: 'DESC' },
      search: { state: 'In Progress' },
    };
  },
  methods: {
    timeAgo(npsDate) {
      if (npsDate) {
        return dayjs(npsDate, 'YYYY-MM-DD').endOf('day').fromNow();
      }
      return 'Never';
    },
    queryVariables() {
      return { orderBy: [this.orderBy], ...SearchBuilder.build(this.search) };
    },
    refresh() {
      this.$apollo.queries.projects.refetch();
    },
  },
};
</script>
