export default {
  methods: {
    showMore(queryName) {
      this.$apollo.queries[queryName].fetchMore({
        variables: { page: this.page++, ...this.queryVariables() },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          // Make a copy of the paginatorInfo object before modifying it
          const updatedPaginatorInfo = {
            ...previousResult[queryName].paginatorInfo,
            hasMorePages: fetchMoreResult[queryName].paginatorInfo.hasMorePages,
          };

          // Return a new reference with the updated data
          return {
            [queryName]: {
              __typename: previousResult[queryName].__typename,
              data: [...previousResult[queryName].data, ...fetchMoreResult[queryName].data],
              paginatorInfo: updatedPaginatorInfo,
            },
          };
        },
      });
    },
  },
  data() {
    return { page: 2 };
  },
};
