import { gql } from 'apollo-boost';

export default gql`
  mutation($healthScoreProjectValue: HealthScoreProjectValueInput!){
    createHealthScoreProjectValue(input: $healthScoreProjectValue){
      id
      value
    }
  }
`;
