import { useVuelidate } from '@vuelidate/core';
import { required, minValue } from '@vuelidate/validators';

export default {
  data() {
    return {
      v$: useVuelidate(),
    };
  },
  validations: {
    localRecord: {
      cost: { required, minValueValue: minValue(1) },
      date: { required },
    },
  },
};
