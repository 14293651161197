import { gql } from '@apollo/client/core';

export default gql`
mutation($name: String
         $only_my_projects: Boolean
         $project_id: ID
         $user_id: ID){
  exportProjectSummaryToExcel(
    name: $name,
    only_my_projects: $only_my_projects,
    project_id: $project_id,
    user_id: $user_id,
  )
}
`;
