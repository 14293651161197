<template>
  <div class="row task-container flex-nowrap">
    <div class="input-group col-md-8 p-0 flex-fill">
      <span class="input-group-text grabbable text-default rounded-0">
        <i class="fas fa-arrows-alt-v"></i>
      </span>
      <input v-if="isDuplicated" type="text" class="form-control rounded-0 w-25 text-danger" placeholder="Name" v-model="localItem.name" title="Duplicated Line" @change="updateParent"/>
      <input v-if="!isDuplicated" type="text" class="form-control rounded-0 w-25" placeholder="Name" v-model="localItem.name" :title="localItem.name" @change="updateParent"/>
    </div>
    <div class="input-group col-md-4 p-0 flex-fill">
      <input type="number" class="form-control rounded-0" placeholder="Hours" v-model="localItem.hours" @change="updateParent"/>
      <span class="input-group-text sortable rounded-0">
        <input type="checkbox" class="form-check-input sortable" v-model="localItem.is_completed" @change="updateParent"/>
      </span>
      <span class="input-group-text sortable text-danger rounded-0" @click="$emit('deleteLine', localItem)">
        <i class="fas fa-times"></i>
      </span>
    </div>
  </div>
</template>
<script>
export default {
  props: ['bucket_estimation_item', 'isDuplicated'],
  data() {
    return {
      localItem: { ...this.bucket_estimation_item },
    };
  },
  methods: {
    updateParent() {
      this.$emit('updateItem', this.localItem);
    },
  },
  watch: {
    bucket_estimation_item: {
      handler(newVal) {
        this.localItem = { ...newVal };
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
