import { gql } from '@apollo/client/core';

import OPPORTUNITY_FRAGMENT from './fragments';

export const OPPORTUNITIES_VIEW = gql`
query($page: Int, $name: String){
  opportunities(page: $page, name: $name, orderBy: { column: "id", order: DESC }) @connection(key: "opportunities", filter: ["id", "name"]) {
    paginatorInfo{
      hasMorePages
    }
    data {
      ...opportunity
    }
  }
}
${OPPORTUNITY_FRAGMENT}
`;

export const CLIENTS_OPPORTUNITIES = gql`
query($page: Int, $id: ID $name: String){
  opportunities(page: $page, client_id: $id, name: $name, orderBy: { column: "id", order: DESC }) @connection(key: "opportunities_clients", filter: ["id", "name"]) {
    paginatorInfo{
      hasMorePages
    }
    data {
      ...opportunity
    }
  }
}
${OPPORTUNITY_FRAGMENT}
`;

export const PROJECTS_OPPORTUNITIES = gql`
query($page: Int, $id: ID $name: String){
  opportunities(page: $page, project_id: $id, name: $name, orderBy: { column: "id", order: DESC }) @connection(key: "opportunities_projects", filter: ["id", "name"]) {
    paginatorInfo{
      hasMorePages
    }
    data {
      ...opportunity
    }
  }
}
${OPPORTUNITY_FRAGMENT}
`;
