import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';

export default {
  data() {
    return {
      v$: useVuelidate(), // Inicialización de vuelidate
    };
  },
  validations: {
    updatedPtoDays: {
      comments: { required },
    },
  },
};
