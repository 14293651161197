import { useVuelidate } from '@vuelidate/core';
import { required, minLength } from '@vuelidate/validators';

export default {
  data() {
    return {
      v$: useVuelidate(), // Inicialización de vuelidate
    };
  },
  validations: {
    localRecord: {
      assign_to_id: { required },
      name: { required, minLengthValue: minLength(7) },
    },
  },
};
