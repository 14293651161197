import { gql } from '@apollo/client/core';

export default gql`
  query(
    $name: String,
    $type: String,
    $state: String,
    $client_id: ID,
    $start_date: DateTime,
    $end_date: DateTime,
    $only_my_projects: Boolean,
    $page: Int,
    $orderBy: [OrderByClause!]
  ){
    projectMetrics(
      name: $name
      type: $type
      state: $state
      client_id: $client_id
      start_date: $start_date
      end_date: $end_date
      only_my_projects: $only_my_projects
      page: $page
      orderBy: $orderBy
    ) @connection(key: "project_metrics", filter: ["name", "type", "client_id", "state", "only_my_projects"]) {
      paginatorInfo{
        hasMorePages
      }
      data{
        id
        project
        client
        engagement
        budget
        start_date
        end_date
        actual_hours
        actual_cost
        gross_margin_percentage
        progress
        scope_percent_completed
      }
    }
  }
`;
