import { gql } from '@apollo/client/core';

export default gql`
  fragment job_title on JobTitle {
    id
    internal_description
    external_description
    user_count
  }
`;
