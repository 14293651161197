<template>
  <div class="form-inline">
    <Select
      v-model="localSearch.from_job_title_id"
      fieldToShow="internal_description"
      :name="`from_job_title_id`"
      @change="updateFilter"
      :query="queries.jobTitles"
      :queryVariables="{ orderBy: [{ column: 'id', order: 'ASC' }] }">
      From Job Title:
    </Select>

    <Select
      v-model="localSearch.to_job_title_id"
      fieldToShow="internal_description"
      :name="`to_job_title_id`"
      @change="updateFilter"
      :query="queries.jobTitles"
      :queryVariables="{ orderBy: [{ column: 'id', order: 'ASC' }] }">
      To Job Title:
    </Select>
  </div>
</template>

<script>
import { Searchable, JOB_TITLES } from '../../shared';
import { Select } from '../shared';

export default {
  components: { Select },
  extends: Searchable,
  props: { modelValue: { type: Object, default: () => ({}) } },
  data() {
    return {
      localSearch: { ...this.modelValue },
      queries: {
        jobTitles: JOB_TITLES,
      },
    };
  },
};
</script>
