import { gql } from '@apollo/client/core';

export default gql`
query($user_id: ID, $project_id: ID, $orderBy: [OrderByClause!]){
  projectMembers(user_id: $user_id, project_id: $project_id, orderBy: $orderBy){
    id
    project_id
    user_id
    hourly_rate
    project {
      id
      state
      name
    }
    project_role {
      id
      name
    }
    user {
      id
      name
    }
    end_date
  }
}`;
