<template>
  <div class="row">
    <div class="col-md-3 col-sm-12 ">
      <div class="company-control">
        <label class="me-sm-2" for="status">Status:</label>
        <select id="status" class="form-control form-select me-sm-2" v-model="localSearch.status" @change="updateFilter">
          <option value="">Any</option>
          <option value="pending">Pending</option>
          <option value="approved">Approved</option>
          <option value="rejected">Rejected</option>
        </select>
      </div>
    </div>
    <div class="col-md-3 col-sm-12 ">
      <div class="company-control">
        <Select
          v-model="localSearch.user_id"
          fieldToShow="name"
          :name="`user_id`"
          @change="updateFilter"
          :query="queries.users"
          :queryVariables="queries.variables">
          Person:
         </Select>
      </div>
    </div>
    <div class="col-md-3 col-sm-12 ">
      <div class="company-control">
        <Select
          v-model="localSearch.assigned_to_id"
          fieldToShow="name"
          :name="`assigned_to_id`"
          @change="updateFilter"
          :query="queries.users"
          :queryVariables="queries.variables">
          Assigned to:
        </Select>
      </div>
    </div>
  </div>
</template>

<script>
import { Searchable, USERS } from '../../../shared';
import { Select } from '../../shared';

export default {
  components: { Select },
  extends: Searchable,
  props: {
    modelValue: { type: Object, default: () => ({}) },
  },
  data() {
    return {
      localSearch: {
        ...this.modelValue,
      },
      queries: {
        users: USERS,
        variables: {
          orderBy: [{ column: 'name', order: 'ASC' }],
          is_active: true,
          is_external: false,
        },
      },
    };
  },
};
</script>
