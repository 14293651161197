import { gql } from '@apollo/client/core';

import PAID_TIME_OFF_REQUEST_FRAGMENT from '../vacation_requests/fragments';

export default gql`
  mutation($paid_time_off_request: PaidTimeOffRequestInput!) {
    createPaidTimeOffRequest(input: $paid_time_off_request) {
      ...paid_time_off_request
    }
  }
  ${PAID_TIME_OFF_REQUEST_FRAGMENT}
`;
