<template>
  <div>
    <Form
      v-if="selectedRecord"
      v-bind:record="selectedRecord"
      @close="selectedRecord = null"
      @insert="insert"
    ></Form>
    <nav>
      <div class="breadcrumb p-2">
        <div class="row">
          <div class="col-auto ms-3 p-0">
            <SpinnerRouterLink :loading="$apollo.loading">Learning Paths</SpinnerRouterLink>
          </div>
        </div>
        <div class="container-fluid">
          <div class="row">
            <div class="col-12 col-sm-6 m-auto mt-2">
              <Search
                v-model="search"
                :inputs="['name']"
              ></Search>
            </div>
            <div class="col-12 col-sm-6 order-last text-end mt-2 pt-2">
              <button type="button" class="btn btn-company-primary" @click="selectedRecord = {}" v-if="$can('CreateLearningPath')">
                <i class="fas fa-plus"></i>
                New
              </button>
            </div>
          </div>
        </div>
      </div>
    </nav>
    <div class="row" v-if="learning_paths">
      <Empty v-if="!learning_paths.data.length" icon="fas fa-graduation-cap" :can_create="true" @callCreate="selectedRecord = {}"/>
      <Card
        :key="index"
        v-for="(record, index) in learning_paths.data"
        :record="record"
        @remove="remove"
      ></Card>
    </div>
    <SkeletonCards
      v-if="learning_paths && learning_paths.paginatorInfo.hasMorePages"
      :cards=4
      :executeScroll="learning_paths != undefined"
      @showMore="showMore('learning_paths')"
    ></SkeletonCards>
  </div>
</template>

<script>
import LEARNING_PATHS_QUERY from './queries';
import { DELETE_LEARNING_PATH, CREATE_LEARNING_PATH } from './mutations';
import Form from './Form.vue';
import Card from './Card.vue';
import { Search, SkeletonCards, SpinnerRouterLink } from '../shared';
import { SearchBuilder, ErrorHandler, PaginationHandler } from '../../shared';

export default {
  extends: PaginationHandler,
  components: {
    Form, Card, Search, SkeletonCards, SpinnerRouterLink,
  },
  apollo: {
    learning_paths: {
      query: LEARNING_PATHS_QUERY,
      errorPolicy: 'all',
      variables() {
        return { page: 1, ...SearchBuilder.build(this.search) };
      },
      error(error) {
        this.$toasted.error(error.message);
      },
    },
  },
  data() {
    return {
      search: {},
      selectedRecord: null,
    };
  },
  methods: {
    queryVariables() {
      return SearchBuilder.build(this.search);
    },
    remove(record) {
      this.$apollo.mutate({
        mutation: DELETE_LEARNING_PATH,
        variables: {
          id: record.id,
        },
        update: (store, { data: { deleteLearningPath } }) => {
          const data = store.readQuery({ query: LEARNING_PATHS_QUERY, variables: this.queryVariables() });
          const updatedLearningPath = data.learning_paths.data.filter((t) => t.id !== deleteLearningPath.id);
          store.modify({ fields: { learning_paths(existingLearningPaths = { data: [] }) { return { ...existingLearningPaths, data: updatedLearningPath }; } } });
        },
      }).catch((error) => {
        this.$toasted.error(`Unable to delete record: ${error.message}`);
      });
    },
    insert(record) {
      // We save the user input in case of an error
      const newLearningPath = record;
      this.selectedRecord = null;
      this.$apollo.mutate({
        mutation: CREATE_LEARNING_PATH,
        variables: {
          learning_path: record,
        },
        update: (store, { data: { createLearningPath } }) => {
          const data = store.readQuery({ query: LEARNING_PATHS_QUERY, variables: this.queryVariables() });
          store.modify({ fields: { learning_paths(existingPaths = { data: [] }) { return { ...existingPaths, data: [createLearningPath, ...data.learning_paths.data] }; } } });
        },
      }).catch((error) => {
        ErrorHandler.handle(error, this);
        // We restore the initial user input
        this.selectedRecord = newLearningPath;
      });
    },
  },
};
</script>
