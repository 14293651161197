import { gql } from '@apollo/client/core';

export default gql`
  fragment learning_path_level_node on LearningPathLevelNode {
    id
    name
    description
    documentation_links
    evaluation_methodology
    learning_path_level_id
  }
`;
