<template>
  <div>
    <Form v-if="selectedRecord" v-model:record="selectedRecord"
                                        @close="selectedRecord = null"
                                        @insert="insert"></Form>
    <nav>
      <div class="breadcrumb p-2">
        <div class="row">
          <div class="col-auto ms-3 p-0">
            <SpinnerRouterLink :loading="$apollo.loading">{{$route.name}}</SpinnerRouterLink>
          </div>
        </div>
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-11 col-sm-11 m-auto mt-2">
              <Search
                v-model="search"
                :inputs="searchOptions"
              ></Search>
            </div>
            <div class="col-md-1 col-sm-1 order-last text-end mt-2 pt-2">
              <button type="button" class="btn btn-company-primary" @click="selectedRecord = { status: 'Created' }" v-if="$can('CreatePerformanceEvaluationCycle') || $can('CreateMyPerformanceEvaluation')">
                <i class="fas fa-plus"></i>
                New
              </button>
            </div>
          </div>
        </div>
      </div>
    </nav>
    <div class="row" v-if="performance_evaluations">
      <Empty v-if="!performance_evaluations.data.length" icon="fa-solid fa-star" :can_create="true" @callCreate="selectedRecord = { status: 'Created' }"/>
      <Card :key="index" v-for="(record, index) in performance_evaluations.data" :record="record" @remove="remove"></Card>
    </div>
    <SkeletonCards
      v-if="performance_evaluations && performance_evaluations.paginatorInfo.hasMorePages"
      :cards=4
      :executeScroll="performance_evaluations != undefined"
      @showMore="showMore('performance_evaluations')"
    ></SkeletonCards>
  </div>
</template>

<script>
import { PERFORMANCE_EVALUATION_QUERY, MY_PERFORMANCE_EVALUATION_QUERY } from './queries';
import { DELETE_PERFORMANCE_EVALUATION, CREATE_PERFORMANCE_EVALUATION, CREATE_MY_PERFORMANCE_EVALUATION } from './mutations';
import { Search, SpinnerRouterLink, SkeletonCards } from '../shared';
import { SearchBuilder, ErrorHandler, PaginationHandler } from '../../shared';
import Form from './Form.vue';
import Card from './Card.vue';

export default {
  extends: PaginationHandler,
  components: {
    Search, SpinnerRouterLink, SkeletonCards, Form, Card,
  },
  apollo: {
    performance_evaluations: {
      query() {
        return this.query;
      },
      errorPolicy: 'all',
      variables() {
        return {
          page: 1, orderBy: [{ column: 'id', order: 'ASC' }], include_forms: false, ...SearchBuilder.build(this.search),
        };
      },
      error(error) {
        this.$toasted.error(error.message);
      },
    },
  },
  computed: {
    searchOptions() {
      return this.query === MY_PERFORMANCE_EVALUATION_QUERY ? ['dateRanges'] : ['users', 'evaluation_cycles', 'dateRanges'];
    },
    query() {
      return this.$route.name === 'My Performance Evaluations' ? MY_PERFORMANCE_EVALUATION_QUERY : PERFORMANCE_EVALUATION_QUERY;
    },
    mutation() {
      return this.$route.name === 'My Performance Evaluations' ? CREATE_MY_PERFORMANCE_EVALUATION : CREATE_PERFORMANCE_EVALUATION;
    },
  },
  data() {
    return {
      search: {},
      selectedRecord: null,
    };
  },
  methods: {
    queryVariables() {
      return { orderBy: [{ column: 'id', order: 'ASC' }], include_forms: false, ...SearchBuilder.build(this.search) };
    },
    remove(performanceEvaluationCyle) {
      this.$apollo.mutate({
        mutation: DELETE_PERFORMANCE_EVALUATION,
        variables: {
          id: performanceEvaluationCyle.id,
        },
        update: (store, { data: { deletePerformanceEvaluation } }) => {
          const data = store.readQuery({ query: this.query, variables: { page: 1, ...this.queryVariables() } });
          const updtaedPerformanceEvaluation = data.performance_evaluations.data.filter((v) => v.id !== deletePerformanceEvaluation.id);
          store.modify({ fields: { performance_evaluations(existingPerformanceEvaluations = { data: [] }) { return { ...existingPerformanceEvaluations, data: updtaedPerformanceEvaluation }; } } });
        },
      }).catch((error) => {
        this.$toasted.error(`Unable to delete record: ${error.message}`);
      });
    },
    insert(performanceEvaluation) {
      // We save the user input in case of an error
      const newRecord = performanceEvaluation;
      this.selectedRecord = null;
      this.$apollo.mutate({
        mutation: this.mutation,
        variables: {
          performance_evaluation: performanceEvaluation,
          include_forms: false,
        },
        update: (store, { data: { createPerformanceEvaluation } }) => {
          const data = store.readQuery({ query: this.query, variables: { page: 1, ...this.queryVariables() } });
          store.modify({ fields: { performance_evaluations(existingPerformanceEvaluations = { data: [] }) { return { ...existingPerformanceEvaluations, data: [createPerformanceEvaluation, ...data.performance_evaluations.data] }; } } });
        },
      }).catch((error) => {
        ErrorHandler.handle(error, this);
        this.selectedRecord = newRecord;
      });
    },
  },
};
</script>
