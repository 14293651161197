import { gql } from '@apollo/client/core';

import PROFILE_FRAGMENT from './fragments';
import { USER_FRAGMENT } from '../fragments';

export default gql`
  query ($user_id: ID){
    profile: me(id:$user_id) {
      ...user
      ...profile
      project_participations {
        id
        start_date
        end_date
        responsibilities
        project_role{
          id
          name
        }
        project {
          id
          name
          description
        }
      }
      external_companies {
        id
        name
        start_date
        end_date
        external_projects {
          id
          name
          description
          duration
          external_company_id
        }
      }
    }
  }
  ${PROFILE_FRAGMENT}
  ${USER_FRAGMENT}
`;
