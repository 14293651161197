<template>
<table class="table">
  <thead>
    <tr>
      <th class="d-table-cell sortable" @click="sortBy('user_id')">
        <i v-if="orderBy.column === 'user_id'" class="bg-transparent fas" :class="sortIcon"></i>
        Person
      </th>
      <th class="d-table-cell sortable" @click="sortBy('project_id')">
        <i v-if="orderBy.column === 'project_id'" class="bg-transparent fas" :class="sortIcon"></i>
        Project
      </th>
      <th class="d-table-cell">Activities</th>
      <th class="d-table-cell sortable" @click="sortBy('created_at')">
        <i v-if="orderBy.column === 'created_at'" class="bg-transparent fas" :class="sortIcon"></i>
        Date
      </th>
      <th class="d-table-cell sortable" @click="sortBy('hours')">
        <i v-if="orderBy.column === 'hours'" class="bg-transparent fas" :class="sortIcon"></i>
        Hours
      </th>
      <th class="d-table-cell sortable" v-if="showCosts" :title="costsMessage()" @click="sortBy('costs')">
        <i v-if="orderBy.column === 'costs'" class="bg-transparent fas" :class="sortIcon"></i>
        Costs *
      </th>
      <th class="d-table-cell"></th>
    </tr>
  </thead>
  <tbody>
    <tr :key="hour.id" v-for="hour in hours.data">
      <td>{{hour.user.name}}</td>
      <td>{{!!hour.project ? hour.project.name :'Internal hours'}}</td>
      <td class="text-wrap text-break" v-html="hour.activities" v-if="hour.activities"></td>
      <td v-else>-</td>
      <td>
        <i v-if="isHoliday(hour)" title="Holiday" class="fa-regular fa-calendar-xmark" :class="isHoliday(hour) && hour.hours > 0 ? 'text-danger' : ''"></i>
        {{$displayDate(hour.date)}}
      </td>
      <td>{{hour.hours}}</td>
      <td v-if="showCosts">${{ $filters.formatNumber(hour.costs)}}</td>
      <td>
        <Confirmation :id="hour.id" :showLabels="false" @accepted="remove"></Confirmation>
      </td>
    </tr>
    <SkeletonRow v-if="hours && hours.paginatorInfo.hasMorePages" :colspan="showCosts ? 7 : 6" :executeScroll="hours != undefined" @showMore="showMore"></SkeletonRow>
    <tr v-if="hours && hours.data.length > 0">
      <td class="text-end" colspan="4">
        <strong>Total</strong>
      </td>
      <td><strong>{{total}}</strong></td>
      <td v-if="showCosts"><strong>${{ $filters.formatNumber(totalCosts)}}</strong></td>
      <td></td>
    </tr>
  </tbody>
</table>
</template>

<script>

import Helper from '../Helper';
import { Confirmation } from '../../../shared';

export default {
  extends: Helper,
  components: { Confirmation },
  props: ['hours', 'holidays', 'orderBy', 'show_costs'],
};
</script>
