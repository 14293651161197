<template>
  <div>
    <nav>
      <div class="breadcrumb p-2">
        <div class="row">
          <div class="col-auto ms-3 p-0">
            <SpinnerRouterLink :loading="$apollo.loading">Resume Full Text Search</SpinnerRouterLink>
          </div>
        </div>
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12">
              <Search v-model="searchQuery" :inputs="['description']"></Search>
            </div>
          </div>
        </div>
      </div>
    </nav>
    <div class="row" v-if="resume_full_text_search">
      <div class="col-md-12 text-nowrap scroll-x h-100">
        <table class="table">
          <thead>
            <tr>
              <th class="d-table-cell">#</th>
              <SortByColumn class="d-table-cell" :sort_by_field="'name'" :orderBy="orderBy" @refresh="refresh">Person</SortByColumn>
              <SortByColumn class="d-table-cell" :sort_by_field="'score'" :orderBy="orderBy" @refresh="refresh">Matching Score</SortByColumn>
            </tr>
          </thead>
          <tbody>
            <tr v-if="resume_full_text_search?.length === 0">
              <td class="text-center" colspan="3">No records found</td>
            </tr>
            <tr :key="row.id" v-for="(row, index) in resume_full_text_search">
              <td>{{index + 1}}</td>
              <td>
                <router-link :to="{ name: 'resume', query: { highlight: search.description }, params: { id: row.id }}" target="_blank">
                  {{row.name}}
                </router-link>
              </td>
              <td>{{row.score}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <Empty v-if="!resume_full_text_search?.length" icon="fas fa-search"/>
  </div>
</template>
<script>

import RESUME_FULL_TEXT_SEARCH_QUERY from './queries';
import Searchable from '../../../shared/Searchable';
import { Search, SortByColumn, SpinnerRouterLink } from '../../shared';

export default {
  components: { Search, SortByColumn, SpinnerRouterLink },
  extends: Searchable,
  data() {
    return {
      orderBy: { column: 'score', order: 'DESC' },
      searchQuery: {},
    };
  },
  methods: {
    refresh() {
      this.$apollo.queries.resume_full_text_search.refetch();
    },
  },
  apollo: {
    resume_full_text_search: {
      query: RESUME_FULL_TEXT_SEARCH_QUERY,
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
      variables() {
        return {
          query: this.searchQuery.description,
          orderBy: [this.orderBy],
        };
      },
      error(error) {
        this.$toasted.error(error.message);
      },
    },
  },
};
</script>
