import { useVuelidate } from '@vuelidate/core';
import { required, minLength } from '@vuelidate/validators';

export default {
  data() {
    return {
      v$: useVuelidate(),
    };
  },
  validations: {
    localRecord: {
      notes: { required, minLengthValue: minLength(7) },
      date: { required },
    },
  },
};
