<template>
  <div>
    <label for="item" v-if="$slots['default']">
      <slot></slot>
    </label>
    <div class="border rounded">
      <span :key="item.id" v-for="item in localRecord" class="badge bg-primary me-1 ms-1">
        {{item.name}}
        <i class="fas fa-times" @click="untag(item)"></i>
      </span>
      <input list="clients" type="text" name="item" class="border-0" :class="{'w-25': $slots['default']}" v-model="current_tag" @change="tag()">
    </div>
    <datalist id="clients" v-if="clientsDropDown">
      <template v-for="client in clientsDropDown">
        <option :key="client.id" v-if="!alreadySelected(client.id)">{{client.name}}</option>
      </template>
    </datalist>
  </div>
</template>

<script>
import { dropdownWithAlias } from '../../shared';

export default {
  apollo: {
    clientsDropDown: {
      query: dropdownWithAlias('clientsDropDown'),
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
      variables() {
        return { type: 'Client', orderBy: [{ column: 'id', order: 'ASC' }] };
      },
    },
  },
  props: ['records'],
  data() {
    return {
      current_tag: '',
      localRecord: [],
    };
  },
  watch: {
    clientsDropDown() {
      this.records.forEach((clientID) => {
        const client = _.find(this.clientsDropDown, { id: clientID });
        if (client) {
          this.localRecord.push(client);
        }
      });
    },
  },
  methods: {
    alreadySelected(id) {
      const index = _.findIndex(this.records, (record) => (id === record));
      return index !== -1;
    },
    tag() {
      const client = this.clientsDropDown.find((t) => t.name === this.current_tag);
      if (client) {
        this.records.push(client.id);
        this.localRecord.push({ id: client.id, name: client.name });
      }
      this.current_tag = '';
    },
    untag(item) {
      // Elimina el ID del array records
      const recordIndex = this.records.findIndex((e) => e === item.id);
      if (recordIndex !== -1) {
        this.records.splice(recordIndex, 1);
      }

      // Elimina el objeto del array localRecord
      const localIndex = this.localRecord.findIndex((e) => e.id === item.id);
      if (localIndex !== -1) {
        this.localRecord.splice(localIndex, 1);
      }
    },
  },
};
</script>
